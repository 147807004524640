import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Element as ScrollBlock } from 'react-scroll';

import { Order, Outlet } from 'models';
import FlrCard from 'components/shared/card/FlrCard';
import Alert from 'components/shared/Alert/Alert';
import { TitleH2, Link1, TextBody1, TextBody2, TextCaption } from 'components/shared/text';
import { SplitedPrice } from 'components/prices';

import { GridDividerBold } from 'components/shared/table/dividers';
import { baseUrl, defaultLocale, UAH } from 'shared/constants';
import styles from './styles';

import messages from 'translations/checkout/common';
import orderStatusMessages from 'translations/checkout/delivery-status';

interface IProps {
  order: Order;
  outlets: Outlet[];
  orderIndex: number;
  anchor: string;
  classes: any;
}

const OrderCardFinalized: React.FC<IProps> = ({ order, orderIndex, outlets, anchor, classes }) => {
  const mdate = moment(order.deliveryDate || new Date());
  const deliveryDate = `${mdate.locale(defaultLocale).format('ddd')} ${mdate.format('L')}`;

  return (
    <ScrollBlock
      name={`${anchor}-${order.id}`}
      className={clsx(`${anchor}-item`, classes.orderCard, classes.orderCardFinalized)}
    >
      <FlrCard id={order.id}>
        <Alert
          type={'success'}
          title={messages.alertPayedOrder.defaultMessage}
          className={clsx(classes.outlineMargin, classes.noMarginTop)}
        >
          {messages.alertPayedOrderText.defaultMessage}
        </Alert>
        <Grid container spacing={1} direction={'column'}>
          <Grid item>
            <TextCaption className={classes.gray}>
              {messages.delivery.defaultMessage} &ndash; {orderIndex}
            </TextCaption>
            <TitleH2 className={classes.noMarginTop}>{order.outlet && order.outlet.name}&nbsp;</TitleH2>
          </Grid>
          <GridDividerBold />
          <Grid item container spacing={2} className={classes.tableHeaders}>
            <Grid item sm={2}>
              <TextBody2>Замовлення</TextBody2>
            </Grid>
            <Grid item sm={1}>
              <TextBody2>Статус</TextBody2>
            </Grid>
            <Grid item sm={2}>
              <TextBody2>Дата доставки</TextBody2>
            </Grid>
            <Grid item sm={2}>
              <TextBody2>Спосіб доставки</TextBody2>
            </Grid>
            <Grid item sm>
              <TextBody2>Постачальник</TextBody2>
            </Grid>
            <Grid item sm={'auto'} className={classes.orderItemSubtotal}>
              <TextBody2 align={'right'}>Кількість</TextBody2>
            </Grid>
            <Grid item sm={'auto'} className={classes.orderItemSubtotal}>
              <TextBody2 align={'right'}>Сума</TextBody2>
            </Grid>
          </Grid>
          <GridDividerBold />
          <Grid item container spacing={2} className={classes.tableHeaders}>
            <Grid item sm={2}>
              <Link1 to={`${baseUrl}/account/orders/${order.id}`}>№ {order.orderId}</Link1>
              <TextBody2 color={'textSecondary'}>від {moment(order.createdAt).format('L')}</TextBody2>
            </Grid>
            <Grid item sm={1}>
              <TextBody1>
                {orderStatusMessages[order.orderStatus] && orderStatusMessages[order.orderStatus].defaultMessage}
              </TextBody1>
            </Grid>
            <Grid item sm={2}>
              <TextBody1 style={{ textTransform: 'capitalize' }}>{deliveryDate}</TextBody1>
              <TextBody2 color={'textSecondary'}>{order.deliveryTime ? order.deliveryTime.name : ''}</TextBody2>
            </Grid>
            <Grid item sm={2}>
              <TextBody1 className={classes.noMarginBottom}>{order.orderDetails.deliveryMethod.name}</TextBody1>
              <TextBody2 color={'textSecondary'} className={classes.noMarginBottom}>
                {order.outlet && order.outlet.address}
              </TextBody2>
            </Grid>
            <Grid item sm>
              <TextBody1>{order.warehouse.displayName}</TextBody1>
              <TextBody2 color={'textSecondary'}>{order.warehouse.address}</TextBody2>
            </Grid>
            <Grid item sm={'auto'} className={classes.orderItemSubtotal}>
              <TextBody1 align={'right'}>
                <SplitedPrice
                  value={order.totalQty}
                  bold={false}
                  hideDecimal={true}
                  postfix={'шт.'}
                  fontSize={'body1'}
                />
              </TextBody1>
              <TextBody2 align={'right'} color={'textSecondary'}>
                <SplitedPrice
                  value={order.totalPackings}
                  bold={false}
                  hideDecimal={true}
                  postfix={'уп.'}
                  fontSize={'body2'}
                />
              </TextBody2>
            </Grid>
            <Grid item sm={'auto'} className={classes.orderItemSubtotal}>
              <TextBody1 align={'right'}>
                <SplitedPrice value={order.totalSum} postfix={UAH} fontSize={'subtitle1'} />
              </TextBody1>
            </Grid>
          </Grid>
        </Grid>
      </FlrCard>
    </ScrollBlock>
  );
};

export default withStyles<any>(styles)(OrderCardFinalized);
