import { FC } from 'react';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';

import { IOfferDiscountDetails, Offer } from 'models';

import DeliveryCardHeader from './DeliveryCardHeader';
import DeliveryCardInnerContainer from './DeliveryCardInnerContainer';
import styles from './styles';

interface IProps {
  classes: any;
  discount: number;
  discountDetails: IOfferDiscountDetails[];
  offer: Offer;
  onPurchase: (offer: Offer, qty: number) => void;
}

const DeliveryCard: FC<IProps> = ({ classes, discount, discountDetails, offer, onPurchase }) => {
  return (
    <Grid
      className={clsx(classes.deliveryItem, {
        [classes.deliveryItemPreOrder]: offer.preOrder
      })}
      container
      item
      xs={12}
    >
      <DeliveryCardHeader
        warehouse={offer.warehouse}
        isPreorder={offer.preOrder}
        special={offer.special}
        discount={discount}
        discountDetails={discountDetails}
      />

      <Grid className={classes.card} item container>
        <DeliveryCardInnerContainer offer={offer} onPurchase={onPurchase} />
      </Grid>
    </Grid>
  );
};

export default styles(DeliveryCard);
