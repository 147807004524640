import React from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';

import { User } from 'models';
import Icon from 'components/shared/Icon';
import messages from 'translations/account/finance';

interface IProps {
  classes: any;
  account: User;
}

const CardDepositDiscount: React.FC<IProps> = ({ classes, account }) => {
  const personalDiscount =
    (account &&
      account.profile.discount &&
      account.profile.discount.personal &&
      account.profile.discount.personal.percent) ||
    0;

  const nextMonday = moment().day(1 + 7);

  return (
    <div className={classes.cardContent}>
      <span className={classes.cardIcon}>
        <Icon type="loyalty" offset={8} />
      </span>
      <div style={{ textAlign: 'left' }}>
        <span className={classes.depositDiscountAmount}>{personalDiscount}%</span>
        <Grid item sm={12} container justifyContent="space-between" className={classes.depositDiscountText}>
          <div>
            {nextMonday.format('D MMMM')} {messages.depositDiscountRefresh.defaultMessage}
            <br />
            {messages.depositDiscountReminder.defaultMessage}
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default CardDepositDiscount;
