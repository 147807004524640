import React from "react";
import clsx from "clsx";
import { withStyles } from "@mui/styles";

import { IDiscountOrder } from "store/discount-by-group/actions";

import { FlrTable } from "components/shared/table";
import { TextSubTitle } from "components/shared/text";
import FlrCardNarrow from "components/shared/card/FlrCardNarrow";
import FlrCardTitle from "components/shared/card/FlrCardTitle";

import columns from "./table-columns/OrdersTransactions";
import messages from "translations/account/loyalty";
import styles from "../styles";

interface IProps {
  // own props
  loading: boolean;
  data: IDiscountOrder[];
  classes: any;
}

const ProductGroupOrdersHistoryCard: React.FC<IProps> = ({ data, classes, loading }) => {
  return (
    <>
      <FlrCardNarrow
        className={clsx(classes.historyChart, classes.paperCard, classes.paperCardFullHeight)}
        style={{ width: "100%" }}
      >
        <FlrCardTitle isUnderline>
          <TextSubTitle align={"left"}>{messages.productGroupTransactions.defaultMessage}</TextSubTitle>
        </FlrCardTitle>
        <FlrTable columns={columns} data={data} isLoading={loading} />
      </FlrCardNarrow>
    </>
  );
};

export default withStyles<any>(styles)(ProductGroupOrdersHistoryCard);
