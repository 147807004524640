import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "header.userMenu.title",
    description: "",
    defaultMessage: "Профіль"
  },
  dashboard: {
    id: "header.userMenu.dashboard",
    description: "",
    defaultMessage: "Особистий кабінет"
  },
  cart: {
    id: "header.userMenu.cart",
    description: "",
    defaultMessage: "Кошик"
  },
  catalog: {
    id: "header.userMenu.catalog",
    description: "",
    defaultMessage: "Каталог"
  },
  orders: {
    id: "header.userMenu.orders",
    description: "",
    defaultMessage: "Замовлення"
  },
  finance: {
    id: "header.userMenu.finance",
    description: "",
    defaultMessage: "Фінанси"
  },
  refunds: {
    id: "header.userMenu.refunds",
    description: "",
    defaultMessage: "Повернення тари"
  },
  loyalty: {
    id: "header.userMenu.loyalty",
    description: "",
    defaultMessage: "Програма лояльності"
  },
  loyaltyMobile: {
    id: "header.userMenu.loyaltyMobile",
    description: "",
    defaultMessage: "Програми лояльності"
  },
  assist: {
    id: "header.userMenu.assist",
    description: "",
    defaultMessage: "Допомога"
  },
  settings: {
    id: "header.userMenu.settings",
    description: "",
    defaultMessage: "Налаштування"
  },
  logout: {
    id: "header.userMenu.logout",
    description: "",
    defaultMessage: "Вийти з профілю"
  },
  balance: {
    id: "header.userMenu.balance",
    description: "balance label",
    defaultMessage: "Баланс"
  },
  logoutUser: {
    id: "header.userMenu.logoutUser",
    description: "logoutUser label",
    defaultMessage: "Вийти з профілю"
  },
  removeUser: {
    id: "header.userMenu.removeUser",
    description: "removeUser label",
    defaultMessage: "Видалити профіль"
  },

  mobileMenuLanguageTitle: {
    id: "header.userMenu.mobileMenuLanguageTitle",
    description: "Mobile menu, sub drawer language header",
    defaultMessage: "Мова сайту"
  },
  mobileMenuCurrencyTitle: {
    id: "header.userMenu.mobileMenuCurrencyTitle",
    description: "Mobile menu, sub drawer currency header",
    defaultMessage: "Валюта сайту"
  }
});
