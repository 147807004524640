import React from 'react';
import styled from '@emotion/styled/macro';

import { ReactComponent as Attach } from './icons/attach.svg';
import { ReactComponent as Call } from './icons/whatsapp.svg';
import { ReactComponent as List } from './icons/list.svg';
import { ReactComponent as Location } from './icons/location.svg';
import { ReactComponent as Catalog } from './icons/catalog.svg';
import { ReactComponent as Phone } from './icons/phone.svg';
import { ReactComponent as PhoneContact } from './icons/phoneContact.svg';
import { ReactComponent as PhoneCall } from './icons/phone-call.svg';
import { ReactComponent as Search } from './icons/search.svg';
import { ReactComponent as Telegram } from './icons/telegram.svg';
import { ReactComponent as Viber } from './icons/viber.svg';
import { ReactComponent as Instagram } from './icons/instagram.svg';
import { ReactComponent as Flower } from './icons/flower.svg';
import { ReactComponent as FlowerUnit } from './icons/flower-unit.svg';
import { ReactComponent as LogoFull } from './icons/logo-full.svg';
import { ReactComponent as Logo } from './icons/logo.svg';
import { ReactComponent as LogoRu } from './icons/logo-ru.svg';
import { ReactComponent as LogoTextRightRu } from './icons/logo-textright-ru.svg';
import { ReactComponent as MapMarker } from './icons/map-marker.svg';
import { ReactComponent as ButtonArrow } from './icons/btnArrow.svg';
import { ReactComponent as Cross } from './icons/cross.svg';
import { ReactComponent as CheckMark } from './icons/check-mark.svg';
import { ReactComponent as CheckAlert } from './icons/check-alert.svg';
import { ReactComponent as Cookies } from './icons/cookies.svg';
import { ReactComponent as Kyivstar } from './icons/kyivstar.svg';
import { ReactComponent as MTS } from './icons/mts.svg';
import { ReactComponent as LifeCell } from './icons/lifecell.svg';
import { ReactComponent as Arrow } from './icons/arrow.svg';
import { ReactComponent as CircledText } from './icons/new-products-circled.svg';
import { ReactComponent as Eye } from './icons/eye.svg';
import { ReactComponent as EyeOff } from './icons/eye-off.svg';
import { ReactComponent as Google } from './icons/google.svg';
import { ReactComponent as Facebook } from './icons/fb.svg';
import { ReactComponent as FacebookOutlined } from './icons/facebook_outlined.svg';
import { ReactComponent as Help } from './icons/help.svg';
import { ReactComponent as Favorites } from './icons/star.svg';
import { ReactComponent as StarFull } from './icons/star-full.svg';
import { ReactComponent as Profile } from './icons/profile.svg';
import { ReactComponent as Balance } from './icons/balance.svg';
import { ReactComponent as Van } from './icons/van.svg';
import { ReactComponent as Cart } from './icons/cart.svg';
import { ReactComponent as CartGreyed } from './icons/shopping-cart-grayed.svg';
import { ReactComponent as EmptyCart } from './icons/empty-cart.svg';
import { ReactComponent as Wallet } from './icons/wallet.svg';
import { ReactComponent as Height } from './icons/height.svg';
import { ReactComponent as HeightWhite } from './icons/height-white.svg';
import { ReactComponent as Heart } from './icons/heart.svg';
import { ReactComponent as HeartFull } from './icons/heart-full.svg';
import { ReactComponent as Filter } from './icons/filter.svg';
import { ReactComponent as FilterMobile } from './icons/filterMobile.svg';
import { ReactComponent as ArrowExpand } from './icons/arrow-expand.svg';
import { ReactComponent as Info } from './icons/info.svg';
import { ReactComponent as InfoNew } from './icons/info-new.svg';
import { ReactComponent as Warning } from './icons/warning.svg';
import { ReactComponent as PreOrder } from './icons/pre-order.svg';
import { ReactComponent as ChevronUp } from './icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from './icons/chevron-down.svg';
import { ReactComponent as ChevronLeft } from './icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from './icons/chevron-right.svg';
import { ReactComponent as Timer } from './icons/timer.svg';
import { ReactComponent as Block } from './icons/block.svg';
import { ReactComponent as Checkbox } from './icons/checkbox.svg';
import { ReactComponent as CheckboxChecked } from './icons/checkboxChecked.svg';
import { ReactComponent as CheckboxInter } from './icons/checkboxInter.svg';
import { ReactComponent as Dashboard } from './icons/dashboard.svg';
import { ReactComponent as Delivery } from './icons/delivery.svg';
import { ReactComponent as Finance } from './icons/finance.svg';
import { ReactComponent as Loyalty } from './icons/loyalty.svg';
import { ReactComponent as ReturnContainer } from './icons/return-container.svg';
import { ReactComponent as Settings } from './icons/settings.svg';
import { ReactComponent as SignOut } from './icons/sign-out.svg';
import { ReactComponent as Support } from './icons/support.svg';
import { ReactComponent as CreditCard } from './icons/credit-card.svg';
import { ReactComponent as World } from './icons/world.svg';
import { ReactComponent as FullScreen } from './icons/full-screen.svg';
import { ReactComponent as Sort } from './icons/sort.svg';
import { ReactComponent as TableSortDefault } from './icons/table-sort-default.svg';
import { ReactComponent as Box } from './icons/box.svg';
import { ReactComponent as Comment } from './icons/comment.svg';
import { ReactComponent as Return } from './icons/return.svg';
import { ReactComponent as Camera } from './icons/camera.svg';
import { ReactComponent as VerificationProgress } from './icons/verification-progress.svg';
import { ReactComponent as VerificationSuccess } from './icons/verification-success.svg';
import { ReactComponent as Complete } from './icons/complete.svg';
import { ReactComponent as Trash } from './icons/trash.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as Calendar } from './icons/calendar.svg';
import { ReactComponent as Bids } from './icons/bids.svg';
import { ReactComponent as Marker } from './icons/marker.svg';
import { ReactComponent as MarkerGoogle } from './icons/marker-google.svg';
import { ReactComponent as Clock } from './icons/clock.svg';
import { ReactComponent as History } from './icons/history.svg';
import { ReactComponent as ListMarked } from './icons/list-marked.svg';
import { ReactComponent as Discount } from './icons/discount.svg';
import { ReactComponent as Pause } from './icons/pause.svg';
import { ReactComponent as Cancel } from './icons/cancel.svg';
import { ReactComponent as Feedback } from './icons/feedback.svg';
import { ReactComponent as Send } from './icons/send.svg';
import { ReactComponent as Download } from './icons/download.svg';
import { ReactComponent as Category } from './icons/category.svg';
import { ReactComponent as Colour } from './icons/colour.svg';
import { ReactComponent as Country } from './icons/country.svg';
import { ReactComponent as Diameter } from './icons/diameter.svg';
import { ReactComponent as Length } from './icons/length.svg';
import { ReactComponent as Manufacturer } from './icons/manufacturer.svg';
import { ReactComponent as ManufacturerBordered } from './icons/manufacturer-bordered.svg';
import { ReactComponent as Maturity } from './icons/maturity.svg';
import { ReactComponent as Package } from './icons/package.svg';
import { ReactComponent as Quality } from './icons/quality.svg';
import { ReactComponent as Volume } from './icons/volume.svg';
import { ReactComponent as Weight } from './icons/weight.svg';
import { ReactComponent as FirstPage } from './icons/first-page.svg';
import { ReactComponent as LastPage } from './icons/last-page.svg';
import { ReactComponent as KeyboardArrowRight } from './icons/keyboard-arrow-right.svg';
import { ReactComponent as KeyboardArrowLeft } from './icons/keyboard-arrow-left.svg';
import { ReactComponent as ListLayout } from './icons/list-layout.svg';
import { ReactComponent as TableLayout } from './icons/table-layout.svg';
import { ReactComponent as Fire } from './icons/fire.svg';
import { ReactComponent as FireBordered } from './icons/fire-bordered.svg';
import { ReactComponent as FireBorderedMobile } from './icons/fire-bordered-mobile.svg';
import { ReactComponent as Warehouse } from './icons/warehouse.svg';
import { ReactComponent as GoodsComingOutlined } from './icons/goods-coming-outlined.svg';
import { ReactComponent as GoodsComingBordered } from './icons/goods-coming-bordered.svg';
import { ReactComponent as GoodsComingBorderedMobile } from './icons/goods-coming-bordered-mobile.svg';
import { ReactComponent as GoodsComing } from './icons/goods-coming.svg';
import { ReactComponent as Arriving } from './icons/arriving.svg';
import { ReactComponent as ShoppingCartOne } from './icons/shopping-cart-one.svg';
import { ReactComponent as ShoppingCartOneWhite } from './icons/shopping-cart-one-white.svg';
import { ReactComponent as CustomerSupport } from './icons/customer-support.svg';
import { ReactComponent as Maximize } from './icons/maximize.svg';
import { ReactComponent as MoreThan } from './icons/moreThan.svg';
import { ITheme, IThemed } from 'utils/styled';
import clsx from 'clsx';

interface IIconProps {
  type: string;
  size?: number;
  width?: number;
  height?: number;
  offset?: number;
  leftOffset?: number;
  opacity?: number;
  withBackground?: boolean;
  theme: ITheme;
  className?: any;
}

const defaultIconSize = 32;
const icons = {
  attach: Attach,
  call: Call,
  list: List,
  catalog: Catalog,
  location: Location,
  phone: Phone,
  phoneContact: PhoneContact,
  marker: Marker,
  markerGoogle: MarkerGoogle,
  search: Search,
  telegram: Telegram,
  viber: Viber,
  instagram: Instagram,
  flower: Flower,
  flowerUnit: FlowerUnit,
  logo: Logo,
  logoFull: LogoFull,
  logoRu: LogoRu,
  logoTextRightRu: LogoTextRightRu,
  cross: Cross,
  checkAlert: CheckAlert,
  checkMark: CheckMark,
  mapMarker: MapMarker,
  btnArrow: ButtonArrow,
  kyivstar: Kyivstar,
  mts: MTS,
  lifecell: LifeCell,
  arrow: Arrow,
  cookies: Cookies,
  circledText: CircledText,
  eye: Eye,
  eyeOff: EyeOff,
  google: Google,
  facebook: Facebook,
  facebookOutlined: FacebookOutlined,
  help: Help,
  star: Favorites,
  starFull: StarFull,
  profile: Profile,
  balance: Balance,
  orders: Van,
  cart: Cart,
  cartGreyed: CartGreyed,
  emptyCart: EmptyCart,
  wallet: Wallet,
  height: Height,
  heightWhite: HeightWhite,
  heartFull: HeartFull,
  heart: Heart,
  filter: Filter,
  filterMobile: FilterMobile,
  arrowExpand: ArrowExpand,
  info: Info,
  infoNew: InfoNew,
  warning: Warning,
  preOrder: PreOrder,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  timer: Timer,
  block: Block,
  checkbox: Checkbox,
  checkboxChecked: CheckboxChecked,
  checkboxInter: CheckboxInter,
  dashboard: Dashboard,
  delivery: Delivery,
  finance: Finance,
  loyalty: Loyalty,
  returnContainer: ReturnContainer,
  settings: Settings,
  signOut: SignOut,
  creditCard: CreditCard,
  support: Support,
  world: World,
  fullScreen: FullScreen,
  tableSortDefault: TableSortDefault,
  sort: Sort,
  box: Box,
  comment: Comment,
  complete: Complete,
  verificationProgress: VerificationProgress,
  verificationSuccess: VerificationSuccess,
  camera: Camera,
  trash: Trash,
  edit: Edit,
  calendar: Calendar,
  bids: Bids,
  discount: Discount,
  clock: Clock,
  listMarked: ListMarked,
  history: History,
  return: Return,
  pause: Pause,
  cancel: Cancel,
  feedback: Feedback,
  send: Send,
  download: Download,
  category: Category,
  colour: Colour,
  country: Country,
  diameter: Diameter,
  length: Length,
  manufacturer: Manufacturer,
  manufacturerBordered: ManufacturerBordered,
  maturity: Maturity,
  package: Package,
  quality: Quality,
  volume: Volume,
  weight: Weight,
  firstPage: FirstPage,
  lastPage: LastPage,
  keyboardArrowRight: KeyboardArrowRight,
  keyboardArrowLeft: KeyboardArrowLeft,
  listLayout: ListLayout,
  tableLayout: TableLayout,
  fire: Fire,
  fireBordered: FireBordered,
  fireBorderedMobile: FireBorderedMobile,
  warehouse: Warehouse,
  goodsComingOutlined: GoodsComingOutlined,
  goodsComing: GoodsComing,
  goodsComingBordered: GoodsComingBordered,
  goodsComingBorderedMobile: GoodsComingBorderedMobile,
  arriving: Arriving,
  shoppingCartOne: ShoppingCartOne,
  shoppingCartOneWhite: ShoppingCartOneWhite,
  customerSupport: CustomerSupport,
  phoneCall: PhoneCall,
  maximize: Maximize,
  moreThan: MoreThan
};

export type IIconPropsWithChildren = IIconProps & React.PropsWithChildren<any>;

const Icon: React.FC<IIconPropsWithChildren> = ({
  type,
  size,
  width,
  height,
  withBackground,
  className,
  ...props
}: IIconProps) => {
  const IconTag = icons[type] || Flower;
  return (
    <IconHolder
      className={clsx('flr-icon', {
        [className]: Boolean(className)
      })}
      withBackground={withBackground}
      {...props}
    >
      <IconTag width={'100%'} height={'100%'} />
    </IconHolder>
  );
};

export const IconHolder = styled<any>('div')(({ theme, withBackground }: IThemed & IIconProps) => ({
  position: withBackground ? 'relative' : 'static',

  ...(withBackground
    ? {
        '&:before': {
          content: "''",
          position: 'absolute',
          bottom: 4,
          left: 4,
          width: 15,
          height: 13,
          background: theme.palette.common.primaryClickablePressBackground,
          borderRadius: 2,
          transform: 'rotate(-9.74deg)'
        }
      }
    : {})
}));

export default styled<any>(Icon)(
  ({ theme, opacity, withBackground, width, height, size, offset, leftOffset }: IThemed & IIconProps) => ({
    opacity: opacity || theme.opacities.iconDefault,
    '& svg': {
      position: withBackground ? 'relative' : 'static'
    },

    width: (() => {
      const widthValue = width || (!height && (size || defaultIconSize));
      return widthValue ? (Number.isInteger(widthValue) ? `${widthValue}px` : widthValue) : '100%';
    })(),
    height: (() => {
      const heightValue = height || (!width && (size || defaultIconSize));
      return heightValue ? (Number.isInteger(heightValue) ? `${heightValue}px` : heightValue) : '100%';
    })(),
    display: 'inline-flex',
    marginRight: offset || 0,
    marginLeft: leftOffset || 0
  })
);
