const styles = (theme: any) => ({
  dialogContainer: {
    padding: '64px 96px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4)
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  root: {
    flex: 1,
    background: theme.palette.background.default
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  content: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  title: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  buttonPadding: {
    padding: 3
  },
  addressBar: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4)
  },
  paper: {
    height: '100%',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  cardContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  cardIcon: {
    color: theme.palette.common.helperBlack
  },
  cardBalanceBody: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  paperCardFullHeight: {
    height: '100%'
  },
  paperCard: {
    position: 'relative',
    minHeight: 160,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  paperBottomButton: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  dataSection: {
    marginBottom: theme.padding.cardInner
  },
  dataSectionDocs: {
    display: 'flex',
    alignItems: 'center'
  },
  dataSectionLabel: {
    fontSize: theme.fontSizes.tiny
  },
  dataSectionValue: {
    color: theme.colors.black
  },
  depositDiscountAmount: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.2,
    letterSpacing: 1,
    color: theme.palette.common.black,

    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  },
  depositDiscountText: {
    marginTop: theme.spacing(2)
  },
  balanceModalContent: {
    background: theme.colors.backgroundSecondary
  },
  balanceModalItem: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(1) + 1,
    marginBottom: theme.spacing(2),

    background: '#FFFFFF'
  },
  balanceModalCheckbox: {
    position: 'relative',
    width: 18,
    height: 18,
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    border: `2px solid ${theme.colors.brandSecondary}`,
    borderRadius: '50%',

    '&.active': {
      '&::before': {
        content: "''",
        position: 'absolute',
        top: theme.spacing(0.5) + 6,
        left: theme.spacing(0.5) + 6,

        width: 12,
        height: 12,
        backgroundColor: theme.colors.brandSecondary,
        borderRadius: '50%'
      }
    }
  },
  balanceModalCheckboxActive: {
    content: "''",
    position: 'absolute',
    top: 1,
    left: 1,

    width: 12,
    height: 12,
    backgroundColor: theme.colors.brandSecondary,
    borderRadius: '50%'
  },
  balanceModalText: {
    marginRight: theme.spacing(2)
  },
  balanceModalTextMain: {
    marginBottom: theme.spacing(1)
  },
  balanceModalAmount: {
    marginLeft: 'auto'
  },
  tableContentWrapper: {
    '& > div': {
      width: '100%'
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0 16px'
    }
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      maxWidth: `calc(100vw - 32px)`
    },

    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  tableTitleContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(2)}`
    }
  },
  tableToolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [(theme as any).breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.common.disabledBlackBackground}`
    },
    [(theme as any).breakpoints.down('sm')]: {
      overflow: 'hidden',
      flexWrap: 'wrap',
      padding: `0 ${theme.spacing(2)}`
    }
  },
  tableToolbarLeft: {
    display: 'flex'
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    whiteSpace: 'noWrap',
    '& > button': {
      marginLeft: theme.spacing(1.5)
    }
  },
  downloadForm: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: -12,
    '& > *': {
      marginLeft: 12
    }
  },
  downloadButton: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  filterBtn: {
    ...theme.typography.body1,

    border: 'none',
    textAlign: 'center',
    margin: 0,
    marginRight: 26,
    padding: 0,
    paddingBottom: 4,

    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
    cursor: 'pointer',

    '&:focus': { outline: 'none' },
    '&:disabled': {
      color: theme.palette.common.disabledBlack,
      cursor: 'default'
    },
    '&.active': {
      color: theme.palette.common.black,
      position: 'relative',

      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 4,
        borderRadius: 8,
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  creditContent: {
    flex: 1,
    textAlign: 'left'
  },
  creditInput: {
    width: 280
  },
  tmpCreditAmount: {},
  chartTooltip: {
    margin: theme.spacing(1)
  },
  creditFooter: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  creditFooterItem: {
    flex: 1
  },
  creditFooterItemLabel: {
    color: theme.palette.text.secondary
  },
  creditFooterItemBordered: {
    borderLeft: `1px solid ${theme.palette.common.disabledBlackBackground}`,
    paddingLeft: theme.spacing(2)
  },
  dialogTitle: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  dialogText: {
    marginTop: 0,
    marginBottom: theme.spacing(6),
    textAlign: 'center'
  },
  expandedMobileContainer: {
    position: 'relative'
  },
  expandedMobileRow: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  verticalDivider: {
    position: 'relative',

    '&:before': {
      content: "''",
      position: 'absolute',
      top: 4,
      bottom: 4,
      left: -6,
      width: 1,
      backgroundColor: 'lightgrey'
    }
  }
});

export default styles;
