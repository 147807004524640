import React from 'react';
import { Grid, Hidden } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { fetchDiscountConditionsAsync } from 'store/discount-conditions/actions';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { getDiscountConditions, getDiscountConditionsLoadingState } from 'store/discount-conditions/selectors';
import { useTrackInitialPageLoad } from 'controllers';

import DashboardLoyalty from './DashboardLoyalty';
import BalanceCard from 'components/cards/shared/BalanceCard';
import CardCreditLine from 'components/cards/account/CardCreditLine';
import { TitleH1 } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import DashboardDelivery from './DashboardDelivery';

import messages from 'translations/account/dashboard';
import styles from './styles';

interface IProps {
  classes: any;
}

const AccountDashboard: React.FC<IProps> = ({ classes }) => {
  const dispatch = useDispatch();
  const account = useSelector(getUserAccount);
  const accountLoadingState = useSelector(getAccountLoadingState);
  const discountConditions = useSelector(getDiscountConditions);
  const discountConditionsLoading = useSelector(getDiscountConditionsLoadingState);

  if (!discountConditions && !discountConditionsLoading) {
    dispatch(fetchDiscountConditionsAsync.request());
  }

  useTrackInitialPageLoad();

  const combinedConditions = discountConditions
    ? [...discountConditions.permanentDiscounts, ...discountConditions.conditionDiscounts]
    : undefined;

  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <DashboardDelivery account={account} accountLoadingState={accountLoadingState} classes={classes} />
        <Hidden smDown>
          <DashboardLoyalty discountConditions={combinedConditions} />
        </Hidden>
      </Grid>
      <Grid item sm={6} xs={12}>
        <section className={classes.financesSection}>
          <div className={classes.title}>
            <Icon className={classes.titleIcon} type={'wallet'} opacity={1} />
            <TitleH1 className={classes.titleCommon}>{messages.titleFinance.defaultMessage}</TitleH1>
          </div>
          <BalanceCard fullHeight={false} className={classes.financesCard} />
          <div style={{ marginBottom: 16 }} />
          <CardCreditLine fullHeight={false} className={classes.financesCard} />
        </section>
      </Grid>
      <Hidden smUp>
        <Grid item xs={12}>
          <DashboardLoyalty discountConditions={combinedConditions} smallCards />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default withStyles<any>(styles)(AccountDashboard as any) as any;
