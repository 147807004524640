import { FC, useState } from 'react';
import FlrSelect2 from 'components/shared/form-elements/select2/FlrSelect2';
import messages from 'translations/catalog/sort';
import Icon from 'components/shared/Icon';
import classes from './TopBar.module.scss';

export const selectOptions = [
  'name_asc',
  'name_desc',
  'popularityRate',
  'price_asc',
  'price_desc',
  'height_asc',
  'height_desc'
];

export const selectOptionsArray = selectOptions.map(key => ({
  value: key,
  label: messages[key].defaultMessage
}));

interface IPickerProps {
  sortKey?: string;
  setFilter: any;
}

export const SortingSelect: FC<IPickerProps> = ({ sortKey, setFilter }) => {
  const [sortingKeyState, setSortingKeyState] = useState<string>(sortKey || selectOptions[0]);

  const onClickHandlerSelect = (ev: any) => {
    const { value } = ev.target;
    setFilter(value);

    setSortingKeyState(value);
  };

  return (
    <div className={classes.wrapper}>
      <FlrSelect2
        fullWidth
        dropdownMenu
        labelIcon={<Icon type={'sort'} size={24} offset={8} />}
        disableWrapper
        value={sortingKeyState}
        options={selectOptionsArray}
        onChange={onClickHandlerSelect}
        labelIconRight={<Icon type="chevronDown" size={24} leftOffset={2} />}
      />
    </div>
  );
};
