import React from "react";
import { IBreadCrumbsContext } from "./breadCrumbsState";
import { IBreadCrumbLink } from "components/layout/breadcrumbs/Breadcrumbs";

export const useBreadCrumbs = (): IBreadCrumbsContext => {
  const [breadCrumbs, setBreadCrumbs] = React.useState<IBreadCrumbLink[]>([]);

  const setCurrentUrl = React.useCallback((array: IBreadCrumbLink[]): void => {
    setBreadCrumbs(array);
  }, []);

  return {
    breadCrumbs,
    setCurrentUrl
  };
};
