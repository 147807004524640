import clsx from 'clsx';
import { FC } from 'react';
import messages from 'translations/catalog/table-view';
import classes from 'components/cards/product/product-row.module.scss';

interface ICatalogTableHeaderProps {
  key: string;
  style: {
    [key: string]: any;
  };
  isMobile?: boolean;
}

export const CatalogTableHeader: FC<ICatalogTableHeaderProps> = ({ key, style, isMobile }) => {
  return (
    <div key={key} style={{ ...style, height: isMobile ? 44 : style.height, gridAutoRows: isMobile ? 44 : 'inherit' }}>
      <div className={classes.tableLayoutHeader}>
        <div className={classes.nameWrapper}>
          <span className={classes.label}>{messages.tableViewHeaderName.defaultMessage}</span>
        </div>
        <div className={classes.attributesWrapper}>
          <span className={classes.label}>{messages.tableViewHeaderAttrs.defaultMessage}</span>
        </div>
        <div className={classes.manufacturer} style={{ textDecoration: 'none' }}>
          <span className={classes.label}>{messages.tableViewHeaderManufacturer.defaultMessage}</span>
        </div>
        <div className={classes.categoryWrapper}>
          <span className={classes.label}>{messages.tableViewHeaderCategory.defaultMessage}</span>
        </div>
        <div className={classes.unitWrapper}>
          <span className={classes.label}>{messages.tableViewHeaderUnit.defaultMessage}</span>
        </div>
        <div className={classes.inCartWrapper}>
          <span className={classes.label}>{messages.tableViewHeaderInCartCount.defaultMessage}</span>
        </div>
        <div className={classes.discountBadgeHeader}>
          <span className={classes.label}>{messages.tableViewHeaderDiscount.defaultMessage}</span>
        </div>
        <div className={classes.price}>
          <span className={clsx(classes.label, classes.labelPrice)}>
            {messages.tableViewHeaderPrice.defaultMessage}
          </span>
        </div>
        <div className={classes.preOrderTooltipLabel} />
        <div className={classes.iconContainer} />
      </div>
    </div>
  );
};
