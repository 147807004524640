import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Grid, Hidden, IconButton, Tab, Tabs } from '@mui/material';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import moment from 'moment';

import env from 'environment';
import { HttpClient } from 'utils/http';
import {
  isReturnOrRefundTransaction,
  Transaction,
  TransactionOperation,
  TransactionOperationType
} from 'models/transaction';
import messages from 'translations/account/finance';
import { baseUrl } from 'shared/constants';

import { IApplicationState } from 'store/reducers';
import { fetchTransactionsAsync } from 'store/transactions/actions';
import { getTransactionsDataList, getTransactionsLoadingState } from 'store/transactions/selectors';

import Icon from 'components/shared/Icon';
import { FlrButtonKhaki, FlrButtonOutlinedBrown } from 'components/shared/buttons';
import { FlrTableV2 } from 'components/shared/table';
import FlrCard from 'components/shared/card/FlrCard';
import { TitleH2, Link2, TextSubTitle } from 'components/shared/text';
import FlrDatePicker from 'components/shared/form-elements/FlrDatePicker';
import { breadCrumbsContext } from '../breadCrumbsState';
import { DetailsExpandPanel } from './DetailsExpandPanel';
import TableColumns, { columnsMobile } from './TableColumns';

import styles from './styles';

interface IProps {
  classes: any;
}

interface IStateProps {
  transactions: Transaction[];
  transactionsLoadingState: boolean;
}

interface IDispatchProps {
  loadTransactions: typeof fetchTransactionsAsync.request;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;
interface ITab {
  key: string;
  path: string;
  label: string;
  transactionType: any;
}
const rootPath = `/account/finance`;
const paths: ITab[] = [
  {
    key: 'all',
    path: `${rootPath}`,
    label: messages.filterAll.defaultMessage,
    transactionType: ''
  },
  {
    key: 'orders',
    path: `${rootPath}/orders`,
    label: messages.filterOrder.defaultMessage,
    transactionType: TransactionOperation.Order
  },
  {
    key: 'payment',
    path: `${rootPath}/payment`,
    label: messages.filterPayment.defaultMessage,
    transactionType: TransactionOperation.Payment
  },
  {
    key: 'return',
    path: `${rootPath}/return`,
    label: messages.filterReturn.defaultMessage,
    transactionType: TransactionOperation.Refund
  },
  {
    key: 'reclamation',
    path: `${rootPath}/reclamation`,
    label: messages.filterReclamation.defaultMessage,
    transactionType: TransactionOperation.Reclamation
  }
];

const Table: React.FC<IComponentProps> = ({ classes, transactions, transactionsLoadingState, loadTransactions }) => {
  const { status } = useParams<{ status: string }>();

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);

  /*start from week ago*/
  const [dateXlsFrom, setDateXlsFrom] = React.useState<Date | null>(new Date(Date.now() - 60 * 60 * 24 * 7 * 1000));
  const [dateXlsTo, setDateXlsTo] = React.useState<Date | null>(new Date());
  const handleDateChange = (isTo = false) => (date: any) => {
    if (isTo) {
      return setDateXlsTo(date);
    }
    setDateXlsFrom(date);
  };

  const initialTab = status ? paths.findIndex(item => item.path.indexOf(status) >= 0) : 0;
  const [tab, setTab] = React.useState(initialTab > 0 ? initialTab : 0);
  const [transactionsFilter, setTransactionsFilter] = React.useState(
    initialTab > 0 ? paths[initialTab].transactionType : ''
  );

  const handleUpdateFilter = (state: string) => {
    setTransactionsFilter(state);
  };

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCancel = () => {
    setModalOpen(false);
  };
  const handleModalConfirm = () => {
    handleDownloadPDF();
    setModalOpen(false);
  };

  const handleDownloadPDF = () => {
    const url = `${env.apiUrl}/account/transactions/exportToCsv`;
    const httpClient = new HttpClient(url);
    const token = window.localStorage.getItem('token') || '';
    const headers = { Authorization: token };

    httpClient
      .get('', {
        params: {
          startDate: dateXlsFrom,
          endDate: dateXlsTo
        },
        responseType: 'blob',
        headers
      })
      .then(response => {
        downloadFile(response.data, 'transactions.xlsx');
      })
      .catch(err => {
        // todo show toast
      });
  };

  transactions.forEach(txnItem => {
    let transactionOperation = txnItem?.operation?.onModel;
    if (isReturnOrRefundTransaction(txnItem)) {
      switch (txnItem?.operationType) {
        case TransactionOperationType.debit:
          transactionOperation = TransactionOperation.Reclamation;
          break;
        case TransactionOperationType.credit:
          transactionOperation = TransactionOperation.Refund;
          break;
      }
    }
    txnItem.transactionOperation = transactionOperation;
  });

  const filteredTransactions = transactionsFilter
    ? transactions.filter(item => item.transactionOperation === transactionsFilter)
    : transactions;
  const transactionFilterTypesAvailable = transactions
    .map(transaction => transaction.transactionOperation || '')
    .filter((transactionOperation, i, arr) => arr.findIndex(t => t === transactionOperation) === i);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    handleUpdateFilter(paths[newValue].transactionType);
    setTab(newValue);
  };

  const { setCurrentUrl } = React.useContext(breadCrumbsContext);
  const updateBreadCrumbs = React.useCallback(() => {
    const { label } = paths[tab];

    setCurrentUrl([{ label: messages.title.defaultMessage }, { label }]);
  }, [setCurrentUrl, tab]);
  React.useEffect(() => {
    updateBreadCrumbs();
  }, [updateBreadCrumbs]);

  React.useEffect(() => {
    updateBreadCrumbs();

    return () => {
      setCurrentUrl([]);
    };
  }, [setCurrentUrl, tab, updateBreadCrumbs]);

  const tableCommonProps = {
    data: filteredTransactions,
    isLoadingExternal: transactionsLoadingState
  };
  return (
    <FlrCard className={classes.tableContainer} zeroPadding>
      <Grid container justifyContent={'space-between'} alignItems={'center'} className={classes.tableTitleContainer}>
        <Hidden smDown>
          <Grid item sm>
            <TitleH2 style={{ margin: '0 0 20px 0' }}>{messages.transaction.defaultMessage}</TitleH2>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs>
            <TextSubTitle style={{ margin: 0 }}>{messages.transaction.defaultMessage}</TextSubTitle>
          </Grid>
          <Grid item xs={'auto'}>
            <IconButton onClick={handleOpenModal} size="large">
              <Icon type={'download'} size={24} opacity={1} />
            </IconButton>
          </Grid>
        </Hidden>
      </Grid>
      <Grid container justifyContent={'space-between'} className={classes.tableToolbarContainer}>
        <Grid item xs>
          <Tabs
            className={classes.TabsRoot}
            value={tab}
            onChange={handleChange}
            indicatorColor={'primary'}
            variant="scrollable"
            scrollButtons={false}
          >
            {paths.map(tabItem => {
              return (
                <Tab
                  disabled={Boolean(
                    tabItem.transactionType && transactionFilterTypesAvailable.indexOf(tabItem.transactionType) < 0
                  )}
                  key={tabItem.path}
                  label={tabItem.label}
                  component={Link}
                  to={`${baseUrl}${tabItem.path}`}
                />
              );
            })}
          </Tabs>
        </Grid>
        <Hidden smDown>
          <Grid item sm={'auto'}>
            <div className={classes.filterContainer}>
              <Link2 className={classes.downloadButton} onClick={handleOpenModal}>
                <Icon type={'attach'} offset={8} size={16} />
                <span>{messages.transactionDownloadXLS.defaultMessage}</span>
              </Link2>
            </div>
          </Grid>
        </Hidden>
      </Grid>
      <Grid container className={classes.tableContentWrapper}>
        <Hidden smUp>
          <FlrTableV2 {...tableCommonProps} detailPanel={DetailsExpandPanel} columns={columnsMobile} />
        </Hidden>
        <Hidden smDown>
          <FlrTableV2 {...tableCommonProps} columns={TableColumns} />
        </Hidden>
      </Grid>
      <Dialog
        open={modalOpen}
        onClose={handleCancel}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{messages.downloadModalTitle.defaultMessage}</DialogTitle>
        <DialogContent dividers={true}>
          <p>{messages.downloadModalCaption.defaultMessage}</p>
          <div className={classes.downloadForm}>
            <FlrDatePicker
              id="date-from"
              maxDate={moment(dateXlsTo)}
              label={messages.downloadDateFrom.defaultMessage}
              value={moment(dateXlsFrom)}
              onChange={handleDateChange()}
            />
            <FlrDatePicker
              id="date-to"
              disableFuture
              label={messages.downloadDateTo.defaultMessage}
              value={moment(dateXlsTo)}
              minDate={moment(dateXlsFrom)}
              onChange={handleDateChange(true)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <FlrButtonOutlinedBrown onClick={handleCancel} color="primary">
            {messages.cancelBtnLabel.defaultMessage}
          </FlrButtonOutlinedBrown>
          <FlrButtonKhaki onClick={handleModalConfirm} color="primary">
            {messages.downloadModalButton.defaultMessage}
          </FlrButtonKhaki>
        </DialogActions>
      </Dialog>
    </FlrCard>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  transactions: getTransactionsDataList(state),
  transactionsLoadingState: getTransactionsLoadingState(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      loadTransactions: fetchTransactionsAsync.request
    },
    dispatch
  )
});

function downloadFile(data: any, fileName: string): void {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName || 'file.pdf');
  document.body.appendChild(link);
  link.click();
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(Table as any));
