import React, { useContext, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Button, Grid, Hidden, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { defaultLocale } from 'shared/constants';
import { PERSONAL_DISCOUNT } from 'models';
import { getUserAccount } from 'store/account/selectors';
import { getDiscountConditions } from 'store/discount-conditions/selectors';
import BalanceCard, { DEFAULT_AMOUNT } from 'components/cards/shared/BalanceCard';
import { TitleH1, TitleH2, TextSubTitle } from 'components/shared/text';
import PaymentModal from 'components/shared/modals/payment/PaymentModal';
import { loyaltyBreadcrumb, loyaltyBreadcrumbMobile } from './index';
import { breadCrumbsContext } from '../breadCrumbsState';
import { PersonalDiscountCard, BalanceHistoryCard } from './cards';
import DiscountRules from './rules/DiscountRules';
import styles from './styles';
import messages from 'translations/account/loyalty';
import messagesFinance from 'translations/account/finance';
import PersonalDiscountProgressCard from './cards/PersonalDiscountProgressCard';
import DashboardLoyalty from '../dashboard/DashboardLoyalty';

interface IProps {
  classes: any;
}

const BalanceDiscount: React.FC<IProps> = ({ classes }) => {
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalPayOpen, setModalPayOpen] = useState(false);
  const account = useSelector(getUserAccount);
  const discountConditions = useSelector(getDiscountConditions);
  const breadcrumb = screenMobile ? loyaltyBreadcrumbMobile : loyaltyBreadcrumb;
  const { setCurrentUrl } = useContext(breadCrumbsContext);
  const updateBreadCrumbs = useCallback(
    bc => {
      setCurrentUrl([{ label: bc.label, path: bc.path }, { label: messages.titleBalance.defaultMessage }]);
    },
    [setCurrentUrl]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    updateBreadCrumbs(breadcrumb);

    return () => {
      setCurrentUrl([]);
    };
  }, [breadcrumb, setCurrentUrl, updateBreadCrumbs]);

  const handlePayCancel = () => {
    setModalPayOpen(false);
  };

  const handleOpenPayModal = () => {
    setModalPayOpen(true);
  };

  const creditUsed = account && account.profile.balance < 0 ? -1 * account.profile.balance : DEFAULT_AMOUNT;

  const nextUpdateDate = moment()
    .locale(defaultLocale)
    .startOf('isoWeek')
    .add(1, 'week');

  const cards = (
    <React.Fragment>
      {discountConditions?.accountBalanceDiscount ? (
        <>
          <Grid item xs>
            <PersonalDiscountCard discount={discountConditions.accountBalanceDiscount} account={account} />
          </Grid>
          <Grid item xs>
            <PersonalDiscountProgressCard
              progressValue={discountConditions.accountBalanceDiscount.discountData.progressValue || 0}
            />
          </Grid>
        </>
      ) : null}
      <Grid item xs>
        <BalanceCard buttonVariant="outlined" />
      </Grid>
    </React.Fragment>
  );

  const lastDayOfMonth = moment()
    .endOf('month')
    .format('D MMMM YYYY');

  return (
    <>
      <Grid item container spacing={3} direction="column">
        <Grid item spacing={2} container justifyContent="space-between">
          {discountConditions?.permanentDiscounts && discountConditions?.permanentDiscounts.length ? (
            <>
              <Grid container item spacing={1}>
                <TitleH1>{messages.titlePromotions.defaultMessage}.</TitleH1>
              </Grid>
              <DashboardLoyalty
                discountConditions={discountConditions?.permanentDiscounts}
                hideTitle
                hideProfileDiscount
                smallCards
              />
            </>
          ) : null}
          <Grid item>
            <TitleH1 className={classes.discountGroupsTitle}>
              <FormattedMessage {...messages.subTitleGroups} values={{ lastDayOfMonth }} />.
            </TitleH1>
            <TextSubTitle>{messages.groupsDescriptionP1.defaultMessage}.</TextSubTitle>
            <TextSubTitle>{messages.groupsDescriptionP2.defaultMessage}.</TextSubTitle>
            <TextSubTitle className={classes.discountGroupsDesc}>
              {messages.groupsDescriptionP3.defaultMessage}.
            </TextSubTitle>
          </Grid>
          <DashboardLoyalty
            discountConditions={discountConditions?.conditionDiscounts}
            hideTitle
            hideProfileDiscount
            smallCards
          />
        </Grid>
        <Grid item container spacing={3} justifyContent="space-between">
          <Grid item container xs spacing={1} direction="column">
            <Grid item sm>
              <TitleH1 className={classes.header}>{messages.titleBalance.defaultMessage}</TitleH1>
            </Grid>
            <Grid item xs>
              <TextSubTitle>
                <FormattedMessage
                  {...messages.nextDiscountUpdate}
                  values={{
                    date: nextUpdateDate.format('DD MMMM'),
                    time: '00:01'
                  }}
                />
              </TextSubTitle>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item sm="auto">
              <Button color="primary" variant="contained" onClick={handleOpenPayModal}>
                {messagesFinance.topUpBalance.defaultMessage}
              </Button>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item container spacing={3} alignItems="flex-start">
          <Hidden smDown>
            <Grid item sm={5}>
              <Grid container spacing={3} direction="column">
                {cards}
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={7} direction="column">
              <Grid item xs>
                <BalanceHistoryCard />
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item container spacing={2} direction="column">
              {cards}
              <Grid item xs>
                <BalanceHistoryCard mobile={screenMobile} />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item container className={classes.discountRules}>
          <Grid item xs={12}>
            <TitleH2>{messages.rules.defaultMessage}</TitleH2>
          </Grid>
          <Grid item container xs={12} spacing={4}>
            <Grid item container xs={12} sm={6} spacing={6} direction="column" id="discount-rules-anchor">
              <DiscountRules type={PERSONAL_DISCOUNT} />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <DiscountLevels type={PERSONAL_DISCOUNT} /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {modalPayOpen && <PaymentModal open={modalPayOpen} handleClose={handlePayCancel} amount={creditUsed} />}
    </>
  );
};

export default withStyles<any>(styles)(BalanceDiscount) as any;
