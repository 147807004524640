import React from 'react';
import { Box, Grid, Hidden } from '@mui/material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import { Order, ORDER_TYPE_PREORDER } from 'models';

import { TextBody1, TextBody2, TextSubTitle } from 'components/shared/text';
import { GridDivider, GridDividerBold } from 'components/shared/table/dividers';
import { SplitedPrice } from 'components/prices';

import { UAH } from 'shared/constants';
import { methodLabel } from 'utils/helpers';

import styles from './styles';

import messages from 'translations/checkout/common';
import cartMessages from 'translations/cart/common';
import OrderComment from './OrderComment';

interface IProps {
  order: Order;
  withDiscount?: boolean;
  withItemRemove?: boolean;
  withComment?: boolean;
  classes: any;
  index?: number;
  withCommentEdit?: boolean;
}

const OrderCardSubtotal: React.FC<IProps> = ({
  order,
  withItemRemove,
  withDiscount,
  withComment = true,
  classes,
  withCommentEdit,
  index
}) => {
  const isPreOrder = order.orderType === ORDER_TYPE_PREORDER;
  const discountBlock = (
    <Grid item xs sm={'auto'} className={classes.padded}>
      <Grid container direction={'column'} spacing={1}>
        <Grid item>
          <TextBody2 color={'inherit'} align={'right'}>
            {cartMessages.totalDiscount.defaultMessage}
          </TextBody2>
        </Grid>
        <Grid item className={classes.textRight}>
          <SplitedPrice fontColor={'inherit'} fontSize={'subtitle1'} value={order.totalDiscount} postfix={UAH} />
        </Grid>
      </Grid>
    </Grid>
  );

  const mobilePricesCommonProps = {
    fontColor: 'inherit' as 'inherit',
    fontSize: 'body2' as 'body2',
    bold: false,
    postfix: UAH
  };

  const commentRender =
    withComment &&
    (withCommentEdit && index !== undefined ? (
      <div style={{ marginTop: 20, marginBottom: 15, marginLeft: 15 }}>
        <OrderComment index={index - 1} />
      </div>
    ) : (
      order.notes && (
        <Grid item>
          <Box>
            <TextBody1 className={classes.commentTitle} style={{ paddingBottom: 3 }}>
              Коментар до замовлення
            </TextBody1>
          </Box>
          <Box>
            <TextSubTitle>{order.notes}</TextSubTitle>
          </Box>
        </Grid>
      )
    ));

  return (
    <React.Fragment>
      {!withItemRemove ? <GridDivider /> : <GridDividerBold />}
      <Hidden smDown>
        <Grid item container className={clsx([classes.tableItem, classes.gray])} alignItems={'center'}>
          <Grid item xs>
            Сума товарів
          </Grid>
          <Grid item xs={2} />
          <Grid item container sm={'auto'} style={{ width: 240 }} justifyContent={'center'}>
            <SplitedPrice
              value={order.totalQty}
              fontSize={'h3'}
              fontSizeDecimal={'h5'}
              hideDecimal={true}
              bold={false}
            />
          </Grid>
          {withItemRemove && <Grid item xs={1} />}
          {withDiscount && (
            <Grid item xs={1} container justifyContent={'flex-end'}>
              <SplitedPrice
                value={order.totalDiscount}
                fontSize={'h3'}
                fontSizeDecimal={'h5'}
                postfix={UAH}
                bold={false}
              />
            </Grid>
          )}
          <Grid item xs={'auto'} container justifyContent={'flex-end'} className={classes.orderItemSubtotal}>
            <SplitedPrice
              value={order.totalItemsSumWithDiscount()}
              fontSize={'h3'}
              fontSizeDecimal={'h5'}
              postfix={UAH}
              bold={false}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item container sm justifyContent={'flex-end'} className={classes.gray} spacing={2}>
          <Grid item xs={4} className={classes.padded}>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <TextBody2 color={'inherit'} align={'right'}>
                  {cartMessages.totalQty.defaultMessage}
                </TextBody2>
              </Grid>
              <Grid item className={classes.textRight}>
                <SplitedPrice value={order.totalQty} {...mobilePricesCommonProps} postfix={'шт.'} hideDecimal />
              </Grid>
            </Grid>
          </Grid>
          {order.totalDiscount > 0 && discountBlock}
          <Grid item xs={4} className={classes.padded}>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <TextBody2 color={'inherit'} align={'right'}>
                  {cartMessages.totalSum.defaultMessage}
                </TextBody2>
              </Grid>
              <Grid item className={classes.textRight}>
                <SplitedPrice
                  value={order.totalItemsSumWithDiscount()}
                  prefix={isPreOrder ? '~' : undefined}
                  {...mobilePricesCommonProps}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <GridDivider />
      {/* TODO hide for now */}
      {/* <Grid item container spacing={1} className={clsx(classes.tableItem, classes.disabled)} alignItems={"center"}>
        <Grid item container alignItems={"center"}>
          <Grid item xs>
            <TextSubTitle component={"span"} color={"textPrimary"}>
              {packageIdLabel(order.packageId)}
            </TextSubTitle>
          </Grid>
          <Grid item xs={"auto"}>
            <SplitedPrice value={order.packingPrice()} {...mobilePricesCommonProps} />
          </Grid>
        </Grid>
        {order.packageReturn && (
          <Grid item container>
            <Grid item xs>
              <TextBody2 color={"textSecondary"}>{messages.packageReturnSelected.defaultMessage}</TextBody2>
            </Grid>
            <Grid item xs={"auto"} className={classes.fundsPositive}>
              <SplitedPrice value={-order.packingReturnPrice()} {...mobilePricesCommonProps} />
            </Grid>
          </Grid>
        )}
      </Grid>
      <GridDivider /> */}
      <Grid item container className={classes.tableItem} alignItems={'center'}>
        <Grid item xs>
          <TextSubTitle style={{ margin: 0 }}>
            Доставка {methodLabel(order.deliveryMethod && order.deliveryMethod)}
          </TextSubTitle>
        </Grid>
        <Grid item>
          <SplitedPrice
            value={order.deliveryPrice()}
            {...mobilePricesCommonProps}
            fontSize={'h3'}
            fontSizeDecimal={'h5'}
          />
        </Grid>
      </Grid>
      <GridDividerBold />
      <Hidden mdUp>{commentRender}</Hidden>
      <Grid item container className={classes.tableItem} alignItems={'center'} justifyContent={'flex-end'}>
        <Grid item className={clsx(classes.black, classes.textRight)}>
          <Hidden smUp>
            <TextBody2 color={'textSecondary'}>{messages.totalSum.defaultMessage}</TextBody2>
          </Hidden>
          <SplitedPrice
            value={order.totalSumAll()}
            prefix={isPreOrder ? '~' : undefined}
            fontSize={'h2'}
            fontSizeDecimal={'body2'}
            postfix={UAH}
          />
        </Grid>
      </Grid>
      <Hidden lgDown>{commentRender}</Hidden>
    </React.Fragment>
  );
};

export default withStyles<any>(styles)(OrderCardSubtotal);
