import { defineMessages } from 'react-intl';

export default defineMessages({
  unit: {
    id: 'cart.unit',
    description: '',
    defaultMessage: 'Штук'
  },
  price: {
    id: 'cart.price',
    description: '',
    defaultMessage: 'Ціна'
  },
  cancelText: {
    id: 'account.transaction.cancelText',
    description: '',
    defaultMessage: 'Скасувати'
  },
  refoundBalance: {
    id: 'account.transaction.refoundBalance',
    description: '',
    defaultMessage: 'Поповнити баланс'
  },
  cart: {
    id: 'cart.title',
    description: 'Cart title',
    defaultMessage: 'Товари у кошику'
  },
  inCart: {
    id: 'cart.header.title',
    description: 'cart.header.title',
    defaultMessage: 'Кошик'
  },
  emptyCart: {
    id: 'cart.emptyCart',
    description: 'cart.emptyCart',
    defaultMessage: 'У кошику порожньо!'
  },
  emptyCartCaption: {
    id: 'cart.emptyCartCaption',
    description: 'cart.emptyCartCaption',
    defaultMessage:
      'Щоб додати товар у кошик оберіть його у Каталозі та на найбільш підходящій Вам пропозиції натисніть кнопку “Купити” або “Замовити”!'
  },
  total: {
    id: 'cart.total',
    description: 'catalog.cart.total',
    defaultMessage: 'Сума'
  },
  totalSum: {
    id: 'cart.totalSum',
    description: 'cart.totalSum',
    defaultMessage: 'Сума'
  },
  totalDiscount: {
    id: 'cart.totalDiscount',
    description: 'cart.totalDiscount',
    defaultMessage: 'Знижка'
  },
  totalQty: {
    id: 'cart.totalQty',
    description: 'cart.totalQty',
    defaultMessage: 'Кількість'
  },
  qtyInItems: {
    id: 'cart.totalQty',
    description: 'cart.totalQty',
    defaultMessage: 'Кількість, шт'
  },
  pricePerItem: {
    id: 'cart.pricePerItem',
    description: 'cart.pricePerItem',
    defaultMessage: 'Ціна'
  },
  inPackageCountItem: {
    id: 'cart.inPackageCountItem',
    description: 'cart.inPackageCountItem',
    defaultMessage: 'уп'
  },
  totalWithDiscount: {
    id: 'cart.totalWithDiscount',
    description: 'catalog.cart.totalWithDiscount',
    defaultMessage: 'Сума'
  },
  buy: {
    id: 'cart.buy',
    description: 'cart.buy',
    defaultMessage: 'Купити'
  },
  toDelivery: {
    id: 'cart.toDelivery',
    description: 'cart.toDelivery',
    defaultMessage: 'Оформити доставку'
  },
  preOrder: {
    id: 'cart.preOrder',
    description: 'catalog.cart.preOrder',
    defaultMessage: 'Замовити'
  },
  removeFromCart: {
    id: 'cart.removeFromCart',
    description: 'cart.removeFromCart',
    defaultMessage: 'Прибрати з Кошика'
  },
  showAllItems: {
    id: 'cart.showAllItems',
    description: 'cart.common.showAllItems',
    defaultMessage: 'Показати список товарів'
  },
  hideItems: {
    id: 'cart.hideItems',
    description: 'cart.common.hideItems',
    defaultMessage: 'Приховати список товарів'
  },
  orders: {
    id: 'catalog.cart.orders',
    description: 'catalog.cart.orders',
    defaultMessage: 'Замовлення'
  },
  preOrders: {
    id: 'cart.preOrders',
    description: 'catalog.cart.preOrders',
    defaultMessage: 'Передзамовлення'
  },
  transitOrders: {
    id: 'cart.transitOrders',
    description: 'catalog.cart.transitOrders',
    defaultMessage: 'В дорозі'
  },
  inTransitWithAmount: {
    id: 'cart.inTransitWithAmount',
    description: 'catalog.cart.inTransitWithAmount',
    defaultMessage: 'В дорозі на склад ({items})'
  },
  preOrdersWithAmount: {
    id: 'cart.preOrdersWithAmount',
    description: 'catalog.cart.preOrdersWithAmount',
    defaultMessage: 'Передзамовлення ({items})'
  },
  ordersWithAmount: {
    id: 'catalog.cart.ordersWithAmount',
    description: 'catalog.cart.ordersWithAmount',
    defaultMessage: 'Замовлення ({items})'
  },
  attributeInPackage: {
    id: 'catalog.cart.attributeInPackage',
    description: 'catalog.cart.attributeInPackage',
    defaultMessage: 'В упаковцi'
  },
  moreInfo: {
    id: 'catalog.cart.moreInfo',
    description: 'catalog.cart.moreInfo',
    defaultMessage: 'Більше'
  },
  lessInfo: {
    id: 'catalog.cart.lessInfo',
    description: 'catalog.cart.lessInfo',
    defaultMessage: 'Менше'
  },
  totalInCart: {
    id: 'catalog.cart.totalInCart',
    description: 'catalog.cart.totalInCart',
    defaultMessage: 'Загалом у кошику'
  },
  totalToOrder: {
    id: 'catalog.cart.totalToOrder',
    description: 'catalog.cart.totalInCart',
    defaultMessage: 'Загалом до замовлення'
  },
  totalToPreOrder: {
    id: 'catalog.cart.totalToPreOrder',
    description: 'catalog.cart.totalInCart',
    defaultMessage: 'Загалом до передзамовлення'
  },
  notEnoughFundsTitle: {
    id: 'cart.notEnoughFundsTitle',
    description: 'cart.notEnoughFundsTitle',
    defaultMessage: 'Не вистачає коштів на балансі  (<price>{amount}</price> + доставка)'
  },
  notEnoughFundsTextShort: {
    id: 'cart.notEnoughFundsTextShort',
    description: 'cart.notEnoughFundsTextShort',
    defaultMessage: 'Не вистачає коштів на балансі!'
  },
  notEnoughFundsTextShortYourBalance: {
    id: 'cart.notEnoughFundsTextShortYourBalance',
    description: 'cart.notEnoughFundsTextShortYourBalance',
    defaultMessage: 'Ваш баланс: '
  },
  notEnoughFundsBtn: {
    id: 'cart.notEnoughFundsBtn',
    description: 'cart.notEnoughFundsBtn',
    defaultMessage: 'Поповнити баланс'
  },
  notEnoughFundsText: {
    id: 'cart.notEnoughFundsText',
    description: 'cart.notEnoughFundsText',
    defaultMessage: '<url>Поповніть баланс</url> або подайте заявку на збільшення кредитної лінії.'
  },
  backToCatalog: {
    id: 'cart.backToCatalog',
    description: 'cart.backToCatalog',
    defaultMessage: 'Перейти до каталогу'
  },
  continueShopping: {
    id: 'cart.continueShopping',
    description: 'cart.continueShopping',
    defaultMessage: 'Продовжити покупки'
  },

  notificationExpiredCartRemoved: {
    id: 'expiredCart.notification.expiredCartRemoved',
    description: 'expiredCart.notification.expiredCartRemoved',
    defaultMessage: 'Успішно видалена'
  },
  notificationExpiredGroupCartRemoved: {
    id: 'expiredCart.notification.expiredCartRemoved',
    description: 'expiredCart.notification.expiredCartRemoved',
    defaultMessage: 'Група успішно видалена'
  },
  expiredCartRestore: {
    id: 'expiredCart.notification.expiredCartRestore',
    description: 'expiredCart.notification.expiredCartRemoved',
    defaultMessage: 'Товари успішно відновлені в кошику'
  },
  expiredCartOutOfStock: {
    id: 'expiredCart.notification.expiredCartOutOfStock',
    description: 'expiredCart.notification.expiredCartOutOfStock',
    defaultMessage: 'Товару немає в наявності'
  },
  notificationExpiredCartIn15Minutes: {
    id: 'expiredCart.notification.expiredCartIn15Minutes',
    description: 'expiredCart.notification.expiredCartIn15Minutes',
    defaultMessage: 'Увага, час резервування кошика закінчиться менше ніж через 15 хвилин.'
  },
  notificationCartIsExpired: {
    id: 'expiredCart.notification.cartIsExpired',
    description: 'expiredCart.notification.cartIsExpired',
    // defaultMessage: "Всі товари повернулись у Каталог. Перейдіть у Кошик, щоб їх відновити."
    defaultMessage: 'Всі товари повернулись у Каталог.'
  },
  notificationCartIsExpiredTitle: {
    id: 'expiredCart.notification.cartIsExpiredTitle',
    description: 'expiredCart.notification.cartIsExpiredTitle',
    defaultMessage: 'Неоформлені замовлення'
  },
  hideAllCategories: {
    id: 'cart.hideAllCategories',
    description: 'cart.hideAllCategories',
    defaultMessage: 'Приховати все'
  },
  showAllCategories: {
    id: 'cart.showAllCategories',
    description: 'cart.showAllCategories',
    defaultMessage: 'Показати все'
  },
  catalogCategory: {
    id: 'cart.catalogCategory',
    description: 'catalogCategory',
    defaultMessage: 'Категорія'
  },
  productGroup: {
    id: 'cart.productGroup',
    description: 'productGroup',
    defaultMessage: 'Підкатегорія'
  },
  inPackageCount: {
    id: 'cart.inPackageCount',
    description: 'inPackageCount',
    defaultMessage: 'Кількість, уп'
  },
  manufacturerName: {
    id: 'cart.manufacturerName',
    description: 'manufacturerName',
    defaultMessage: 'Виробник'
  },
  country: {
    id: 'cart.country',
    description: 'country',
    defaultMessage: 'Країна'
  },
  code: {
    id: 'cart.code',
    description: 'code',
    defaultMessage: 'Артикул'
  },
  qtyInPackage: {
    id: 'cart.qtyInPackage',
    description: 'cart.qtyInPackage',
    defaultMessage: 'Уп.'
  }
});
