import React from "react";
import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { TextBody2 } from "components/shared/text";
import styles from "./styles";

interface IProps {
  itemsNumber: number;
  withDiscount?: boolean;
  withItemRemove?: boolean;
  classes: any;
}

const OrderCardItemHeaders: React.FC<IProps> = ({ itemsNumber, withItemRemove, withDiscount, classes }) => {
  return (
    <Grid item container spacing={2} className={classes.tableHeaders}>
      <Grid item sm>
        <TextBody2>Товар ({itemsNumber})</TextBody2>
      </Grid>
      <Grid item sm={2}>
        <TextBody2 align={"right"}>Ціна/шт.</TextBody2>
      </Grid>
      <Grid item sm={"auto"} style={{ width: 240 }}>
        <TextBody2 align={"center"}>Кількість, шт</TextBody2>
      </Grid>
      {!!withItemRemove && (
        <Grid item sm={1}>
          &nbsp;
        </Grid>
      )}
      {!!withDiscount && (
        <Grid item sm={1}>
          <TextBody2 align={"right"}>Знижка, %</TextBody2>
        </Grid>
      )}
      <Grid item sm={"auto"} className={classes.orderItemSubtotal}>
        <TextBody2 align={"right"}>Сума</TextBody2>
      </Grid>
    </Grid>
  );
};

export default withStyles<any>(styles)(OrderCardItemHeaders);
