import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ReactComponent as TableSortDefault } from 'components/shared/icons/table-sort-default.svg';

interface IEnhancedTableProps {
  classes?: any;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  headCells: any;
}

export function EnhancedTableHead(props: IEnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.field}
            align={headCell.type === 'numeric' ? 'right' : 'left'}
            sortDirection={orderBy === headCell.field ? order : false}
            padding="none"
          >
            <TableSortLabel
              IconComponent={TableSortDefault as any}
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : 'asc'}
              onClick={createSortHandler(headCell.field)}
              style={{ whiteSpace: 'nowrap', paddingBottom: 10, paddingTop: 10 }}
            >
              {headCell.title}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
