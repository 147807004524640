import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTheme, withStyles } from '@mui/styles';
import { Button, Grid, Hidden, Theme, useMediaQuery } from '@mui/material';
import { animateScroll as scroll } from 'react-scroll';
import { useHistory, useRouteMatch } from 'react-router';

import { IApplicationState } from 'store/reducers';
import { getCart, getCartLoaded, getCartLoadingState } from 'store/cart/selectors';
import {
  cancelCheckout,
  fetchCheckoutAsync,
  finalizeAllOrdersAsync,
  finalizeOrderAsync,
  setActiveOrder,
  selectOrderItem,
  unselectOrderItem,
  updateOrdersAsync
} from 'store/checkout/actions';
import {
  getCheckoutActiveOrder,
  getCheckoutSelectedOrderItem,
  getCheckoutFinalizedOrders,
  getCheckoutFinalizingOrders,
  getCheckoutLoaded,
  getCheckoutLoadingState,
  getCheckoutOrders
} from 'store/checkout/selectors';
import { getOutletsData, getOutletsLoadingState } from 'store/outlets/selectors';

import { Cart, Order, ORDER_TYPE_PREORDER, Outlet } from 'models';
import { GTM } from 'controllers';
import { useTrackInitialPageLoad } from 'controllers';

import SidePanelsLayout from 'components/layout/side-panels-layout';
import { TitleH1, TextBody1, TextSubTitle } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import CheckoutMain from './components/CheckoutMain';
import { baseUrl, cartGroupCheckoutStorageKey, hideRightPanelWidth, UAH } from 'shared/constants';
import FullHeightSkeleton from 'components/loading/FullHeightSkeleton';
import Breadcrumbs from 'components/layout/breadcrumbs/Breadcrumbs';
import CheckoutRightSide from './components/CheckoutRightSide';
import AsideDeviceTopPanel from 'components/layout/aside-device/header';
import AsideDeviceBottomPanel from 'components/layout/aside-device/footer';
import { SplitedPrice } from 'components/prices';
import FlrLoader from 'components/loading/LoadingSpinner';

import styles from './styles';
import messages from 'translations/checkout/common';
import { ISetActiveItem } from 'store/cart/actions';
import moment from 'moment';
import { upperFirst } from 'lodash';
import HoldCart from '../../components/timer/HoldCart';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

interface IProps {
  classes: any;
}

interface IStateProps {
  locale: any;
  cartLoaded: boolean;
  cartLoadingState: boolean;
  cart: Cart;
  checkoutLoaded: boolean;
  checkoutLoadingState: boolean;
  checkoutOrders: Order[];
  checkoutFinalizingOrders: boolean;
  checkoutFinalizedOrders: Order[];
  outletsLoadingState: boolean;
  outlets: Outlet[];
  activeOrder: ISetActiveItem;
  activeOrderItem: string;
}

interface IDispatchProps {
  fetchCheckoutOrders: typeof fetchCheckoutAsync.request;
  updateCheckoutOrders: typeof updateOrdersAsync.request;
  submitCheckoutOrder: typeof finalizeOrderAsync.request;
  submitAllCheckoutOrders: typeof finalizeAllOrdersAsync.request;
  cancelOrders: typeof cancelCheckout;
  setActiveOrderIndex: typeof setActiveOrder;
  setActiveOrderItem: typeof selectOrderItem;
  unsetActiveOrderItem: typeof unselectOrderItem;
}

type CheckoutProps = IProps & IStateProps & IDispatchProps;

// redirect time for inactive user 10 min
const INACTIVE_USER_TIME_THRESHOLD = 10 * 60 * 1000;

let userActivityTimeout: any = null;

function setUserActivityTimeout() {
  clearTimeout(userActivityTimeout);
  userActivityTimeout = setTimeout(() => {
    window.location.replace(`${baseUrl}/cart`);
  }, INACTIVE_USER_TIME_THRESHOLD);
}

const UnconnectedCheckout: React.FC<CheckoutProps> = ({
  locale,
  outlets,
  cart,
  cartLoaded,
  cartLoadingState,
  checkoutOrders,
  checkoutLoaded,
  checkoutLoadingState,
  outletsLoadingState,
  checkoutFinalizingOrders,
  checkoutFinalizedOrders,
  classes,
  fetchCheckoutOrders,
  updateCheckoutOrders,
  submitCheckoutOrder: onSubmitAction,
  submitAllCheckoutOrders,
  cancelOrders,
  activeOrder,
  setActiveOrderIndex,
  activeOrderItem,
  setActiveOrderItem,
  unsetActiveOrderItem
}) => {
  useTrackInitialPageLoad();

  const checkoutGroup = String(window.localStorage.getItem(cartGroupCheckoutStorageKey));
  const isCheckoutPage = !!useRouteMatch(`${baseUrl}/checkout`);

  /** mobile header */
  const history = useHistory();
  const handleMobileClose = () => {
    cancelOrders();
    history.push(`${baseUrl}/cart`);
  };

  useEffect(() => {
    setUserActivityTimeout();
    window.addEventListener('click', setUserActivityTimeout);
    window.addEventListener('keydown', setUserActivityTimeout);
    return () => {
      clearTimeout(userActivityTimeout);
      window.removeEventListener('click', setUserActivityTimeout);
      window.removeEventListener('keydown', setUserActivityTimeout);
    };
  }, []);

  useEffect(() => {
    if (cartLoadingState || !checkoutGroup) {
      return;
    }
    if (
      checkoutGroup &&
      checkoutGroup !== 'null' &&
      cartLoaded &&
      !cart.groups[checkoutGroup] &&
      cart.items.length > 0
    ) {
      cancelOrders();
    }
  }, [cart, cartLoadingState, cartLoaded, checkoutGroup, cancelOrders]);

  useEffect(() => {
    if (!cart.items || !cart.items.length) {
      return;
    }
    GTM.trackEnterToCheckoutPage(cart);
    // eslint-disable-next-line
  }, [cartLoaded]);

  useEffect(() => {
    if (checkoutGroup) {
      if (!checkoutLoaded && !checkoutLoadingState) {
        fetchCheckoutOrders(checkoutGroup);
      }
    } else if (checkoutLoaded) {
      cancelOrders();
    }
    // eslint-disable-next-line
  }, []);

  const warehouseName = checkoutOrders && checkoutOrders[0] && checkoutOrders[0].warehouse.displayName;
  const isPreOrder = checkoutOrders && checkoutOrders[0] && checkoutOrders[0].orderType === ORDER_TYPE_PREORDER;
  const finalizedOrder = checkoutFinalizedOrders[0];
  const order = checkoutOrders[0];
  const preOrder = isPreOrder;

  const anchor = 'order-card';

  const offset = useRef(0);
  useEffect(() => {
    const containerEl = document.getElementById(`${anchor}-container`);
    if (!checkoutLoaded || checkoutLoadingState || !containerEl) {
      return;
    }
    const firstAnchor = containerEl.querySelector(`.${anchor}-item`);
    const allAnchors = containerEl.querySelectorAll(`.${anchor}-item`);
    const lastAnchor = allAnchors.length > 2 && (allAnchors.item(allAnchors.length - 2) as HTMLElement);
    const buttonsAnchor = allAnchors.length > 1 && (allAnchors.item(allAnchors.length - 1) as HTMLElement);
    if (firstAnchor) {
      const el = firstAnchor as HTMLElement;
      offset.current = -(el.offsetTop + 100);
      if (buttonsAnchor && lastAnchor) {
        // style={{ marginBottom: hasNext ? "" : Math.max(0, 3 - order.items.length) * 70 }}
        buttonsAnchor.style.marginBottom = `${Math.abs(
          150 + offset.current + Math.max(0, containerEl.offsetHeight - lastAnchor.offsetHeight)
        )}px`;
      }

      // dirty hack to enable first option
      setTimeout(() => {
        if (containerEl.scrollTop === 0) {
          scroll.scrollTo(1, { containerId: `${anchor}-container` });
        }
      }, 100);
    }
  }, [offset, checkoutLoaded, checkoutLoadingState]);

  const theme: Theme = useTheme();
  const screenFullHD = useMediaQuery(theme.breakpoints.up('xl'));
  const screenHD = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const hideRightPanel = useMediaQuery(theme.breakpoints.down(hideRightPanelWidth));
  const [openRightPanel, setOpenRightPanel] = useState(false);

  const hasMultiOrders = checkoutOrders.length + checkoutFinalizedOrders.length > 1;

  const nowHours = moment().hours();
  const createDeliveryOptions = () => {
    return [
      preOrder ? moment().add(5, 'days') : moment().add(moment().hours() >= 18 ? 1 : 0, 'days'),
      preOrder ? moment().add(7, 'days') : moment()
    ];
  };
  // eslint-disable-next-line
  const deliveryOptions = useMemo(createDeliveryOptions, [preOrder, nowHours]);

  const orderToExtractDate =
    (order && order.deliveryDate && order) || (finalizedOrder && finalizedOrder.deliveryDate && finalizedOrder);
  // @ts-ignore
  const orderDate = orderToExtractDate && moment(orderToExtractDate.deliveryDate).format('dd DD.MM');

  const breadCrumbs = (
    <Breadcrumbs
      className={classes.checkoutBreadcrumbs}
      links={[
        {
          path: '/catalog/shear-flowers',
          label: 'Каталог'
        },
        {
          path: '/cart',
          label: 'Кошик'
        },
        {
          label: 'Оформлення замовлення'
        }
      ]}
    />
  );

  // validation
  const [submitted, setSubmitted] = React.useState(false);
  const onSubmit = (orderToSubmit: Order) => {
    setSubmitted(true);
    // tslint:disable-next-line:no-unused-expression
    if (orderToSubmit.isValid()) {
      onSubmitAction(orderToSubmit);
    } else {
      scrollToTop();
    }
  };

  const firstOrder = checkoutOrders && checkoutOrders[0];
  const isFirstOrderPreOrder = firstOrder && firstOrder.orderType === ORDER_TYPE_PREORDER;

  const checkoutOrderOnSubmit = (orderToSubmit: Order) => {
    if (checkoutOrders.length === 1) {
      GTM.trackCheckoutAllOrders(cart);
      setSubmitted(true);
      if (firstOrder.isValid()) {
        return submitAllCheckoutOrders(orderToSubmit.orderId);
      }
      scrollToTop();
      return;
    }
    GTM.trackCheckoutOrder(orderToSubmit);
    return onSubmit(orderToSubmit);
  };

  const onSubmitAllCheckoutOrders = () => {
    GTM.trackCheckoutAllOrders(cart);
    submitAllCheckoutOrders(checkoutOrders[0].orderId);
  };

  useEffect(() => {
    if (screenHD || screenFullHD) {
      setOpenRightPanel(true);
    }
    if (hideRightPanel) {
      setOpenRightPanel(false);
    }
  }, [screenHD, screenFullHD, hideRightPanel]);

  const contentCheckout = (
    <Grid container alignContent={'flex-start'} className={classes.checkoutMain}>
      <Grid item container>
        <TitleH1 className={classes.title}>Оформлення доставки</TitleH1>
      </Grid>
      <Grid item container xs alignItems={'center'} justifyContent={'space-between'}>
        <Grid item xs={12} sm={5} md={9}>
          <TextSubTitle className={classes.warehouse}>{warehouseName}</TextSubTitle>
        </Grid>
        <Grid item container xs={12} sm={5} md={3} alignItems={'center'} className={classes.delivery}>
          <Icon type={'delivery'} opacity={1} />
          &nbsp;
          <TextBody1>
            {orderDate || (
              <>
                {upperFirst(deliveryOptions[0].format('dd DD.MM'))}
                {preOrder && (
                  <>
                    {' - '}
                    {upperFirst(deliveryOptions[1].format('dd DD.MM'))}
                  </>
                )}
              </>
            )}
          </TextBody1>
        </Grid>
      </Grid>
      {(checkoutLoadingState || outletsLoadingState || cartLoadingState) && !checkoutLoaded ? (
        <FullHeightSkeleton />
      ) : (
        <>
          <CheckoutMain
            anchor={anchor}
            checkoutGroup={checkoutGroup}
            locale={locale}
            cart={cart}
            orders={checkoutOrders}
            finalizedOrders={checkoutFinalizedOrders}
            outlets={outlets}
            updateOrders={updateCheckoutOrders}
            submitOrder={checkoutOrderOnSubmit}
            cancelCheckout={() => cancelOrders()}
            selectedOrderItem={activeOrderItem}
            onSelectOrderItem={setActiveOrderItem}
            onUnselectOrderItem={() => unsetActiveOrderItem()}
            submitted={submitted}
            submitting={checkoutFinalizingOrders}
          />

          <Grid item xs={12} className={`${anchor}-item`}>
            {checkoutOrders.length > 0 && hasMultiOrders && (
              <Hidden xlUp smDown>
                <Grid container item alignItems="center" justifyContent={'space-evenly'} style={{ marginTop: 50 }}>
                  <Button color={'primary'} onClick={() => cancelOrders()} style={{ paddingLeft: 0 }}>
                    {messages.cancelBtnLabel.defaultMessage}
                  </Button>
                  <Button
                    disabled={checkoutFinalizingOrders}
                    color={'primary'}
                    variant={'contained'}
                    onClick={onSubmitAllCheckoutOrders}
                  >
                    {isPreOrder
                      ? messages.submitPreOrderAllBtnLabel.defaultMessage
                      : messages.submitAllBtnLabel.defaultMessage}
                  </Button>
                </Grid>
              </Hidden>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <Grid className={classes.mobileRoot}>
      <Hidden smUp>
        <AsideDeviceTopPanel
          classes={{
            root: classes.checkoutHeader
          }}
          title={messages.mobileTitle.defaultMessage}
          onClose={handleMobileClose}
        />
        <div>
          {breadCrumbs}
          {contentCheckout}
        </div>

        {isCheckoutPage && <HoldCart cart={cart} />}

        <AsideDeviceBottomPanel
          classes={{
            root: classes.checkoutFooter
          }}
          info={
            <div>
              {firstOrder && (
                <span className={classes.mobileFooterContent}>
                  <span className={classes.mobileTotalLabel}>{messages.mobileTotal.defaultMessage}</span>
                  <SplitedPrice
                    value={firstOrder.totalSumAll()}
                    prefix={isFirstOrderPreOrder ? '~' : undefined}
                    fontSize={'subtitle1'}
                    fontSizeDecimal={'subtitle1'}
                    postfix={UAH}
                  />
                </span>
              )}
            </div>
          }
          action={
            firstOrder ? (
              <Button
                disabled={
                  firstOrder.processing ||
                  !(firstOrder.outlet && firstOrder.outlet.id) ||
                  (submitted && !firstOrder.isValid()) ||
                  checkoutFinalizingOrders
                }
                color={'primary'}
                variant={'contained'}
                onClick={() => {
                  return checkoutOrderOnSubmit(firstOrder);
                }}
                className={classes.checkoutOrderFooterBlock}
                startIcon={firstOrder.processing && <FlrLoader size={16} />}
              >
                {messages.submitPreOrderBtnLabel.defaultMessage}
              </Button>
            ) : null
          }
        />
      </Hidden>

      <Hidden smDown>
        <SidePanelsLayout
          mirrored
          fullScreen={false}
          topPanel={() => breadCrumbs}
          leftPanel={() => null}
          leftPanelToggleable={screenFullHD}
          leftPanelOpened={screenFullHD}
          // rightPanelOpened={screenHD || screenFullHD}
          rightPanelOpened={openRightPanel}
          rightPanelToggleable={openRightPanel}
          rightPanel={() =>
            checkoutLoadingState && !checkoutLoaded ? (
              <FullHeightSkeleton skeletonHeight={100} withPadding />
            ) : hasMultiOrders && (openRightPanel) ? (
              <CheckoutRightSide
                preOrder={isPreOrder}
                anchor={anchor}
                anchorOffset={offset.current}
                orders={checkoutOrders}
                finalizedOrders={checkoutFinalizedOrders}
                onCancel={() => cancelOrders()}
                onSubmitAll={submitAllCheckoutOrders}
                submitting={checkoutFinalizingOrders}
                setActiveElement={(index: number) => setActiveOrderIndex({ index })}
              />
            ) : null
          }
          anchorIndex={activeOrder.index}
          trackAnchor={anchor}
          setAnchorIndex={(index: number) => setActiveOrderIndex({ index })}
        >
          {contentCheckout}
        </SidePanelsLayout>
      </Hidden>
    </Grid>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  locale: state.locale,
  cart: getCart(state),
  cartLoadingState: getCartLoadingState(state),
  cartLoaded: getCartLoaded(state),
  checkoutOrders: getCheckoutOrders(state),
  checkoutFinalizedOrders: getCheckoutFinalizedOrders(state),
  checkoutFinalizingOrders: getCheckoutFinalizingOrders(state),
  checkoutLoadingState: getCheckoutLoadingState(state),
  checkoutLoaded: getCheckoutLoaded(state),
  outlets: getOutletsData(state),
  outletsLoadingState: getOutletsLoadingState(state),
  activeOrder: getCheckoutActiveOrder(state),
  activeOrderItem: getCheckoutSelectedOrderItem(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      fetchCheckoutOrders: fetchCheckoutAsync.request,
      updateCheckoutOrders: updateOrdersAsync.request,
      submitCheckoutOrder: finalizeOrderAsync.request,
      submitAllCheckoutOrders: finalizeAllOrdersAsync.request,
      cancelOrders: cancelCheckout,
      setActiveOrderIndex: setActiveOrder,
      setActiveOrderItem: selectOrderItem,
      unsetActiveOrderItem: unselectOrderItem
      // makeOffer: addCartItemAsync.request,
      // selectProduct: (product: Product) => selectProductAsync.request(product),
      // openMiniCart
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(UnconnectedCheckout as any));
