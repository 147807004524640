import React from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";

import { OrderItem } from "models";

import { SplitedPrice } from "components/prices";
import { TextBody2, TextSubTitle } from "components/shared/text";
import { getProductImage } from "utils/helpers";

import withStyles from "./styles";

interface IProps {
  selected?: boolean;
  removeDisabled?: boolean;
  item: OrderItem;
  onRemove?: () => void;
  onSelect: () => void;
  onUnselect: () => void;
  classes?: any;
}

const OrderCardItemLabelDevice: React.FC<IProps> = ({
  selected,
  removeDisabled,
  item,
  onRemove,
  onSelect,
  onUnselect,
  classes
}) => {
  /*const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onRemove) {
      onRemove();
    }
    e.stopPropagation();
  };*/

  const handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (selected && onUnselect) {
      return onUnselect();
    }
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <Grid container spacing={1} direction={"column"} style={{ marginTop: 8, marginBottom: 8 }} onClick={handleSelect}>
      <Grid item container spacing={1}>
        <Grid item xs="auto" className={clsx(classes.productImageCell, "productImageCell")}>
          <img src={getProductImage(item.product)} alt={""} className={classes.productImage} />
        </Grid>
        <Grid item xs container direction={"column"} justifyContent={"space-evenly"} spacing={2}>
          <Grid item>
            <TextSubTitle>{item.product.displayName}</TextSubTitle>
          </Grid>
          <Grid item>
            <TextBody2 color={"textSecondary"}>
              <span className={classes.productColor}>{item.product.getAttribute("color").value}</span>
              <br />
              <SplitedPrice fontColor={"inherit"} value={item.qty} fontSize={"body2"} hideDecimal postfix={"шт"} />
            </TextBody2>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(OrderCardItemLabelDevice);
