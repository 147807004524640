import styled from '@emotion/styled/macro';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { IThemed, ITheme } from 'utils/styled';

export default withStyles((theme: ITheme) => ({
  gray: {
    color: theme.palette.text.secondary
  },
  cartGroupCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    '&:last-of-type': {
      marginBottom: theme.spacing(7)
    }
  },
  expired: {
    background: 'transparent'
  },
  summary: {
    height: 'auto',
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    },
    '& .MuiIconButton-root.MuiIconButton-edgeEnd .flr-icon': {
      width: 12,
      height: 12
    }
  },
  productImage: {
    height: '100%',
    width: 40,
    objectFit: 'cover',
    borderRadius: 4
  },
  productColor: {
    marginBottom: 2,
    display: 'inline-block'
  },
  paramLabel: {
    color: theme.palette.text.primary.main
  },
  paramValue: {
    color: theme.palette.text.secondary.main,
    fontSize: theme.fontSizes.medium
  },

  cartContainer: {
    padding: theme.padding.container
  },
  cartCard: {
    marginBottom: theme.spacing(3)
  },
  cartCardItem: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
    position: 'relative',
    transition: 'padding 0.5s ease-in-out'
  },
  cartCardSummary: {
    marginTop: theme.spacing(2)
  },
  accentBlack: {
    color: theme.palette.common.black
  },
  cartCardItemEditBtn: {
    transition: 'all 0.5s ease-in-out',
    position: 'absolute',
    right: theme.spacing(-4),
    width: 0,
    top: 0,
    bottom: 0
  },
  cartCardItemAnchor: {
    display: 'block',
    overflow: 'hidden',
    paddingTop: 2,
    paddingBottom: 2,
    [(theme as any).breakpoints.up('sm')]: {
      borderTop: `1px solid ${theme.palette.divider}`
    },
    '&.active + a': {
      borderTopColor: 'transparent'
    },
    '&.active': {
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      width: `calc(100% + calc(${theme.spacing(3)} * 2))`,
      background: theme.palette.common.white,
      boxShadow: theme.shadow.cardSelected.boxShadow,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      padding: `0px calc(${theme.spacing(2.5)} + 2px) !important`,
      '&.last': {
        marginBottom: 0
      },
      [(theme as any).breakpoints.down('sm')]: {
        width: `calc(100% + calc(${theme.spacing(3)} * 2))`,
        margin: `0 -${theme.spacing(3)}`,
        padding: `${theme.spacing(2)} calc(${theme.spacing(2.5)} + 2px) !important`
      },
      '& .cart-overall': {
        marginTop: 0
      },
      '& .attributeItem': {
        '&:first-child': {
          clear: 'none',
          width: 'auto'
        },
        '&:nth-child(2)': {
          paddingLeft: theme.spacing(1),
          '&::before': {
            display: 'block'
          }
        }
      }
    }
  },
  buttons: {
    marginTop: theme.spacing(2)
  }
}));

export const Wrapper = styled<any>(Grid)(({ theme }: IThemed) => ({
  flex: '0.2',
  borderLeft: `1px solid ${theme.colors.borderGray}`
}));
