import { defineMessages } from 'react-intl';

export default defineMessages({
  mobileModalTitle: {
    id: 'catalog.sort.mobileModalTitle',
    description: 'catalog.sort.mobileModalTitle',
    defaultMessage: 'Сортування'
  },
  name_asc: {
    id: 'catalog.sorting.label.name_asc',
    description: 'catalog.sorting.label.name_asc',
    defaultMessage: 'За назвою товарів (А → Я)'
  },
  name_desc: {
    id: 'catalog.sorting.label.name_desc',
    description: 'catalog.sorting.label.name_desc',
    defaultMessage: 'За назвою товарів (Я → А)'
  },
  price_asc: {
    id: 'catalog.sorting.label.byPrice_asc',
    description: 'catalog.sorting.label.byPrice_asc',
    defaultMessage: 'Від дешевих до дорогих'
  },
  price_desc: {
    id: 'catalog.sorting.label.byPrice_desc',
    description: 'catalog.sorting.label.byPrice_desc',
    defaultMessage: 'Від дорогих до дешевих'
  },
  height_asc: {
    id: 'catalog.sorting.label.byHeight_asc',
    description: 'catalog.sorting.label.byHeight_asc',
    defaultMessage: 'За висотою (спочатку нищі)'
  },
  height_desc: {
    id: 'catalog.sorting.label.byHeight_desc',
    description: 'catalog.sorting.label.byHeight_desc',
    defaultMessage: 'За висотою (спочатку вищі)'
  },
  popularityRate: {
    id: 'catalog.sorting.label.byPopularityRate',
    description: 'catalog.sorting.label.byPopularityRate',
    defaultMessage: 'За популярністю'
  }

});
