import React from 'react';
import { Box, Button, Container, Grid, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';

import { Order } from 'models';
import { TitleH1, TextBody1, TextBody2 } from 'components/shared/text';
import { GridDivider } from 'components/shared/table/dividers';
import { SplitedPrice } from 'components/prices';
import CheckoutRightSideItem from './CheckoutRightSideItem';

import styles from '../styles';
import messages from 'translations/checkout/common';
import { UAH } from 'shared/constants';
import { useSelector } from 'react-redux';
import { getCheckoutActiveOrder } from '../../../store/checkout/selectors';
import OrderComment from 'components/cards/order/OrderComment';

interface IProps {
  orders: Order[];
  finalizedOrders: Order[];
  onCancel: () => void;
  onSubmitAll: (payload: string) => void;
  setActiveElement?: (index: number) => void;
  anchor: string;
  submitting: boolean;
  anchorOffset?: number;
  preOrder?: boolean;
  classes: any;
}

const CheckoutRightSide: React.FC<IProps> = props => {
  const {
    orders,
    preOrder,
    finalizedOrders,
    onCancel,
    onSubmitAll,
    submitting,
    anchor,
    anchorOffset,
    classes,
    setActiveElement
  } = props;
  const selectedOrder = useSelector(getCheckoutActiveOrder);
  const order = orders[selectedOrder.index - 1];
  const totalQty = orders.reduce((t, o: Order) => t + o.totalQty, 0);
  const totalSum = orders.reduce((t, o: Order) => t + o.totalSum, 0);
  const indexShift = finalizedOrders.length + 1;

  const handleSubmitAll = () => {
    if (orders && orders.length) {
      onSubmitAll(orders[0].orderId);
    }
  };

  return (
    <Container className={classes.checkoutRightPanel}>
      <TitleH1 align={'center'}>Розділені доставки</TitleH1>
      <Paper elevation={0} className={classes.checkoutOrdersSummaryCard}>
        <Grid container spacing={2} direction={'column'}>
          <Grid container item>
            <Grid item container>
              <Grid item sm>
                <TextBody2 className={classes.gray}>Доставка</TextBody2>
              </Grid>
              <Grid item sm>
                <TextBody2 className={classes.gray} align={'right'}>
                  Сума
                </TextBody2>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={0} direction={'column'}>
            {finalizedOrders.map((o: Order, index: number) => (
              <CheckoutRightSideItem
                key={`rb-${o.orderId}`}
                allOrdersCount={orders.length + finalizedOrders.length}
                order={o}
                anchor={anchor}
                anchorOffset={anchorOffset}
                index={index + 1}
              />
            ))}
            {orders.map((o: Order, index: number) => (
              <CheckoutRightSideItem
                key={`rb-${o.orderId}`}
                allOrdersCount={orders.length + finalizedOrders.length}
                order={o}
                anchor={anchor}
                anchorOffset={anchorOffset}
                index={index + indexShift}
                setActiveElement={setActiveElement}
              />
            ))}
            <GridDivider />
            <Grid item container>
              <Grid item container className={classes.rightSideItem}>
                <Grid item container>
                  <Grid item sm>
                    &nbsp;
                  </Grid>
                  <Grid item>
                    <SplitedPrice value={totalQty} bold={false} fontSize={'body2'} hideDecimal={true} postfix={'шт'} />
                  </Grid>
                </Grid>
                <Grid item container justifyContent={'flex-end'} alignItems={'flex-end'}>
                  <Grid item sm>
                    <TextBody2>Сума до сплати</TextBody2>
                  </Grid>
                  <Grid item className={classes.black}>
                    <SplitedPrice value={totalSum} fontSize={'h3'} fontSizeDecimal={'h5'} postfix={UAH} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <GridDivider />
          </Grid>
          {order && (
            <Grid container item>
              <Box>
                <TextBody1 className={classes.commentTitle} style={{ paddingBottom: 3 }}>
                  Коментар до замовлення
                </TextBody1>
              </Box>
              <OrderComment index={selectedOrder.index - 1} />
            </Grid>
          )}
          <Grid container item alignItems="center" justifyContent={'space-between'}>
            <Button color={'primary'} onClick={onCancel} style={{ paddingLeft: 0 }}>
              {messages.cancelBtnLabel.defaultMessage}
            </Button>
            <Button disabled={submitting} color={'primary'} variant={'contained'} onClick={handleSubmitAll}>
              {preOrder ? messages.submitPreOrderAllBtnLabel.defaultMessage : messages.submitAllBtnLabel.defaultMessage}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default withStyles<any>(styles)(CheckoutRightSide);
