import React from 'react';
import clsx from 'clsx';
import { CardActions, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { Element as ScrollBlock } from 'react-scroll';

import { Cart, CartItem, Order, ORDER_TYPE_PREORDER, OrderItem, IDeliveryTime } from 'models';
import { ORDER_STATUS_NEW } from 'models/order';

import { TitleH2, TextCaption } from 'components/shared/text';
import FlrCard from 'components/shared/card/FlrCard';
import Alert from 'components/shared/Alert/Alert';
import FlrLoader from 'components/loading/LoadingSpinner';

import OrderCardDelivery from './OrderCardDelivery';
import OrderCardItemHeaders from './OrderCardItemHeaders';
import OrderCardItem from './OrderCardItem';
import OrderCardSubtotal from './OrderCardSubtotal';

import styles from './styles';
import messages from 'translations/checkout/common';
import OrderComment from './OrderComment';

export interface IFormDeliveryState {
  deliveryDate: Date | null;
  deliveryTime: IDeliveryTime;
}

interface IProps {
  checkoutGroup: string;
  cart: Cart;
  order: Order;
  orderIndex: number;
  orderIndexShift: number;
  hasNext: boolean;
  anchor: string;
  onQtyUpdate: (item: OrderItem, newQty: number) => void;
  onDeliveryUpdate: (item: Order) => void;
  onRemoveItem: (item: OrderItem) => void;
  onChangeItemAmount: (item: OrderItem) => void;
  onCancel: () => void;
  onSubmit: (item: Order) => void;
  classes: any;
  submitted: boolean;
  submitting: boolean;
}

const OrderCard: React.FC<IProps> = ({
  checkoutGroup,
  cart,
  order,
  orderIndex,
  orderIndexShift,
  hasNext = false,
  anchor,
  classes,
  onRemoveItem,
  onChangeItemAmount,
  onSubmit,
  onCancel,
  onQtyUpdate,
  onDeliveryUpdate,
  submitted,
  submitting
}) => {
  const isPreOrder = order.orderType === ORDER_TYPE_PREORDER;
  const isNewOrder = order.orderStatus === ORDER_STATUS_NEW;
  const hasDiscount = order.totalDiscount > 0;
  const disableRemove = order.items.length === 1; // order.totalQty > 1 && order.items.length > 1);
  const getGroupItemQty = (offerId: string) => {
    const group = cart.groups[checkoutGroup];
    if (group) {
      const item = group.items.find((cartItem: CartItem) => cartItem.offer.id === offerId);
      if (item) {
        return item.qty;
      }
    }
    return 0;
  };

  return (
    <ScrollBlock
      name={`${anchor}-${order.id}`}
      className={clsx(`${anchor}-item`, classes.orderCard, classes.orderCardActual)}
    >
      <FlrCard id={order.id}>
        <Grid item>
          {orderIndex && orderIndex >= 0 && (
            <TextCaption className={classes.gray}>
              {messages.delivery.defaultMessage} &ndash; {orderIndex + orderIndexShift}
            </TextCaption>
          )}
          <TitleH2 className={classes.noMarginTop}>{order.outlet && order.outlet.name}&nbsp;</TitleH2>
        </Grid>
        <OrderCardDelivery order={order} onDeliveryChange={onDeliveryUpdate} submitted={submitted} />
        {!hasNext && order.totalQty > 1 && (
          <Alert
            closable
            type={'info'}
            title={messages.alertSplitOrder.defaultMessage}
            className={classes.outlineMargin}
          >
            {messages.alertSplitOrderText.defaultMessage}
          </Alert>
        )}
        <Grid container item direction={'column'} spacing={2}>
          <OrderCardItemHeaders
            itemsNumber={order.items && order.items.length}
            withItemRemove={isNewOrder}
            withDiscount={hasDiscount}
          />
          {order.items &&
            order.items.map((item: OrderItem, index: number) => (
              <OrderCardItem
                isFirst={index === 0}
                max={getGroupItemQty(item.offerId)}
                key={item.id}
                item={item}
                removeDisabled={disableRemove}
                withItemRemove={isNewOrder}
                withDiscount={hasDiscount}
                onChangeAmount={(qty: number) => onQtyUpdate(item, qty)}
                onRemove={() => onRemoveItem(item)}
                isNotEditable={item.special}
              />
            ))}
          <OrderCardSubtotal order={order} withItemRemove={isNewOrder} withDiscount={hasDiscount} withComment={false} />
          <div style={{ marginTop: 10 }}>
            <OrderComment index={orderIndex - 1} />
          </div>
          {isNewOrder && (
            <Grid item className={classes.orderCardActions}>
              <CardActions disableSpacing={true} className={classes.cartAction}>
                {!hasNext && (
                  <Button color={'primary'} onClick={onCancel}>
                    {orderIndex > 1
                      ? messages.mergeBackBtnLabel.defaultMessage
                      : messages.cancelBtnLabel.defaultMessage}
                  </Button>
                )}
                <Button
                  disabled={
                    order.processing ||
                    !(order.outlet && order.outlet.id) ||
                    (submitted && !order.isValid()) ||
                    submitting
                  }
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => onSubmit(order)}
                  className={classes.checkoutOrderFooterBlock}
                  startIcon={order.processing && <FlrLoader size={16} />}
                >
                  {isPreOrder ? messages.submitPreOrderBtnLabel.defaultMessage : messages.submitBtnLabel.defaultMessage}
                </Button>
              </CardActions>
            </Grid>
          )}
        </Grid>
      </FlrCard>
    </ScrollBlock>
  );
};

export default withStyles<any>(styles)(OrderCard);
