import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { Grid } from '@mui/material';

import { User } from 'models';

import { baseUrl } from 'shared/constants';
import messages from 'translations/account/dashboard';
import { TitleH1, TextSubTitle } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import FlrCard from 'components/shared/card/FlrCard';
import DashboardDeliverySkeleton from './DashboardDeliverySkeleton';

// todo
import { pathsOrders } from '../orders/AccountOrdersTable';

interface IProps {
  // component own props
  classes: any;
  account: User | null;
  accountLoadingState: boolean;
}

interface IStateProps {
  // Props passed to the component by `connect`
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const ordersMeta = {};
pathsOrders.forEach(item => {
  ordersMeta[item.statuses[0]] = item;
});

const DashboardDelivery: React.FC<IComponentProps> = ({ classes, account, accountLoadingState }) => {
  const ordersSummaryKeys = account && account.profile.ordersSummary ? Object.keys(account.profile.ordersSummary) : [];
  const ordersSummary = ordersSummaryKeys.length && account ? account.profile.ordersSummary : {};

  return (
    <Grid container item spacing={2} className={classes.deliveryContainer}>
      <div className={clsx(classes.title, classes.titleDelivery)}>
        <Icon className={classes.titleIcon} type={'delivery'} opacity={1} />

        <TitleH1 className={classes.titleCommon}>{messages.titleDelivery.defaultMessage}</TitleH1>
      </div>

      {Boolean(ordersSummaryKeys.length) ? (
        ordersSummaryKeys.map(item => {
          const orderMeta = ordersMeta[item];
          const orderCount = ordersSummary[item];

          // hotfix maybe only for localhost server
          if (item === 'null' || !orderMeta) {
            return null;
          }

          const itemComponent = (
            <FlrCard hoverShadow={Boolean(orderCount)} zeroPadding className={classes.cardLoyalty}>
              <Grid
                className={classes.cardLoyaltyContent}
                container
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Grid>
                  <TextSubTitle className={classes.cardDeliveryLabel}>
                    <Icon type={orderMeta.icon} size={24} className={classes.cardDeliveryIcon} offset={8} />
                    {orderMeta.label}
                  </TextSubTitle>
                </Grid>
                <Grid>
                  <TitleH1 component={'span'}>{orderCount}</TitleH1>
                </Grid>
              </Grid>
            </FlrCard>
          );

          return (
            <Grid key={item} item md={6} xs={12}>
              {Boolean(orderCount) ? (
                <NavLink className={classes.CardLink} to={`${baseUrl}${orderMeta.path}`}>
                  {itemComponent}
                </NavLink>
              ) : (
                itemComponent
              )}
            </Grid>
          );
        })
      ) : (
        <DashboardDeliverySkeleton />
      )}
    </Grid>
  );
};

export default DashboardDelivery;
