import React from 'react';
import clsx from 'clsx';
import { connect, MapStateToProps } from 'react-redux';
import { withStyles, useTheme } from '@mui/styles';
import { Button, Grid, Theme, useMediaQuery } from '@mui/material';

import { User } from 'models';
import { IApplicationState } from 'store/reducers';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';

import { SplitedPrice } from 'components/prices';
import { TextBody2, TextSubTitle } from 'components/shared/text';
import Icon from 'components/shared/Icon';

import styles from './styles';
import PaymentModal from 'components/shared/modals/payment/PaymentModal';
import FlrCard from 'components/shared/card/FlrCard';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import DataCardSkeleton from 'pages/account/finance/CardSkeleton';

import messages from 'translations/account/finance';
import { UAH } from 'shared/constants';

export const DEFAULT_AMOUNT = 10000;

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  buttonVariant?: 'text' | 'outlined' | 'contained';
  fullHeight?: boolean;
  classes: any;
}

interface IStateProps {
  account: User | null;
  accountLoadingState: boolean;
}

type IComponentProps = IProps & IStateProps;

const BalanceCard: React.FC<IComponentProps> = ({
  buttonVariant,
  fullHeight = true,
  account,
  accountLoadingState,
  classes,
  ...rest
}) => {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalPayOpen, setModalPayOpen] = React.useState(false);

  const handlePayCancel = () => {
    setModalPayOpen(false);
  };
  const handleOpenPayModal = () => {
    setModalPayOpen(true);
  };

  const creditUsed = account && account.profile.balance < 0 ? -1 * account.profile.balance : DEFAULT_AMOUNT;

  return (
    <>
      <FlrCard
        className={clsx(classes.paperCard, { [classes.paperCardFullHeight]: fullHeight }, rest.className)}
        hoverShadow
      >
        <FlrCardTitle isUnderline>
          <TextSubTitle align="left">{messages.balance.defaultMessage}</TextSubTitle>
          <Button color="primary" variant={buttonVariant || 'contained'} size="small" onClick={handleOpenPayModal}>
            {messages.topUp.defaultMessage}
          </Button>
        </FlrCardTitle>

        {!accountLoadingState && account ? (
          <Grid item sm={12} container>
            <div className={classes.cardContent}>
              <span className={classes.cardIcon}>
                <Icon type="finance" offset={8} />
              </span>

              <div style={{ textAlign: 'left' }}>
                <SplitedPrice
                  value={account.profile.balance}
                  fontSize={isMobile ? 'h2' : 'h1'}
                  fontSizeDecimal={isMobile ? 'h3' : 'h2'}
                  fontColor="black"
                  negative={account.profile.balance < 0}
                  postfix={UAH}
                  priceDisplayStyle={{ fontWeight: 700, letterSpacing: '1px' }}
                />

                <TextBody2 className={classes.cardBalanceBody}>Власні кошти на рахунку</TextBody2>
              </div>
            </div>
          </Grid>
        ) : (
          <DataCardSkeleton />
        )}
      </FlrCard>

      {modalPayOpen && <PaymentModal open={modalPayOpen} handleClose={handlePayCancel} amount={creditUsed} />}
    </>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  account: getUserAccount(state),
  accountLoadingState: getAccountLoadingState(state)
});

export default connect(mapStateToProps)(withStyles<any>(styles)(BalanceCard as any));
