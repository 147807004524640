import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ITheme } from 'utils/styled';

export default makeStyles((theme: ITheme) =>
  createStyles({
    title: {
      width: '100%',
      position: 'relative',
      paddingLeft: 48
    },
    titleIcon: {
      position: 'absolute',
      left: 0,
      top: 0,
      color: theme.palette.common.black
    },
    titleLoyalty: {
      marginTop: 0,
      marginBottom: 0,
      color: theme.palette.common.black
    },
    subTitleLoyalty: {
      marginTop: 0,
      marginBottom: theme.spacing(3)
    },
    cardPaper: {
      [(theme as any).breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2)
      }
    },
    CardLink: {
      textDecoration: 'none',
      '&:hover, &:active': {
        textDecoration: 'none'
      }
    },
    cardLoyalty: {
      position: 'relative',
      // overflow: 'hidden'
    },
    cardLoyaltyContent: {
      padding: theme.spacing(2),
      paddingTop: 12
    },
    cardLoyaltyImage: {
      width: '100%',
      height: 112,
      objectFit: 'cover'
    },
    cardLoyaltyTooltip: {
      position: 'absolute',
      top: 8,
      right: 16
    },
    cardLoyaltyTooltipIcon: {
      backgroundColor: 'rgba(0, 0, 0, 0.44)',
      padding: 7,
      borderRadius: '50%',
      color: theme.palette.common.white
    },
    tooltipText: {
      color: theme.palette.common.white,
      paddingRight: theme.spacing(3)
    },
    tooltipClass: {
      maxWidth: 500,
      minWidth: 380
    },
    percentLevel: {
      color: theme.palette.common.disabledBlack,
    }
  })
);
