import { FC } from 'react';
import { MessageDescriptor } from 'react-intl';
import clsx from 'clsx';
import { DialogTitle } from '@mui/material';
import { withStyles } from '@mui/styles';

import { TitleH1 } from 'components/shared/text';

import styles from './styles';

interface IProps {
  text: MessageDescriptor;
  classes?: any;
  className?: any;
}

const FlrDialogTitle: FC<IProps> = ({ text, classes, className }) => (
  <DialogTitle className={clsx(classes.dialogTitleContainer, className)} id="scroll-dialog-title">
    <TitleH1 className={classes.dialogTitle} align={'center'}>
      {text.defaultMessage}
    </TitleH1>
  </DialogTitle>
);

export default withStyles<any>(styles)(FlrDialogTitle);
