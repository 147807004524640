import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import moment, { Moment } from 'moment';
import { useTheme, withStyles } from '@mui/styles';
import lodashGet from 'lodash/get';

import {
  Order,
  Outlet,
  ORDER_STATUS_NEW,
  ORDER_STATUS_PENDING_APPROVAL,
  ORDER_PACKING_OPTIONS,
  IMethod,
  OrderItem
} from 'models';
import { updateOrderItem } from 'store/checkout/actions';

import { TextHelper, TextBody2, Link3, TextSubTitle } from 'components/shared/text';
import Select from 'components/shared/form-elements/select2/FlrSelect2';
import FlrCheckboxWithLabel from 'components/shared/form-elements/FlrCheckbox';
import FlrDatePicker from 'components/shared/form-elements/FlrDatePicker';
import AddForm from 'components/shared/modals/outlet/OutletModal';

import { prettyDate, methodDescription, packageIdDescription, packageIdLabel } from 'utils/helpers';
import { defaultLocale } from 'shared/constants';
import messages from 'translations/checkout/common';
import styles from './styles';

interface IProps {
  order: Order;
  submitted: boolean;
  onDeliveryChange?: (order: Order) => void;
  classes: any;
}

function additionalDays(
  isPreorder: boolean,
  nowHour: number,
  isSelfPickupSelected: boolean | undefined,
  preOrderExtraDays: number
) {
  if (isPreorder) {
    return preOrderExtraDays || 5;
  }
  // disable today after 16:00 and disable today for selfpickup after 17:00
  if (nowHour > (isSelfPickupSelected ? 17 : 16)) {
    return 1;
  }
  return 0;
}

function getPreOrderExtraDays(items: OrderItem[]): number {
  return Math.max(
    ...((items &&
      items.map((i: OrderItem) => (i.product.preOrderShippingData ? i.product.preOrderShippingData.dayMargin : 0))) ||
      [])
  );
}

const OrderCardDelivery: React.FC<IProps> = ({ order, onDeliveryChange, classes, submitted }) => {
  const dispatch = useDispatch();
  const theme: Theme = useTheme();
  const momentTime = moment();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [addOutletModal, setAddOutletModal] = useState(false);
  const [editOutletIdModal, setEditOutletIdModal] = useState('');
  const isPreorder = order.orderType === 'preorder';
  const preOrderExtraDays = order.orderType === 'preorder' ? getPreOrderExtraDays(order.items) : 0;
  const isTransitOrder = order.orderDetails?.cartGroupId?.includes('transit');
  const nowDay = momentTime.format('DDDD');
  const nowHour = momentTime.hours();
  const presentDay: boolean = momentTime.isAfter(moment(order.deliveryDate || ''));
  const isEditable = order.orderStatus === ORDER_STATUS_NEW && onDeliveryChange !== undefined;
  const warehouse = order.warehouse;
  const shouldHaveDefaultsSet = (check: Order) => !check.packageId;
  const deliveryMethodOptions = order.shippingData.methods;
  const selfPickupMethod = deliveryMethodOptions.find((item: IMethod) => item.alias === 'selfpickup');
  const isSelfPickupSelected = selfPickupMethod && order.deliveryMethod === selfPickupMethod.id;
  const shouldUseUserOutlets = !isSelfPickupSelected;
  const outlets = order.shippingData.outlets
    ? order.shippingData.outlets.filter(({ systemOutlet }) => (isSelfPickupSelected ? systemOutlet : !systemOutlet))
    : [];
  const outlet = order.outlet && outlets.find(({ id }) => !!order.outlet && order.outlet.id === id);
  const isDisabledMethod = (method: IMethod) => method.alias === 'selfpickup' && !warehouse.selfPickUp;
  const isDisabledOutlet = (item: Outlet) => shouldUseUserOutlets && !(item.location && item.location.approved);
  const deliveryDays = (outlet && outlet.shippingData && outlet.shippingData.days) || {};
  const packageOptions = ORDER_PACKING_OPTIONS;
  const outletId = order.outlet && order.outlet.id;
  const dayOfWeekName = (date: Moment | Date) =>
    moment(date)
      .locale('en')
      .format('dddd')
      .toLocaleLowerCase();
  const dayOfWeek = order.deliveryDate && dayOfWeekName(order.deliveryDate);
  const deliveryTimes = (dayOfWeek && deliveryDays[dayOfWeek]) || [];
  // const deliveryDay = moment(order.deliveryDate).format('DDDD');

  const deliveryTimeOptions = deliveryTimes
    .reduce((acc: any[], id: string) => {
      const timeObj = order.shippingData.times[id];
      const timeShift = timeObj.name.split(' - ');

      // * disable time for holidays 2022/2023
      // if (deliveryDay === '365' || deliveryDay === '006') {
      // }
      // if (parseInt(timeShift[0], 10) > 14 || parseInt(timeShift[1], 10) > 14) {
      //   return acc;
      // }

      if (presentDay && timeObj) {
        if (parseInt(timeShift[1], 10) > nowHour + 2) {
          return [...acc, { label: timeObj.name, value: timeObj.id }];
        }
        return acc;
      }
      return [...acc, { label: timeObj.name, value: timeObj.id }];
    }, [])
    .sort((a: any, b: any) => {
      if (b.label < a.label) {
        return 1;
      }
      if (b.label > a.label) {
        return -1;
      }
      return 0;
    });

  const openOutletModal = (id: string) => () => {
    setAddOutletModal(!!id);
    setEditOutletIdModal(id);
  };
  const closeAddOutletModal = () => {
    setAddOutletModal(false);
    setEditOutletIdModal('');
  };

  const minDate = useMemo(
    () => {
      if (isTransitOrder) return moment(order.orderDetails?.transitDate);
      return momentTime.add(additionalDays(isPreorder, nowHour, isSelfPickupSelected, preOrderExtraDays), 'days');
    },
    // eslint-disable-next-line
    [nowHour, isPreorder, isSelfPickupSelected, preOrderExtraDays]
  );

  const maxDate = moment(minDate).add(4, 'days');

  const onDeliveryFieldChange = (evt: any, isChecked?: any) => {
    // TODO prevent request with same data
    if (evt.target) {
      const name = evt.target.name;
      const value = evt.target.value;
      if (name === 'outlet') {
        if (order.outlet && order.outlet.id !== value) {
          order.deliveryDate = null;
          order.deliveryTime = '';
        }

        order.outlet = outlets.find(o => o.id === value) || null;
      } else if (name === 'packageReturn') {
        order.packageReturn = !!isChecked;
      } else if (name === 'deliveryMethod') {
        order[name] = value;
        order.deliveryDate = null;
        order.deliveryTime = '';
        if (
          selfPickupMethod &&
          value === selfPickupMethod.id &&
          order.shippingData.outlets &&
          order.shippingData.outlets.length
        ) {
          order.outlet = order.shippingData.outlets.find(({ systemOutlet }) => systemOutlet) || null;
        }
      } else {
        order[name] = value;
      }

      if (onDeliveryChange && !shouldHaveDefaultsSet(order)) {
        if (name === 'deliveryTime') {
          order.deliveryTime = value;
          dispatch(updateOrderItem(order));
          return;
        }
        onDeliveryChange(order);
      }
    }
  };

  const onDeliveryDateChange = (newDate: Moment | null) => {
    if (!newDate) {
      return;
    }

    const dayData = deliveryDays[dayOfWeekName(newDate)];
    const canThisDayBeSelected = Boolean(dayData);

    if (canThisDayBeSelected) {
      const deliveryDate = newDate.toDate();
      order.deliveryDate = deliveryDate;
      dispatch(updateOrderItem(order));

      // reset time (if new day does not include previous time)
      if (!(dayData && dayData.includes(order.deliveryTime))) {
        order.deliveryTime = '';
      }
    }
  };

  const onAddOutletSuccess = (newOutlet: Outlet) => {
    if (newOutlet && newOutlet.location && newOutlet.location.approved) {
      order.outlet = new Outlet(newOutlet);
    }
    if (onDeliveryChange) {
      onDeliveryChange(order);
    }
  };

  // temp
  // const orderDeliveryMethod = deliveryMethodOptions.find(d => d.code === '000000001');
  // const isDeliveryMethodFloroteka = orderDeliveryMethod?.id === order.deliveryMethod;

  const shouldDisableDate = (day: any) => {
    // * disable holidays for 2022/2023 NY
    const dayOfYear = moment(day).format('DDDD');
    if (
      dayOfYear === '359' || // 25.12.2022
      dayOfYear === '001' || // 01.01.2023
      (dayOfYear === '365' && nowDay === '365' && nowHour >= 12) // 31.12.2022

      // (dayOfYear === '358' && nowDay === '358' && nowHour >= 11) || // 24.12.2022
      // (dayOfYear === '006' && nowDay === '006' && nowHour >= 11) || // 06.01.2023
      // dayOfYear === '007' || // 07.01.2023
      // dayOfYear === '008' || // 08.01.2023
      // dayOfYear === '106' || // 16.04.2023
      // dayOfYear === '107' || // 17.04.2023
      // (isDeliveryMethodFloroteka && order.orderDetails?.supplier === 'dc' && dayOfYear === '055')
    ) {
      return true;
    }

    return !deliveryDays[dayOfWeekName(day)];
  };

  const addOutletOptions = !shouldUseUserOutlets
    ? []
    : [
        {
          label: '+ Додати пункт призначення',
          value: 'add',
          onClick: () => setAddOutletModal(true)
        }
      ];

  const optionsOutlets = useMemo(
    () => [
      ...outlets.map(outletItem => {
        const isDisabled = isDisabledOutlet(outletItem);
        return {
          label: !isDisabled ? outletItem.name : `${outletItem.name} (Немає доставки)`,
          value: outletItem.id,
          isDisabled
        };
      }),
      ...addOutletOptions
    ],
    // eslint-disable-next-line
    [outlets]
  );

  useEffect(() => {
    if (!order.deliveryMethod) {
      const nearestAvailableMethod = deliveryMethodOptions.find(method => !isDisabledMethod(method)) || { id: '' };
      order.deliveryMethod = nearestAvailableMethod.id;
    }
    if (!order.outlet) {
      const defaultOutlet = outlets.find(({ isDefault }) => isDefault);
      order.outlet = defaultOutlet || outlets[0] || null;
    }
    if (isEditable && !outlets.some(o => o.id === outletId)) {
      order.outlet = null;
    }
    if (!order.packageId) {
      order.packageId = packageOptions[0];
    }
    dispatch(updateOrderItem(order));
    // eslint-disable-next-line
  }, []);

  if (isEditable && outletId && outlets.length && !outlets.some(o => o.id === outletId)) {
    order.outlet = null;
    dispatch(updateOrderItem(order));
  }

  return (
    <>
      <Grid
        container
        item
        spacing={3}
        className={classes.delivery}
        style={{ marginBottom: order.orderStatus === ORDER_STATUS_PENDING_APPROVAL ? 0 : 24 }}
      >
        <Grid item container xs={12} sm={6} md={7} lg={2} spacing={1} alignContent={'flex-start'}>
          <Grid item xs={12}>
            {isEditable ? (
              <>
                <Select
                  name="deliveryMethod"
                  fullWidth
                  label={messages.deliveryMethod.defaultMessage}
                  options={deliveryMethodOptions.map(value => ({
                    label: value.name,
                    value: value.id,
                    isDisabled: isDisabledMethod(value)
                  }))}
                  onChange={onDeliveryFieldChange}
                  value={order.deliveryMethod}
                />
                {submitted && !order.deliveryMethod && (
                  <TextHelper color={'error'} className={classes.deliveryMethodValue}>
                    {messages.noDeliveryAddress.defaultMessage}
                  </TextHelper>
                )}
              </>
            ) : (
              <>
                <TextBody2 color={'textSecondary'}>{messages.deliveryMethod.defaultMessage}</TextBody2>
                <TextSubTitle className={classes.deliveryMethodValue}>
                  {lodashGet(order, 'orderDetails.deliveryMethod.name', '-')}
                </TextSubTitle>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {isEditable && <TextHelper>{methodDescription(order.deliveryMethod)}</TextHelper>}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={7} lg={3} spacing={1} alignContent={'flex-start'}>
          <Grid item xs={12}>
            {isEditable ? (
              <Select
                name="outlet"
                fullWidth
                label={messages.deliveryAddress.defaultMessage}
                options={optionsOutlets}
                onChange={onDeliveryFieldChange}
                value={outletId || ''}
                invalid={!outletId}
                isDisabled={!order.deliveryMethod || isSelfPickupSelected}
              />
            ) : (
              <>
                <TextBody2 color={'textSecondary'}>{messages.deliveryAddress.defaultMessage}</TextBody2>
                <TextSubTitle className={classes.noMarginBottom}>
                  {(order.outlet && order.outlet.name) || ''}
                </TextSubTitle>
              </>
            )}
          </Grid>
          <Grid item container xs={12}>
            <Grid item container sm direction={'column'} style={{ display: 'flex' }}>
              {outletId ? (
                <>
                  <TextHelper>{order.outlet && order.outlet.address}</TextHelper>
                  <TextHelper>{order.outlet && order.outlet.phone}</TextHelper>
                </>
              ) : (
                submitted && <TextHelper color={'error'}>{messages.noDeliveryAddress.defaultMessage}</TextHelper>
              )}
              {isEditable && shouldUseUserOutlets && order.outlet && (
                <Link3 onClick={openOutletModal(order.outlet.id)}>Редагувати</Link3>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          sm={6}
          md={5}
          lg
          columnSpacing={3}
          alignContent="flex-start"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Grid item container xs={6} sm={6} spacing={1} alignItems={'flex-start'}>
            <Grid item xs={12}>
              {isEditable ? (
                <>
                  <FlrDatePicker
                    name="deliveryDate"
                    variant={isMobile ? 'dialog' : 'inline'}
                    id={`${order.id}-date-from`}
                    label={messages.date.defaultMessage}
                    value={order.deliveryDate}
                    minDate={minDate}
                    disabled={!outletId}
                    maxDate={isPreorder ? undefined : maxDate}
                    disablePast={true}
                    onChange={onDeliveryDateChange}
                    margin={'none'}
                    className={classes.fullWidth}
                    InputProps={{
                      readOnly: true
                    }}
                    shouldDisableDate={shouldDisableDate}
                  />
                  {submitted && !order.deliveryDate && (
                    <TextHelper color={'error'} className={classes.deliveryMethodValue}>
                      {messages.noDeliveryAddress.defaultMessage}
                    </TextHelper>
                  )}
                </>
              ) : (
                <>
                  <TextBody2 color={'textSecondary'}>{messages.date.defaultMessage}</TextBody2>
                  <TextSubTitle className={classes.noMarginBottom}>
                    {order.deliveryDate &&
                      moment(order.deliveryDate)
                        .locale(defaultLocale)
                        .format('L')}
                  </TextSubTitle>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextHelper>{order.deliveryDate && prettyDate(order.deliveryDate)}</TextHelper>
            </Grid>
          </Grid>
          <Grid item container xs={6} sm={6} spacing={1} alignItems={'flex-start'} style={{ paddingRight: 0 }}>
            <Grid item xs={12} style={{ paddingRight: 0 }}>
              {isEditable ? (
                <>
                  <Select
                    fullWidth
                    name="deliveryTime"
                    label={messages.time.defaultMessage}
                    options={deliveryTimeOptions}
                    onChange={onDeliveryFieldChange}
                    value={order.deliveryTime}
                    isDisabled={!order.deliveryDate && !outletId}
                  />
                  {submitted && !order.deliveryTime && (
                    <TextHelper color={'error'} className={classes.deliveryMethodValue}>
                      {messages.noDeliveryAddress.defaultMessage}
                    </TextHelper>
                  )}
                </>
              ) : (
                <>
                  <TextBody2 color={'textSecondary'}>{messages.time.defaultMessage}</TextBody2>
                  <TextSubTitle className={classes.noMarginBottom}>
                    {order.deliveryTime ? order.deliveryTime.name : ''}
                  </TextSubTitle>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextHelper>{order.deliveryTime ? order.deliveryTime.name : ''}</TextHelper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={5} lg={3} spacing={1} alignContent={'flex-start'}>
          <Grid item xs={12}>
            {isEditable ? (
              <Select
                name="packageId"
                fullWidth
                label={messages.package.defaultMessage}
                options={packageOptions.map(value => ({
                  label: packageIdLabel(value),
                  value,
                  isDisabled: value !== packageOptions[0]
                }))}
                onChange={onDeliveryFieldChange}
                value={packageOptions[0]}
                isDisabled
              />
            ) : (
              <>
                <TextBody2 color={'textSecondary'}>{messages.package.defaultMessage}</TextBody2>
                <TextSubTitle className={classes.noMarginBottom}>{packageIdLabel(packageOptions[0])}</TextSubTitle>
              </>
            )}
          </Grid>
          <Grid item container xs={12} alignItems="flex-start">
            <Grid item container xs={8} sm={9} md={10} direction="column" style={{ display: 'flex' }}>
              {isEditable ? (
                <>
                  <TextHelper>{packageIdDescription(packageOptions[0])}</TextHelper>
                  <FlrCheckboxWithLabel
                    disabled
                    name="packageReturn"
                    value={!!order.packageReturn}
                    label={messages.packageReturn.defaultMessage}
                    onChange={onDeliveryFieldChange}
                  />
                </>
              ) : (
                <TextHelper>
                  {messages[`package${!order.packageReturn ? 'Not' : ''}Returned`].defaultMessage}
                </TextHelper>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AddForm
        toggle={closeAddOutletModal}
        open={!!addOutletModal}
        id={editOutletIdModal}
        onSuccess={onAddOutletSuccess}
        onUpdate={() => setTimeout(() => editOutletIdModal && onDeliveryChange && onDeliveryChange(order), 2300)}
      />
    </>
  );
};

export default withStyles<any>(styles)(OrderCardDelivery);
