import { FC } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { IAttribute, Product } from 'models/product';
import Tooltip from 'components/shared/tooltip';
import Icon from 'components/shared/Icon';
import { SplitedPrice } from 'components/prices';
import cardMessages from 'translations/catalog/details';
import { CardDiscountIcon } from 'components/cards/shared/CardDiscountIcon';
import { ColorCircle } from '../../../shared/product/ColorCircle';
import classes from '../product-row.module.scss';
import { getProductImage } from '../../../../utils/helpers';
import { useSelector } from 'react-redux';
import { createCartItemQtySelector } from '../../../../store/cart/selectors';

interface ICatalogProductRowDeviceProps {
  item: Product;
  onClick?: (product: Product) => void;
  isPriceByPackage?: boolean;
  isSelected?: boolean;
}

export const CatalogProductRowDevice: FC<ICatalogProductRowDeviceProps> = ({
  item,
  onClick,
  isPriceByPackage,
  isSelected
}) => {
  const cartItemQty = useSelector(createCartItemQtySelector(item.id));

  if (!item) {
    return null;
  }

  const productParams: IAttribute[] = [];
  Object.keys(item.attributes).forEach((attributeKey) => {
    const attribute = item.getAttribute(attributeKey);
    if (attribute.value) {
      productParams.push(attribute);
    }
  });

  const urlImages = getProductImage(item);

  const productManufacturer = item.attributes.manufacturer || {};

  const handleClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <div onClick={handleClick} className={classes.rowContainer}>
      {!isSelected && cartItemQty > 0 ? (
        <div className={classes.inCartContainer}>
          <Icon type="shoppingCartOne" opacity={1} size={18} className={classes.iconInCart} />
        </div>
      ) : null}
      <img src={urlImages} alt={item.fullName} className={classes.mainImg} />
      <div className={classes.mainContainer}>
        <>
          {item.fullName.length > 21 ? (
            <Tooltip
              placement="top"
              title={
                <p className="MuiTypography-root MuiTypography-subtitle2 MuiTypography-colorInherit">{item.fullName}</p>
              }
            >
              <span className={classes.overFlowText}>
                <p className={classes.name}>{item.fullName}</p>
              </span>
            </Tooltip>
          ) : (
            <p className={classes.name}>{item.fullName}</p>
          )}
        </>
        <div className={classes.containerAttributes}>
          <div className={classes.packageInfo}>
            <Icon type="package" size={16} offset={6} />
            <span>{item.inPackageCount}</span>
            &nbsp;
            <span>{item.unit}</span>
          </div>
          <ColorCircle color={item.attributes?.color?.value} />
          <div className={classes.attrContainer}>
            {item.attributesList.map((attr) => (
              <div key={attr.code} className={classes.attributeContainer}>
                <Icon size={16} type={attr.alias} offset={2} className={classes.icon} opacity={1} />
                <p className={classes.otherAttributeValue}>{attr.value}</p>
              </div>
            ))}
          </div>
          <span className={clsx(classes.ellipsisText, classes.manufacturerLabel)}>{productManufacturer.value}</span>
        </div>
        <div className={clsx(classes.price, { [classes.preOderWrapper]: item.hasPreOrder })}>
          <div className={classes.countryContainer}>
            <CardDiscountIcon
              classes={{
                popper: classes.tooltipMobileDiscountPopper,
                tooltip: classes.tooltipMobileDiscountTooltip
              }}
              discount={item.discount}
              details={item.discountDetails}
              className={classes.discountBadge}
            >
              <p className={classes.discountText}>-{item.discount}%</p>
            </CardDiscountIcon>
            <div className={classes.iconContainer}>
              {item.specialPrice ? (
                <Icon type="fireBorderedMobile" size={16} className={classes.fireBorderedImg} opacity={1} />
              ) : null}
              {item.hasTransit ? <Icon type="goodsComingBorderedMobile" leftOffset={2} size={17} opacity={1} /> : null}
            </div>
          </div>
          {item.priceRange ? (
            <div className={classes.priceItem}>
              {!item.priceRangeEquals ? <span className={classes.minPriceLabel}>Від</span> : null}
              <SplitedPrice
                fontColor="inherit"
                fontSize="large"
                value={item.minPrice * (isPriceByPackage ? item.inPackageCount : 1)}
                className={classes.priceLabel}
              />
            </div>
          ) : item.hasPreOrder && item.preOrderPrice ? (
            <div className={clsx(classes.priceItem, classes.priceItemPreOrder)}>
              <div>
                <span className={classes.preOrderText}>На {moment(item.preOrderPriceDate).format('DD.MM.YY')}</span>
                <span className={classes.tilde}>~</span>
                <SplitedPrice
                  fontColor="inherit"
                  value={item.preOrderPriceWithDiscount * (isPriceByPackage ? item.inPackageCount : 1)}
                  fontSize="large"
                  className={classes.priceLabel}
                />
              </div>
            </div>
          ) : (
            cardMessages.notInStock.defaultMessage
          )}
        </div>
      </div>
    </div>
  );
};
