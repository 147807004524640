import React from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

import { OrderItem } from 'models';
import { GTM } from 'controllers';
import { UAH } from 'shared/constants';

import Icon from 'components/shared/Icon';
import { ItemPrice, SplitedPrice } from 'components/prices';
import ProductDetails from 'components/cards/shared/ProductDetails';
import FlrQuantity from 'components/shared/form-elements/quantity/FlrQuantity';
import { Link3, TextBody1 } from 'components/shared/text';
import { GridDivider, GridDividerBold } from 'components/shared/table/dividers';
import { getProductImage } from 'utils/helpers';

import styles from './styles';

interface IProps {
  isFirst: boolean;
  removeDisabled: boolean;
  max?: number;
  item: OrderItem;
  onChangeAmount?: (qty: number) => void;
  onRemove?: () => void;
  withDiscount?: boolean;
  withItemRemove?: boolean;
  isNotEditable?: boolean;
  classes: any;
  isPreOrder?: boolean;
}

const OrderCardItem: React.FC<IProps> = ({
  isFirst,
  removeDisabled,
  max,
  item,
  onChangeAmount,
  onRemove,
  withItemRemove,
  withDiscount,
  isNotEditable,
  classes,
  isPreOrder
}) => {
  const removeHandle = () => {
    if (!removeDisabled && onRemove) {
      GTM.trackNullifyItemFromOrderList(item);
      onRemove();
    }
  };

  const isSpecialOrder = item.special;

  return <>
    <Grid item>{isFirst ? <GridDividerBold /> : <GridDivider />}</Grid>
    <Grid item container spacing={2} className={classes.tableItem} alignItems={'center'}>
      <Grid item container sm spacing={1} alignItems={'center'}>
        <Grid item>
          <img src={getProductImage(item.product)} alt={''} height={40} />
        </Grid>
        <Grid item sm>
          <ProductDetails product={item.product} />
        </Grid>
      </Grid>
      <Grid item sm={2} container justifyContent={'flex-end'}>
        {isSpecialOrder ? <Icon opacity={1} type="fire" size={20} style={{ marginRight: 6 }} /> : null}
        <ItemPrice value={item.price} />
      </Grid>
      <Grid item sm={'auto'} style={{ width: 240 }}>
        {isNotEditable ? (
          <TextBody1 align={'center'}>{item.qty}</TextBody1>
        ) : (
          <FlrQuantity
            value={item.qty}
            inPackageCount={item.product.inPackageCount}
            min={item.qty === item.product.inPackageCount ? 0 : item.product.inPackageCount}
            onDecrement={item.qty === item.product.inPackageCount ? () => onRemove && onRemove() : undefined}
            max={max || item.qty}
            onChange={onChangeAmount}
            disableMaxLimit={!isPreOrder}
          />
        )}
      </Grid>
      {!!withItemRemove && (
        <Grid item sm={1} onClick={removeHandle}>
          <Link3 className={clsx(classes.orderItemRemove, removeDisabled ? classes.disabled : null)}>Обнулити</Link3>
        </Grid>
      )}
      {!!withDiscount && (
        <Grid item sm={1} container justifyContent={'flex-end'} className={classes.paramValue}>
          {item.discount > 0 || item.discountAuto > 0 ? item.discount + item.discountAuto : null}
        </Grid>
      )}
      <Grid item sm={'auto'} container justifyContent={'flex-end'} className={classes.orderItemSubtotal}>
        <SplitedPrice
          value={Math.round(item.total * 100) / 100}
          fontColor={'textSecondary'}
          fontSize={'h3'}
          fontSizeDecimal={'h5'}
          postfix={UAH}
        />
      </Grid>
    </Grid>
  </>;
};

export default withStyles<any>(styles)(OrderCardItem);
