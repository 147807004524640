import React from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';

import { OrderItem, Product } from 'models';
import { ExpandPanel } from 'components/shared';
import { GridDivider, GridDividerBold } from 'components/shared/table/dividers';
import FlrCard from 'components/shared/card/FlrCard';
import OrderCardItemDeviceData from './OrderCardItemData';
import OrderCardItemLabel from './OrderCardItemLabel';

import withStyles from './styles';

interface IProps {
  isFirst: boolean;
  removeDisabled: boolean;
  max?: number;
  item: OrderItem;
  onChangeAmount?: (qty: number) => void;
  onRemove?: () => void;
  withDiscount?: boolean;
  withItemRemove?: boolean;
  isNotEditable?: boolean;
  selected?: boolean;
  isLast?: boolean;
  isPreOrder?: boolean;
  onSelect: () => void;
  onUnselect: () => void;
  onMoreInfo: (product: Product) => void;
  classes: any;
}

const OrderCardItemDevice: React.FC<IProps> = ({
  selected,
  isNotEditable,
  isFirst,
  isLast,
  removeDisabled,
  max,
  item,
  onChangeAmount,
  onRemove,
  onSelect,
  onUnselect,
  onMoreInfo,
  withDiscount,
  isPreOrder,
  classes
}) => {
  const handleChange = () => {
    if (selected) {
      onUnselect();
    } else {
      onSelect();
    }
  };

  const innerContent = (
    <React.Fragment>
      <ExpandPanel
        label={
          <OrderCardItemLabel
            item={item}
            onRemove={onRemove}
            selected={selected}
            onSelect={onSelect}
            onUnselect={onUnselect}
            removeDisabled={removeDisabled}
          />
        }
        onChange={handleChange}
        leftIcon
        expanded={selected}
        classNameSummary={classes.summary}
      >
        <OrderCardItemDeviceData
          isNotEditable={isNotEditable}
          isPreOrder={isPreOrder}
          max={max}
          onRemove={onRemove}
          onClose={onUnselect}
          onMoreInfo={onMoreInfo}
          onChangeAmount={onChangeAmount}
          item={item}
          withDiscount={withDiscount}
          withItemRemove={!isNotEditable}
          removeDisabled={removeDisabled}
        />
      </ExpandPanel>
    </React.Fragment>
  );

  return (
    <>
      {!selected && isFirst && (
        <Grid item style={{ opacity: +!isNotEditable }}>
          <GridDividerBold />
        </Grid>
      )}
      <Grid
        item
        container
        spacing={selected ? 0 : 2}
        className={clsx(classes.tableItem, { firstItem: isFirst, [classes.outlineMargin]: selected })}
        alignItems={'center'}
      >
        {selected ? (
          <FlrCard className={clsx(classes.cartCardItemAnchor, 'active')}>
            {/* <Grid container spacing={2} alignItems={"center"}> */}
            {innerContent}
            {/* </Grid> */}
          </FlrCard>
        ) : (
          innerContent
        )}
      </Grid>
      {!selected && isLast && (
        <Grid item className={clsx(classes.tableItem)}>
          {isNotEditable ? <GridDivider /> : <GridDividerBold />}
        </Grid>
      )}
    </>
  );
};

export default withStyles(OrderCardItemDevice);
