import { useEffect, useRef, useState } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';

import PaymentModal from 'components/shared/modals/payment/PaymentModal';
import ProductDetails from 'components/shared/product/product-details/ProductDetails';
import { Cart, DetailedProduct, Offer, Product, User } from 'models';
import { getUserAccount } from 'store/account/selectors';
import { checkTransitOfferAsync } from 'store/cart/actions';
import { findCartItem, getCart } from 'store/cart/selectors';
import { intervalProductDetails } from 'store/catalog/actions';
import { getSelectedProductData, getSelectedProductLoadingState } from 'store/catalog/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/catalog/mini-details';

import { fetchAccountAsync } from '../../../../store/account/actions';
import { AddTransitToOfferModal } from './AddTransitToOfferModal';
import Skeleton from './MiniDetailsSkeleton';
import OffersGroup from './OffersGroup';
import { TransitOfferModal } from './TransitOfferModal';

import classes from './MiniDetails.module.scss';

interface IOwnProps {
  onPurchase?: (qty: number, offerId: string) => void;
  classes?: any;
  noOffers?: boolean;
  mobileProduct?: DetailedProduct | Product | null;
}

interface IStateProps {
  cart?: Cart;
  user: User | null;
  selectedProductLoading: boolean;
  selectedProduct: DetailedProduct | Product | null;
}

interface IDispatchProps {
  checkTransitOffer: any;
  intervalProductDetails: typeof intervalProductDetails;
  loadAccountData: typeof fetchAccountAsync.request;
}

export type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IGroupedOffers {
  [key: string]: {
    label: string;
    offers: Offer[];
    defaultExpanded: boolean;
    hideSwitch?: boolean;
  };
}

function groupOffersByType(offers: Offer[], filterParams: string): IGroupedOffers {
  return offers.reduce(
    (acc, offer) => {
      if (offer.preOrder) {
        acc.preOrder.offers.push(offer);
      } else if (offer.transit || offer.specialTransit) {
        acc.transit.offers.push(offer);
      } else if (offer.inStock > 0 || offer.special) {
        acc.inStock.offers.push(offer);
      }
      return acc;
    },
    {
      inStock: {
        label: messages.inStockFilter.defaultMessage,
        offers: [],
        defaultExpanded: filterParams.includes('inStock=1') || filterParams.includes('specialOffers=1')
      },
      transit: {
        label: messages.transitFilter.defaultMessage,
        offers: [],
        defaultExpanded: filterParams.includes('transitOffers=1') || filterParams.includes('specialTransitOffers=1')
      },
      preOrder: {
        label: messages.preOrderFilter.defaultMessage,
        offers: [],
        defaultExpanded: filterParams.includes('preOrder=1'),
        hideSwitch: true
      }
    } as IGroupedOffers
  );
}

const orderedOfferTypes = ['inStock', 'transit', 'preOrder'];

const sortOffersByPrice = (a: any, b: any) => {
  return +a.price[0] - +b.price[0];
};

const MiniDetails = (props: IProps) => {
  const [selectedProductCode, setSelectedProductCode] = useState<any>(
    (props.selectedProduct && props.selectedProduct.code) || null
  );
  const [modalPayAmount, setModalPayAmount] = useState(0);
  const [transitOffer, setTransitOffer] = useState<Offer | undefined>(undefined);
  const [transitOfferModalState, setTransitOfferModalState] = useState(false);
  const [qtyState, setQtyState] = useState((props.selectedProduct && props.selectedProduct.inPackageCount) || 1);
  const fetchProductDetailsInterval = useRef(undefined);
  const { filter: filterParams } = useParams<{ filter: string; type: string }>();

  const offersByType = groupOffersByType(props.selectedProduct?.offers || [], filterParams);

  useEffect(() => {
    if (fetchProductDetailsInterval) {
      fetchProductDetailsInterval.current = setInterval(props.intervalProductDetails, 30 * 1000) as any;
    }
    return () => {
      clearInterval(fetchProductDetailsInterval.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.selectedProduct && selectedProductCode !== props.selectedProduct.code) {
      setQtyState(props.selectedProduct.inPackageCount);
      setSelectedProductCode((props.selectedProduct && props.selectedProduct.code) || null);
    }
    // eslint-disable-next-line
  }, [props.selectedProduct]);

  const handlePayCancel = () => {
    setModalPayAmount(0);
  };

  const handleOpenPayModal = (amount?: number) => {
    setModalPayAmount(amount || 0);
  };

  const onSuccess = () => {
    if (props.loadAccountData) {
      props.loadAccountData();
    }
  };

  const handleCloseTransitOfferModal = () => {
    setTransitOffer(undefined);
    setTransitOfferModalState(false);
  };

  const handlePurchase = (offer: Offer, qty: number) => {
    const { transit, id: offerId, preOrder } = offer;
    const { onPurchase, cart, checkTransitOffer } = props;
    const currentItemInCart = (cart && findCartItem(cart, offerId)) || { qty: 0 };

    if (transit) {
      checkTransitOffer({ offerId, qty: currentItemInCart.qty + qty });
      setTransitOffer(offer);
      // if transit offer already in cart then open a modal to choose the order
      // otherwise add this offer to the cart
    } else if (onPurchase) {
      const transitOffer = props.selectedProduct?.offers?.find((item) => item.transit);
      if (preOrder && !!transitOffer) {
        setTransitOffer(transitOffer);
        setTransitOfferModalState(true);
      } else {
        onPurchase(currentItemInCart.qty + qty, offerId);
      }
    }
  };

  const handlePreOrderSubmit = () => {
    if (props.onPurchase) {
      const preOrderOffer = props.selectedProduct?.offers?.find((item) => item.preOrder);
      if (preOrderOffer && preOrderOffer.id) {
        const currentItemInCart = (props.cart && findCartItem(props.cart, preOrderOffer.id)) || { qty: 0 };
        props.onPurchase(currentItemInCart.qty + qtyState, preOrderOffer.id);
      }
    }
  };

  const handleTransitSubmit = ({ id: offerId }: Offer) => {
    const currentItemInCart = (cart && findCartItem(cart, offerId)) || { qty: 0 };
    props.checkTransitOffer({ offerId, qty: currentItemInCart.qty + qtyState });
    setTransitOfferModalState(false);
  };

  const { selectedProduct, cart, noOffers, selectedProductLoading } = props;

  if (selectedProductLoading) {
    return <Skeleton />;
  }

  return (
    <section className={clsx(classes.miniDetailsWrapper, { noOffers })}>
      <div className={classes.card}>
        <ProductDetails item={selectedProduct} />
      </div>

      <div className={classes.offerContainer}>
        {orderedOfferTypes.map((offerType) => {
          const { label, offers, defaultExpanded, hideSwitch } = offersByType[offerType] || { label: '', offers: [] };
          return (
            <OffersGroup
              key={offerType}
              offerType={offerType}
              offers={offers.sort(sortOffersByPrice)}
              handlePurchase={handlePurchase}
              selectedProduct={props?.selectedProduct}
              label={label}
              defaultExpanded={defaultExpanded}
              hideSwitch={hideSwitch}
            />
          );
        })}
      </div>

      <TransitOfferModal
        transitOffer={transitOffer}
        state={transitOfferModalState}
        product={selectedProduct}
        handleClose={handleCloseTransitOfferModal}
        handleTransitSubmit={handleTransitSubmit}
        handlePreorderSubmit={handlePreOrderSubmit}
      />

      <AddTransitToOfferModal
        transitOffer={transitOffer}
        product={selectedProduct}
        preselectedCount={qtyState}
        handleOpenPayModal={handleOpenPayModal}
      />

      {!!modalPayAmount && (
        <PaymentModal
          open={!!modalPayAmount}
          handleClose={handlePayCancel}
          amount={modalPayAmount}
          onSuccess={onSuccess}
        />
      )}
    </section>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  selectedProductLoading: getSelectedProductLoadingState(state),
  selectedProduct: getSelectedProductData(state),
  user: getUserAccount(state),
  cart: getCart(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      intervalProductDetails,
      checkTransitOffer: checkTransitOfferAsync.request,
      loadAccountData: fetchAccountAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniDetails as any);
