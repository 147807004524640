import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { connect, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, Box, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';

import messages from 'translations/account/finance';
import { User } from 'models';

import { IApplicationState } from 'store/reducers';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { useTrackInitialPageLoad } from 'controllers';

import DataCardSkeleton from './CardSkeleton';

import styles from './styles';
import BalanceCard from 'components/cards/shared/BalanceCard';
import CardCreditLine from 'components/cards/account/CardCreditLine';
import CardDepositDiscount from './CardDepositDiscount';
import TransactionsTable from './Table';
import Icon from 'components/shared/Icon';
import { TitleH1, TextSubTitle } from 'components/shared/text';
import FlrCard from 'components/shared/card/FlrCard';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import { baseUrl } from 'shared/constants';

interface IProps {
  classes: any;
  account: User;
  accountLoadingState: boolean;
}

const AccountFinance: React.FC<IProps> = ({ account, accountLoadingState, classes }) => {
  useTrackInitialPageLoad();

  return (
    <div className={'actual-content'}>
      <Box className={classes.topBar}>
        <TitleH1>{messages.title.defaultMessage}</TitleH1>
      </Box>
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12} sm={4}>
          <BalanceCard />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardCreditLine />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FlrCard className={clsx(classes.paperCard, classes.paperCardFullHeight)} hoverShadow>
            <FlrCardTitle isUnderline>
              <TextSubTitle align={'left'}>{messages.depositDiscount.defaultMessage}</TextSubTitle>
              <IconButton
                size={'small'}
                component={NavLink}
                to={`${baseUrl}/account/loyalty`}
                className={classes.buttonPadding}
              >
                <Icon type="btnArrow" opacity="1" size={24} />
              </IconButton>
            </FlrCardTitle>
            {!accountLoadingState && account ? (
              <CardDepositDiscount classes={classes} account={account} />
            ) : (
              <DataCardSkeleton />
            )}
          </FlrCard>
        </Grid>
      </Grid>
      {/*
        <Alert
          closable
          title={"Заголовок"}
          action={
            <span style={{ display: "flex" }}>
              <span style={{ marginRight: 8 }}>57 : 45</span> <Icon type={"timer"} offset={8} size={24} />
            </span>
          }
          withTimer
        >
          <span>
            У вас є передзамовлення, з товаром, що їде на склад, на суму 6 100 ₴. Автоматична оплата відбудеться через
            21 06 хв. Впевніться, що у вас поповнений баланс!
          </span>
        </Alert>
        */}
      <TransactionsTable />
    </div>
  );
};

const mapStateToProps: MapStateToProps<{}, {}, IApplicationState> = (state: IApplicationState) => ({
  account: getUserAccount(state),
  accountLoadingState: getAccountLoadingState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(AccountFinance as any));
