import React from "react";

import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Controller, useForm } from "react-hook-form";
import axios, { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";

import env from "environment";
import { logout } from "store/auth/actions";
import { validatePassword } from "utils/helpers";
import { IUserLoginResponse, User } from "models/user";
import FlrInputPassword from "components/shared/form-elements/textfield/FlrInputPassword";
import SnackbarContent from "components/layout/notistack-provider/SnackbarWithTitle";

import styles from "./styles";
import messagesControls from "translations/layout/controls";
import messages from "translations/account/settings";
import commonMessages from "translations/common";
import notificationsMessages from "translations/layout/notifications";
import { baseUrl } from "shared/constants";
import FlrDialogTitle from "components/shared/modals/components/DialogTitle";

interface IProps {
  // component own props
  account: User;
  classes?: any;

  logoutUser: typeof logout;
  modalOpen: boolean;
  handleCancel: () => void;
}

interface IStateProps {
  // Props passed to the component by `connect`
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

interface IFormData {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

const httpClient = axios.create({ baseURL: env.apiUrl });

const ChangePasswordModal: React.FC<IComponentProps> = ({ modalOpen, handleCancel, classes }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState("");
  const [errorNew, setErrorNew] = React.useState("");

  const { handleSubmit, errors, control, watch } = useForm<IFormData>();

  const passwordWatcher = watch("password");

  const onSubmit = handleSubmit(({ oldPassword, password }): any => {
    setIsLoading(true);

    const regToken = window.localStorage.getItem("token") || "";
    const headers = { Authorization: regToken };
    const response = httpClient.post<any, AxiosResponse<IUserLoginResponse>>(
      `${process.env.REACT_APP_API_ENDPOINT}/account/change-password`,
      { currentPassword: oldPassword, newPassword: password },
      { headers }
    );

    response
      .then(res => {
        if (res.status && res.status === 200) {
          enqueueSnackbar(
            <SnackbarContent title={notificationsMessages.success.defaultMessage}>
              {messages.passwordChanged.defaultMessage}
            </SnackbarContent>,
            {
              variant: "success"
            }
          );

          window.location.assign(`${baseUrl}`);
        }
      })
      .catch(err => {
        const errorText = err && err.response && err.response.data;

        if (errorText === "Current Password incorrect") {
          return setErrorPassword(messages.passwordErrorWrong.defaultMessage);
        }

        if (errorText === "Password must be at least 6 characters") {
          return setErrorNew(commonMessages.passwordValidationLength.defaultMessage);
        }

        enqueueSnackbar(
          <SnackbarContent title={notificationsMessages.error.defaultMessage}>
            {notificationsMessages.errorOccurred.defaultMessage}
          </SnackbarContent>,
          {
            variant: "error"
          }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  return (
    <Grid item sm component={"section"}>
      <Dialog
        open={modalOpen}
        onClose={handleCancel}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <form onSubmit={onSubmit}>
          <FlrDialogTitle text={messages.passwordChangeTitle} />
          <DialogContent className={classes.dialogContent}>
            <Controller
              error={Boolean(errors.oldPassword || errorPassword)}
              helperText={(errors.oldPassword && errors.oldPassword.message) || errorPassword}
              rules={{
                required: messagesControls.requiredFiled.defaultMessage
              }}
              as={FlrInputPassword}
              name="oldPassword"
              type="password"
              control={control}
              defaultValue=""
              className={classes.changePasswordControl}
              fullWidth
              variant={"outlined"}
              label={messages.oldPasswordLabel.defaultMessage}
            />
            <Controller
              error={Boolean(errors.password)}
              helperText={errors.password && errors.password.message}
              rules={{
                required: messagesControls.requiredFiled.defaultMessage,
                validate: validatePassword
              }}
              as={FlrInputPassword}
              name="password"
              type="password"
              control={control}
              defaultValue=""
              className={classes.changePasswordControl}
              fullWidth
              variant={"outlined"}
              label={messages.passwordLabel.defaultMessage}
            />
            <Controller
              error={Boolean(errors.passwordConfirmation || errorNew)}
              helperText={(errors.passwordConfirmation && errors.passwordConfirmation.message) || errorNew}
              rules={{
                required: messagesControls.requiredFiled.defaultMessage,
                validate: (value: string) => value === passwordWatcher || messages.passwordMismatch.defaultMessage
              }}
              as={FlrInputPassword}
              name="passwordConfirmation"
              type="password"
              control={control}
              defaultValue=""
              className={classes.changePasswordControl}
              fullWidth
              variant={"outlined"}
              label={messages.passwordConfirmationLabel.defaultMessage}
            />
          </DialogContent>
          <DialogActions>
            <Button color={"primary"} variant={"outlined"} disabled={isLoading} onClick={handleCancel}>
              {messages.cancelBtnLabel.defaultMessage}
            </Button>
            <Button color={"primary"} variant={"contained"} type={"submit"} disabled={isLoading} onClick={onSubmit}>
              {messages.passwordChangeSubmit.defaultMessage}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Grid>
  );
};

export default withStyles<any>(styles)(ChangePasswordModal);
