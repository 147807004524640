import React, { useMemo } from 'react';
import clsx from 'classnames';
import { TextBody2, TextSubTitle } from 'components/shared/text';
import Tooltip from 'components/shared/tooltip';
import Icon from 'components/shared/Icon';

interface IProps {
  details: any;
  discount?: number;
  children?: any;
  className?: string;
  classes?: any;
}

type ICardDiscountIcon = (props: IProps) => JSX.Element | null;

const CardDiscountIcon: ICardDiscountIcon = ({ discount, details, children, className, classes }) =>
  useMemo(() => {
    const DiscountDescription = () => {
      return details?.map((d: any, idx: any) => (
        <TextBody2 key={idx} color={'inherit'} style={{ color: 'white', whiteSpace: 'normal' }}>
          {d.percent}%&nbsp;-&nbsp;{d.name}
          <br />
        </TextBody2>
      ));
    };

    return !!discount ? (
      <Tooltip
        classes={classes}
        icon={<Icon type={'loyalty'} size={24} offset={8} opacity={1} />}
        title={<DiscountDescription />}
      >
        <div className={clsx('discount', className)}>{children || <TextSubTitle>-{discount}%</TextSubTitle>}</div>
      </Tooltip>
    ) : null;
  }, [discount, details, children, className, classes]);

export { CardDiscountIcon };
