import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, MapStateToProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';

import withStyles from '@mui/styles/withStyles';
import Menu, { MenuProps } from '@mui/material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Hidden from '@mui/material/Hidden';
import { Theme, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { User } from 'models/user';
import { logout } from 'store/auth/actions';
import { IApplicationState } from 'store/reducers';
import { getContactsData } from 'store/main/selectors';

import { LinkInNewTab, notMobileTabs } from 'pages/account';
import Icon from 'components/shared/Icon';
import { TextBody2 } from 'components/shared/text';
import { StyledButtonCommon } from '../top/StyledButtonCommon';
import HeaderMobileMenu from '../mobile-menu';

import { baseUrl } from 'shared/constants';

import messages from 'translations/account/menu';
import styles from './styles';

const StyledMenu = (props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
);

const StyledMenuItem = withStyles(theme => ({
  root: {
    padding: 0,
    '& .active': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))(MenuItem);

const StyledSignOutMenuItem = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    borderTop: `1px solid rgba(0, 0, 0, 0.08)`,

    '&:focus': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))(MenuItem);

export interface IProps {
  user: User;
  iconSize?: number;
  fontSize?: number;
  classes?: any;
}

interface IStateProps {
  // Props passed to the component by `connect`
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
  logout: typeof logout;
  push: typeof push;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const UserNavMenu: React.FC<IComponentProps> = ({
  user,
  logout: logoutUser,
  push: redirectTo,
  classes,
  ...other
}: IComponentProps) => {
  const theme: Theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | boolean>(null);
  const isOpen = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = () => {
    setAnchorEl(state => !state);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (!screenMobile && isOpen) {
      document.addEventListener('scroll', handleClose);
    }

    return () => {
      document.removeEventListener('scroll', handleClose);
    };
  }, [isOpen, screenMobile]);

  const handleLogout = () => {
    handleClose();
    logoutUser();
    const redirectPath = `${baseUrl}/`;
    const currentPath = window.location.pathname;
    // we should force main page reload
    if (currentPath === redirectPath || `${currentPath}/` === redirectPath) {
      window.location.reload();
    } else {
      redirectTo(redirectPath);
    }
  };

  // TODO user nav from account
  return (
    <>
      <StyledButtonCommon
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ marginRight: 0 }}
      >
        <Icon type={'profile'} size={other.iconSize} leftOffset={screenMobile ? 16 : 35} />
          <TextBody2 className={classes.name}>{user && user.profile && user.profile.businessName}</TextBody2>
      </StyledButtonCommon>

      <Hidden smUp>
        <SwipeableDrawer
          anchor={'right'}
          open={isOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          classes={{ paper: classes.drawerPaper }}
          disableBackdropTransition
        >
          <HeaderMobileMenu toggleDrawer={toggleDrawer} user={user} handleLogout={handleLogout} classes={classes} />
        </SwipeableDrawer>
      </Hidden>

      <Hidden smDown>
        <StyledMenu
          PopoverClasses={{
            root: classes.headerDropDown
          }}
          keepMounted
          anchorEl={anchorEl && anchorEl instanceof HTMLElement ? anchorEl : null}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {notMobileTabs.map((content, key) => {
            const innerContent = (
              <>
                <Icon type={content.icon} size={24} offset={8} />
                <span>{content.label.defaultMessage}</span>
              </>
            );
            return (
              <StyledMenuItem key={key} onClick={handleClose} disabled={content.disabled}>
                {content.href ? (
                  <LinkInNewTab href={content.href} className={classes.link}>
                    {innerContent}
                  </LinkInNewTab>
                ) : (
                  <Link className={classes.link} to={`${baseUrl}/account/${content.path}`}>
                    {innerContent}
                  </Link>
                )}
              </StyledMenuItem>
            );
          })}

          <StyledSignOutMenuItem onClick={handleLogout}>
            <Icon type={'signOut'} size={24} offset={16} />
            <ListItemText primary={messages.logout.defaultMessage} />
          </StyledSignOutMenuItem>
        </StyledMenu>
      </Hidden>
    </>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IProps, IApplicationState> = (state: IApplicationState) => ({
  contacts: getContactsData(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      logout,
      push
    },
    dispatch
  )
});
// @ts-ignore
export default withStyles<any>(styles)(connect(mapStateToProps, mapDispatchToProps)(UserNavMenu));
