import * as React from "react";
import { Grid } from "@mui/material";
import Icon from "components/shared/Icon";
import { TextCaption } from "components/shared/text";

import cartMessages from "translations/cart/common";
import { OrderInCartDetailsWrapper } from "./styles";

interface IOwnProps {
  qty: number;
  unit: string;
}

const ProductDetailsInCart: React.FC<IOwnProps> = ({ qty, unit }) =>
  !!qty ? (
    <OrderInCartDetailsWrapper>
      <Grid container justifyContent={"space-between"} className="cart-overall">
        <div className="cart-icon">
          <Icon type={"cart"} size={24} opacity={1} />
        </div>
        <div className="cart-data">
          <TextCaption className="cart-data-text" color={"inherit"}>
            {cartMessages.totalInCart.defaultMessage}
          </TextCaption>
          <TextCaption className="cart-data-value" color={"inherit"}>
            {qty} {` ${unit}`}
          </TextCaption>
        </div>
      </Grid>
    </OrderInCartDetailsWrapper>
  ) : null;

export default ProductDetailsInCart;
