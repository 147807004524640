import * as React from 'react';
import styled from '@emotion/styled/macro';
import { IThemed } from '../../utils/styled';
import { transparentize } from 'polished';

interface IProps {
  value: number;
}

const Price = styled<any>('span')(({ theme }: IThemed) => ({
  color: transparentize(0.5, theme.colors.black),
  fontSize: 14,
  lineHeight: '21px',

  '& > b': {
    color: theme.colors.black,
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 500
  }
}));

const ItemPrice: React.FC<IProps> = ({ value }) => {
  return (
    <Price>
      <b>{value.toFixed(2)}</b> ₴/шт.
    </Price>
  );
};

export default ItemPrice;
