import styled from '@emotion/styled/macro';
import { contentFadeMaterial, scrollBarV5, scrollBarMaterial } from 'styles/mixins';
import { ITheme, IThemed } from 'utils/styled';
import {
  fadeSize,
  leftDrawerWidth,
  rightDrawerWidth,
  headerHeight,
  mobileHeaderHeight,
  breadcrumbsHeight,
  drawerWidthDevice,
  devicePanelHeight, hideLeftPanelWidth
} from 'shared/constants';

export const Layout = styled<any>('div')(({ theme }: IThemed) => ({
  '& .ReactVirtualized__List': {
    ...scrollBarV5
  },

  '& .mainContent': {
    ...scrollBarV5
  },

  '& .MuiDrawer-root': {
    '& > .MuiPaper-root': {
      ...scrollBarV5
    }
  }
}));

const common = {
  layout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    justifyItems: 'stretch',
    width: '100%'
    // TODO check if it works without this on mobile devices
    // overflowX: "hidden"
  },
  rightDrawerLayout: {
    flexGrow: 1,
    marginRight: 0
  },
  rightDrawerLayoutShift: {},
  rightDrawerLayoutInner: {
    height: '100%'
  },
  rightDrawer: {
    width: 0
  },
  rightDrawerPaper: {
    width: rightDrawerWidth,
    borderLeftWidth: 0.5,
    boxShadow: 'none',
    ...scrollBarMaterial
  },

  leftDrawerLayout: {
    flex: 1,
    display: 'flex',
    height: `calc(100vh - ${headerHeight + breadcrumbsHeight}px)`,
    '&.withTopBar': {
      height: `calc(100% - ${breadcrumbsHeight}px)`
    }
  },
  leftDrawerLayoutShift: {
    marginLeft: leftDrawerWidth
  },
  leftDrawerPaper: {
    width: leftDrawerWidth,
    bottom: 0,
    height: 'auto',
    borderRight: 0,
    background: 'transparent',
    boxShadow: 'none',
    ...scrollBarMaterial
  },

  content: {
    flex: 1,
    display: 'flex'
  }
};

const styles = (theme: ITheme) => {
  const asideStyles = {
    '&>:not(.devicePanel)': {
      [(theme as any).breakpoints.down('lg')]: {
        paddingTop: devicePanelHeight - 2,
        position: 'absolute',
        ...scrollBarMaterial
      },
      '& > div': {
        marginTop: theme.spacing(2)
      }
    }
  };
  return {
    // full Screen classes
    layout: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      background: theme.palette.common.cardBlackBackgroundHex,
      ...common.layout
    },
    mobLayoutContainer: {
      paddingTop: mobileHeaderHeight,
      paddingBottom: devicePanelHeight,
      background: theme.palette.common.cardBlackBackgroundHex,

      [(theme as any).breakpoints.up('md')]: {
        paddingTop: 0,
        paddingBottom: 0
      },
      [(theme as any).breakpoints.down('md')]: {
        height: 'calc(100vh - 54px)'
      }
    },
    'mobLayoutContainer-catalog': {
      display: 'flex',
      flex: 1,

      [(theme as any).breakpoints.down('md')]: {
        paddingTop: mobileHeaderHeight + 54
      },
    },
    mobLayoutContainerIOS: {
      [(theme as any).breakpoints.down('md')]: {
        paddingBottom: 'calc(54px + env(safe-area-inset-bottom))',
        height: 'calc(100vh - 106px)'
      }
    },
    mobileDrawer: {
      [(theme as any).breakpoints.down('sm')]: {
        paddingTop: 54
      }
    },
    'mobLayout-catalog': {
      display: 'flex',
      flex: 1,
      [(theme as any).breakpoints.down('sm')]: {
        maxHeight: 'calc(100vh - 162px)',
      },

      '& > div': {
        display: 'flex',
        flex: 1,
        maxHeight: 'calc(100vh - 126px)',
      }
    },
    rightDrawerLayoutShift: {
      ...common.rightDrawerLayoutShift,
      [(theme as any).breakpoints.up('lg')]: {
        width: `calc(100% - ${rightDrawerWidth}px)`,
        marginRight: rightDrawerWidth
      },
      [(theme as any).breakpoints.up('xl')]: {
        width: `calc(100% - ${drawerWidthDevice}px)`,
        marginRight: drawerWidthDevice
      }
    },
    hideRightMargin: {
      '@media(max-width: 1858px)': {
        width: '100%',
        marginRight: 0
      }
    },
    rightDrawerLayoutInner: {
      ...common.rightDrawerLayoutInner
    },
    rightDrawer: {
      ...common.rightDrawer
    },
    rightDrawerPaper: {
      ...common.rightDrawerPaper,
      top: headerHeight,
      height: 'auto',
      bottom: 0,
      '@media(max-width: 1858px)': {
        width: drawerWidthDevice,
        top: 45
      },
      [(theme as any).breakpoints.down('lg')]: {
        top: 0 // headerHeightTablet
      },
      [(theme as any).breakpoints.down('sm')]: {
        width: '100%'
      },
      [(theme as any).breakpoints.up('xl')]: {
        width: drawerWidthDevice
      },
      ...asideStyles
    },

    leftDrawerLayout: {
      flex: 1,
      display: 'flex',
      height: `calc(100vh - ${headerHeight}px)`,
      '&.withTopBar': {
        height: `calc(100% - ${breadcrumbsHeight}px)`
      }
    },
    leftDrawerLayoutShift: {
      [(theme as any).breakpoints.up('lg')]: {
        marginLeft: leftDrawerWidth
      }
    },
    hideLeftMargin: {
      [`@media(max-width: ${hideLeftPanelWidth}px)`]: {
        marginLeft: '0'
      }
    },
    leftDrawerPaper: {
      ...common.leftDrawerPaper,
      top: headerHeight,
      [`@media(max-width: ${hideLeftPanelWidth}px)`]: {
        background: theme.palette.common.white,
        width: `calc(min(375px, 100%))`,
        top: 45,
        zIndex: 1204
      },
      [(theme as any).breakpoints.down('lg')]: {
        background: theme.palette.common.white,
        width: `calc(min(375px, 100%))`,
        top: 0 // headerHeightTablet
      },
      [(theme as any).breakpoints.down('md')]: {
        width: drawerWidthDevice
      },
      [(theme as any).breakpoints.down('sm')]: {
        width: '100%'
      },
      ...asideStyles
    },

    content: {
      flex: 1,
      display: 'flex'
    },
    mobContent: {
      marginTop: breadcrumbsHeight,

      [(theme as any).breakpoints.up('sm')]: {
        marginTop: 0
      }
    },
    'mobContent-catalog': {
      marginTop: 0,
    },
    'mobContent-account-3': {
      // for loyalty program breadcrumbs is 2x row
      [(theme as any).breakpoints.down('sm')]: {
        marginTop: breadcrumbsHeight + 16
      }
    },
    'mobContent-cart-page': {
      // for cart page (tabs height)
      [(theme as any).breakpoints.down('lg')]: {
        marginBottom: 0
      }
    },

    // footer classes
    f_layout: {
      background: theme.palette.gray[50],
      ...common.layout
    },
    f_rightDrawerLayout: {
      position: 'relative',
      display: 'flex',
      minHeight: `calc(100vh - ${headerHeight}px - ${breadcrumbsHeight}px)`,
      '& >:not(.MuiDrawer-docked)': {
        flex: '1',
        display: 'flex',
        flexDirection: 'column'
      },
      ...common.rightDrawerLayout
    },
    f_rightDrawerLayoutShift: {
      paddingRight: rightDrawerWidth,
      [(theme as any).breakpoints.down('xl')]: {
        paddingRight: 0
      },
      '& > .noShadow': {
        boxShadow: 'none !important'
      },
      '& .MuiDrawer-docked': {
        left: -30
      },
      ...common.rightDrawerLayoutShift
    },
    f_rightDrawerLayoutInner: {
      ...common.rightDrawerLayoutInner,
      minHeight: `calc(100vh - ${headerHeight}px - ${breadcrumbsHeight}px)`
    },
    f_rightDrawer: {
      ...common.rightDrawer,
      [(theme as any).breakpoints.down('xl')]: {
        width: 'auto'
      },
      position: 'relative',
      '&.MuiDrawer-docked': {
        left: rightDrawerWidth
      }
    },
    f_rightDrawerPaper: {
      ...common.rightDrawerPaper,
      position: 'fixed',
      [(theme as any).breakpoints.down('lg')]: {
        width: drawerWidthDevice,
        top: 0 // headerHeightTablet
      },
      [(theme as any).breakpoints.down('md')]: {
        width: '100%'
      },
      ...asideStyles
    },

    f_leftDrawerLayout: {
      paddingLeft: 0,
      flex: 1,
      height: '100%',
      display: 'flex',
      position: 'relative',
      '&.withTopBar': {
        height: `calc(100% - ${breadcrumbsHeight + fadeSize}px)`
      },
      '&:after': {
        display: 'block',
        content: "''",
        fontSize: 0,
        position: 'absolute',
        bottom: 0,
        height: fadeSize,
        width: '100%'
      }
    },
    f_leftDrawerLayoutShift: {
      '&.mirrored': {
        [(theme as any).breakpoints.down('xl')]: {
          paddingLeft: 0
        }
      },
      '&:not(.mirrored)': {
        [(theme as any).breakpoints.down('xl')]: {
          paddingLeft: 0
        }
      },
      paddingLeft: leftDrawerWidth
    },
    f_leftDrawer: {
      width: leftDrawerWidth,
      display: 'block',
      [(theme as any).breakpoints.up('xl')]: {
        transform: 'translateX(-100%)'
      },
      [(theme as any).breakpoints.down('lg')]: {
        width: drawerWidthDevice
      },
      [(theme as any).breakpoints.down('md')]: {
        width: '100%'
      }
    },
    f_leftDrawerPaper: {
      ...common.leftDrawerPaper,
      [(theme as any).breakpoints.down('lg')]: {
        width: `calc(min(${drawerWidthDevice}px, 100%))`,
        background: theme.palette.common.white,
        top: 0, // headerHeightTablet,
        paddingTop: theme.spacing(2)
      },
      [(theme as any).breakpoints.down('md')]: {
        width: '100%'
      },
      position: 'absolute',
      ...asideStyles
    },
    f_content: {
      flex: 1,
      display: 'flex',
      '&.mirrored': {
        [(theme as any).breakpoints.down('xl')]: {
          marginLeft: 0
        }
      },
      '&:not(.mirrored)': {
        [(theme as any).breakpoints.down('xl')]: {
          marginLeft: 0
        }
      },
      marginLeft: -leftDrawerWidth,
      marginBottom: fadeSize,
      marginRight: theme.spacing(1),
      [(theme as any).breakpoints.down('sm')]: {
        marginRight: 0
      },
      position: 'relative',
      ...contentFadeMaterial
    },
    leftDrawerPaperCart: {
      width: 238,
      position: 'fixed',
      left: '24px !important',
      top: '177px',
      transition: '0s',
      '@media (max-width: 1500px)': {
        width: 210,
      },
    },
    rightDrawerPaperCart: {
      position: 'fixed',
      top: 90,
      transition: '0s',
    },
    drawerFixed: {
      top: 0,
      transition: '0s',
    },
    leftDrawerCart: {
      width: 238,
      marginLeft: 24,
      marginRight: 30,
    },
    leftDrawerLayoutShiftCart: {
      paddingLeft: 296,
    },
    f_leftPanelCart: {
      width: 296,
      transform: 'none',
      '@media (max-width: 1500px)': {
        width: 242,
      }
    }
  };
};

export default styles;
