import * as React from 'react';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

import { TextSubTitle } from 'components/shared/text';
import { Product } from 'models/product';
import { baseUrl, catalogTypeSlugByCode } from 'shared/constants';

import { Icon } from '../../shared';
import { ColorCircle } from '../../shared/product/ColorCircle';
import styles from '../cart/styles';

interface IProps {
  product: Product;
  className?: string;
  classNameTitle?: string;
  attrsOnly?: boolean;
  classes?: any;
  attrsListStyle?: any;
  marginRight?: string;
}

const ProductDetails: FC<IProps> = ({ product, classes, attrsOnly, attrsListStyle, classNameTitle, ...other }) => {
  return (
    <Grid container {...other}>
      {!attrsOnly && (
        <Grid item>
          <NavLink
            to={`${baseUrl}/catalog/${catalogTypeSlugByCode[product.productTypeCode]}/product=${product.displayCode}`}
          >
            <TextSubTitle color={'inherit'} className={clsx(classes.productNameAttr, classNameTitle)}>
              {product.fullName}
            </TextSubTitle>
          </NavLink>
        </Grid>
      )}
      <Grid item container style={{ whiteSpace: 'nowrap', ...attrsListStyle }}>
        <ColorCircle color={product.attributes.color?.value} className={classes.colorCircle} />
        {product.attributesList.map((attr) => (
          <div key={attr.code} className={clsx(classes.attributeContainer)}>
            <Icon size={12} type={attr.alias} offset={4} opacity={1} />
            <p className={classes.otherAttributeValue}>{attr.value}</p>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default withStyles<any>(styles)(ProductDetails);
