import { FC } from 'react';

import Icon from 'components/shared/Icon';
import { TextSubTitle } from 'components/shared/text';
import { IOfferDiscountDetails, Warehouse } from 'models';
import messages from 'translations/catalog/mini-details';

import { CardDiscountIcon } from '../shared/CardDiscountIcon';

import classes from './DeliveryCardHeader.module.scss';

interface IProps {
  warehouse: Warehouse;
  isPreorder?: boolean;
  special?: boolean;
  discount?: number;
  transit?: boolean;
  discountDetails: IOfferDiscountDetails[];
}

const DeliveryCardHeader: FC<IProps> = ({ warehouse, isPreorder, special, discount, discountDetails, transit }) => {
  return (
    <div className={classes.cardHeader}>
      <div className={classes.cardHeaderWrapper}>
        <TextSubTitle className={classes.cardHeaderTitle}>
          {isPreorder ? 'Флоротека' : warehouse.displayName}
        </TextSubTitle>
      </div>
      <div className={classes.deliveryType}>
        {!!discount ? (
          <CardDiscountIcon discount={discount} details={discountDetails}>
            <div className={classes.discount}>-{(discount * 100).toFixed(0)}%</div>
          </CardDiscountIcon>
        ) : null}
        {special ? (
          <Icon opacity={1} type="fireBordered" size={24} leftOffset={8} className={classes.fireBordered} />
        ) : null}
        {transit ? (
          <>
            <span className={classes.transitOrderType}>{messages.transit.defaultMessage}</span>
            <Icon type="goodsComingBordered" size={24} leftOffset={8} className={classes.goodsComingBordered} />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DeliveryCardHeader;
