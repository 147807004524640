import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ITheme, defaultMaterialTheme } from 'utils/styled';

export default makeStyles((theme: ITheme) =>
  createStyles({
    dialogContainer: {
      padding: '64px 96px',
      [defaultMaterialTheme.breakpoints.down('md')]: {
        padding: defaultMaterialTheme.spacing(4)
      },
      [defaultMaterialTheme.breakpoints.down('sm')]: {
        padding: defaultMaterialTheme.spacing(2)
      }
    },
    balanceModalContent: {
      background: theme.colors.backgroundSecondary
    },
    balanceModalItem: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: defaultMaterialTheme.spacing(1) + 1,
      marginBottom: defaultMaterialTheme.spacing(2),

      background: '#FFFFFF',
      [(theme as any).breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    radioWrapper: {
      [(theme as any).breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center'
      }
    },
    balanceModalText: {
      marginRight: defaultMaterialTheme.spacing(2)
    },
    balanceModalTextMain: {
      marginBottom: defaultMaterialTheme.spacing(1),
      [(theme as any).breakpoints.down('sm')]: {
        marginBottom: 0
      }
    },
    balanceModalAmount: {
      marginLeft: 'auto',
      [(theme as any).breakpoints.down('sm')]: {
        marginLeft: 42
      }
    },
    balanceModalAmountText: {
      [(theme as any).breakpoints.down('md')]: {
        marginTop: theme.spacing(1)
      }
    },
    tableToolbarLeft: {
      display: 'flex'
    },
    dialogTitle: {
      marginTop: 0,
      marginBottom: defaultMaterialTheme.spacing(2),
      textAlign: 'center'
    },
    dialogText: {
      marginTop: defaultMaterialTheme.spacing(3),
      marginBottom: defaultMaterialTheme.spacing(6),
      textAlign: 'center'
    },
    paymentSelectWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: defaultMaterialTheme.spacing(3)
    },
    paymentSelect: {
      width: 250
    }
  })
);
