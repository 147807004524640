import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';

import { ExpandPanel } from 'components/shared';
import FlrSwitchWithLabel from 'components/shared/form-elements/FlrSwitch';
import Icon from 'components/shared/Icon';
import { DetailedProduct, Offer, Product } from 'models';
import { getCartItemQtyByTypeSelector } from 'store/cart/selectors';
import messages from 'translations/catalog/mini-details';

import MiniDetailsOffer from './MiniDetailsOffer';

import classes from './OffersGroup.module.scss';

interface IOfferGroupProps {
  handlePurchase: (offer: Offer, qty: number) => void;
  offers: Offer[];
  selectedProduct: DetailedProduct | Product | null;
  label: string;
  defaultExpanded: boolean;
  hideSwitch?: boolean;
  offerType: string;
}

const OffersGroup: FC<IOfferGroupProps> = ({
  handlePurchase,
  selectedProduct,
  offers,
  label,
  defaultExpanded,
  hideSwitch,
  offerType
}) => {
  const { filter: filterParams } = useParams<{ filter: string; type: string }>();
  const [showOnlySpecial, setShowOnlySpecial] = useState(filterParams.includes('specialOffers=1'));
  const cartItemQty = useSelector(
    getCartItemQtyByTypeSelector(
      String(selectedProduct?.id || ''),
      offerType.replaceAll('inStock', 'stock').toLowerCase()
    )
  );

  return (
    <ExpandPanel
      classNameLabel
      label={`${label} (${offers.length})`}
      defaultExpanded={defaultExpanded}
      disabled={!offers.length}
      classNameSummary={classes.summary}
      classNameDetails={classes.details}
      className={classes.expandPanel}
    >
      <div className={classes.offersContainer}>
        <div className={clsx(classes.container, { [classes.hideContainer]: cartItemQty === 0 && hideSwitch })}>
          {cartItemQty > 0 ? (
            <div className={classes.inCartContainer}>
              <Icon type="shoppingCartOne" className={classes.icon} size={24} offset={4} />
              <span className={classes.inCartLabel}>{messages.inCart.defaultMessage}</span>
              <span className={classes.inCartCount}>
                {cartItemQty}&nbsp;{messages.quantityUnit.defaultMessage}
              </span>
            </div>
          ) : null}
          {!hideSwitch ? (
            <FlrSwitchWithLabel
              label="Лише акційні"
              checked={showOnlySpecial}
              onChange={() => setShowOnlySpecial(!showOnlySpecial)}
              labelClassName={classes.labelSwitch}
            />
          ) : null}
        </div>
        {offers
          .filter((offer) => !showOnlySpecial || offer.special || offer.preOrder)
          .map((offer, index) => (
            <MiniDetailsOffer
              key={index}
              offer={offer}
              discount={offer.discount}
              discountDetails={offer.discountDetails}
              onPurchase={handlePurchase}
            />
          ))}
      </div>
    </ExpandPanel>
  );
};

export default OffersGroup;
