import React from "react";
import { Hidden, Grid } from "@mui/material";
import { debounce } from "lodash";

import { Cart, Order, getOrderItemsNameSort, OrderItem, Outlet, Product } from "models";
import { GTM } from "controllers";

import OrderCard from "components/cards/order/OrderCard";
import OrderCardFinalized from "components/cards/order/OrderCardFinalized";
import OrderCardDevice from "components/cards/order/device/OrderCardDevice";
import ProductDetailsMobile from "components/cards/shared/ProductDetailsMobile";

interface IProps {
  checkoutGroup: string;
  submitted: boolean;
  submitting: boolean;
  locale: any;
  cart: Cart;
  orders: Order[];
  finalizedOrders: Order[];
  outlets: Outlet[];
  updateOrders: (orders: Order[]) => void;
  submitOrder: (order: Order) => void;
  cancelCheckout: () => void;
  anchor: string;
  selectedOrderItem: string;
  onSelectOrderItem: (itemId: string) => void;
  onUnselectOrderItem: () => void;
}

const CheckoutMain: React.FC<IProps> = ({
  checkoutGroup,
  locale,
  cart,
  orders,
  finalizedOrders,
  outlets,
  updateOrders,
  submitOrder,
  cancelCheckout,
  onSelectOrderItem,
  onUnselectOrderItem,
  selectedOrderItem,
  anchor,
  submitted,
  submitting
}) => {
  const totalOrders = orders.length;

  const updateOrder = (index: number, updatedOrder: Order) => {
    const newOrders = [...orders];
    newOrders[index] = updatedOrder;
    updateOrders(newOrders.filter(order => order.totalQty > 0));
  };

  const moveOrderItem = (orderIndex: number, orderItem: OrderItem, newQty: number) => {
    const clonedOrders = [...orders];
    const changedOrder = clonedOrders[orderIndex];
    const nextOrder = clonedOrders[orderIndex + 1];

    const changedOrderItem = changedOrder.items.find((checkItem: OrderItem) => checkItem.offerId === orderItem.offerId);

    if (!changedOrderItem) {
      // TODO add some error handle here
      return;
    }
    let delta = newQty - orderItem.qty;
    // qty was decreased in original order
    if (delta < 0) {
      // if we have order next to original - we add/update its item
      if (nextOrder) {
        const nextOrderItem = nextOrder.items.find((checkItem: OrderItem) => checkItem.offerId === orderItem.offerId);
        if (nextOrderItem) {
          // dont be confused, delta is negative here
          orderItem.qty += delta;
          nextOrderItem.qty -= delta;
        } else {
          const newOrderItem = new OrderItem(orderItem);
          newOrderItem.qty = Math.abs(delta);
          nextOrder.items.push(newOrderItem);
          nextOrder.items.sort(getOrderItemsNameSort(locale));
        }
      }
      if (changedOrderItem) {
        changedOrderItem.qty = newQty;
        changedOrder.totalQty += delta;
      }
      // otherwise we send product qty's as are, so backend creates new order with delta item
      GTM.trackSplitOrder(orderItem);
    } else {
      // qty was increased
      if (nextOrder) {
        let orderIndexFrom = clonedOrders.length - 1;
        // we look for all next orders starting from "farthest" to take item qty from until delta is fulfilled
        while (delta > 0 && orderIndexFrom >= orderIndex + 1) {
          const fromOrder = clonedOrders[orderIndexFrom];
          const fromOrderItemIndex = fromOrder.items.findIndex(
            (checkItem: OrderItem) => checkItem.offerId === orderItem.offerId
          );
          const fromOrderItem = fromOrder.items[fromOrderItemIndex];
          if (fromOrderItem) {
            const qtyAvailable = fromOrderItem.qty;
            const qtyLeft = Math.max(qtyAvailable - delta, 0);
            fromOrderItem.qty = qtyLeft;
            if (qtyLeft > 0) {
              delta = 0;
            } else {
              delta -= qtyAvailable;
            }
            const realDelta = qtyAvailable - qtyLeft;
            changedOrderItem.qty += realDelta;
            fromOrder.totalQty -= realDelta;
            fromOrder.items[fromOrderItemIndex] = fromOrderItem;
          }
          orderIndexFrom--;
        }
      }
    }
    // do not send empty orders to backend
    updateOrders(clonedOrders.filter(checkOrder => checkOrder.totalQty > 0 && checkOrder.items.length > 0));
  };

  const removeOrderItem = (index: number, orderItem: OrderItem) => {
    moveOrderItem(index, orderItem, 0);
  };

  const cancelLastOrder = () => {
    if (orders.length < 2) {
      return cancelCheckout();
    }

    const newOrders = [...orders];

    const lastOrder = newOrders[totalOrders - 1];
    const prevOrder = newOrders[totalOrders - 2];

    if (lastOrder && prevOrder) {
      lastOrder.items.forEach((lastOrderItem: OrderItem) => {
        const prevOrderItem = prevOrder.items.find(
          (checkItem: OrderItem) => checkItem.offerId === lastOrderItem.offerId
        );
        if (prevOrderItem) {
          prevOrderItem.qty += lastOrderItem.qty;
        } else {
          prevOrder.items.push(lastOrderItem);
        }
      });
      lastOrder.items = [];
    }

    updateOrders(newOrders.filter(order => order.totalQty > 0 && order.items.length > 0));
  };

  const [mobileProduct, setMobileProductInfo] = React.useState<Product | null>(null);

  const onMoreInfo = (product: Product) => {
    setMobileProductInfo(product);
  };

  const commonProps = {
    anchor,
    checkoutGroup,
    cart,
    submitted,
    submitting,
    outlets,
    orderIndexShift: finalizedOrders.length,
    onSubmit: submitOrder,
    onCancel: cancelLastOrder,
    onChangeItemAmount: () => null
  };

  const debouncedQtyUpdate = debounce(moveOrderItem, 500);
  const debouncedOrderUpdate = debounce(updateOrder, 300);
  const handleOrderUpdate = (index: number, order: Order) => {
    if (!order.outlet) {
      updateOrder(index, order);
    } else {
      debouncedOrderUpdate(index, order);
    }
  };

  return (
    <Grid container item xs={12}>
      <Hidden smDown>
        {finalizedOrders.map((order, index) => (
          <OrderCardFinalized anchor={anchor} order={order} orderIndex={index + 1} outlets={outlets} key={order.id} />
        ))}
        {orders.map((order, index) => (
          <OrderCard
            key={order.id}
            orderIndex={index + 1}
            hasNext={!!orders[index + 1]}
            order={order}
            onQtyUpdate={(updatedOrderItem: OrderItem, newQty: number) =>
              debouncedQtyUpdate(index, updatedOrderItem, newQty)
            }
            onDeliveryUpdate={(updatedOrder: Order) => handleOrderUpdate(index, updatedOrder)}
            onRemoveItem={(orderItem: OrderItem) => removeOrderItem(index, orderItem)}
            {...commonProps}
          />
        ))}
      </Hidden>
      <Hidden smUp>
        {orders.map((order, index) => (
          <OrderCardDevice
            key={order.id}
            orderIndex={index + 1}
            order={order}
            onQtyUpdate={(updatedOrderItem: OrderItem, newQty: number) =>
              debouncedQtyUpdate(index, updatedOrderItem, newQty)
            }
            onSelectOrderItem={onSelectOrderItem}
            onUnselectOrderItem={onUnselectOrderItem}
            selectedOrderItemId={selectedOrderItem}
            onMoreInfo={onMoreInfo}
            onDeliveryUpdate={(updatedOrder: Order) => debouncedOrderUpdate(index, updatedOrder)}
            onRemoveItem={(orderItem: OrderItem) => removeOrderItem(index, orderItem)}
            {...commonProps}
          />
        ))}
        <ProductDetailsMobile onClose={() => setMobileProductInfo(null)} product={mobileProduct} />
      </Hidden>
    </Grid>
  );
};

export default CheckoutMain;
