import React, { useEffect } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router';
// import { FormattedMessage } from 'react-intl';

import { PRODUCT_GROUP_DISCOUNT } from 'models';
import { fetchDiscountByGroupAsync, IDiscountByGroup } from 'store/discount-by-group/actions';
import { catalogSetFilter } from 'store/catalog/actions';
import { IApplicationState } from 'store/reducers';
import { getDiscountByGroup, getDiscountByGroupLoadingState } from 'store/discount-by-group/selectors';

import { TitleH1, TitleH2, TextSubTitle } from 'components/shared/text';
import {
  ProductGroupDiscountCard,
  ProductGroupDiscountProductsHistoryCard,
  ProductGroupOrdersHistoryCard,
  ProductGroupDiscountProgressCard
} from './cards';
import DiscountRules from './rules/DiscountRules';

import { baseUrl, defaultCatalogProductType } from 'shared/constants';
import { loyaltyBreadcrumb, loyaltyBreadcrumbMobile } from './index';
import { breadCrumbsContext } from '../breadCrumbsState';
import messages from 'translations/account/loyalty';
import styles from './styles';
import CategoriesListInGroupCard from './cards/CategoriesListInGroupCard';
import DiscountLevels from './rules/DiscountLevels';

interface IProps {
  classes: any;
}

interface IStateProps {
  discountByGroup: IDiscountByGroup | null;
  discountByGroupLoadingState: boolean;
}

interface IDispatchProps {
  fetchDiscountByGroup: typeof fetchDiscountByGroupAsync.request;
  setCatalogFilter: any;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const ProductGroupDiscount: React.FC<IComponentProps> = ({
  classes,
  discountByGroup,
  discountByGroupLoadingState,
  fetchDiscountByGroup,
  setCatalogFilter
}) => {
  const { discountGroupId } = useParams<{ discountGroupId: string }>();
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const breadcrumb = screenMobile ? loyaltyBreadcrumbMobile : loyaltyBreadcrumb;

  const { setCurrentUrl } = React.useContext(breadCrumbsContext);
  const updateBreadCrumbs = React.useCallback(
    (bc, label) => {
      setCurrentUrl([{ label: bc.label, path: bc.path }, { label }]);
    },
    [setCurrentUrl]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (discountByGroup) {
      updateBreadCrumbs(breadcrumb, discountByGroup?.discountRule.name);
    }
    return () => {
      setCurrentUrl([]);
    };
    // eslint-disable-next-line
  }, [breadcrumb, updateBreadCrumbs, setCurrentUrl, discountByGroup]);

  useEffect(() => {
    if (discountGroupId) {
      fetchDiscountByGroup(discountGroupId);
    }
  }, [fetchDiscountByGroup, discountGroupId]);

  const handleClick = () => {
    setCatalogFilter({ discount: true });
  };

  return (
    <Grid container spacing={2}>
      <Grid item container spacing={3}>
        <Grid item container xs spacing={1} direction={'column'}>
          <Grid item xs>
            <TitleH1 className={classes.header}>{discountByGroup?.discountRule.name}</TitleH1>
          </Grid>
          <Grid item xs>
            <TextSubTitle sx={{ whiteSpace: 'nowrap' }}>{messages.productGroupTitle.defaultMessage}</TextSubTitle>
          </Grid>
        </Grid>
        <Grid item xs={'auto'}>
          <Link className={classes.link} to={`${baseUrl}/catalog/${defaultCatalogProductType}`}>
            <Button color="primary" variant={'contained'} onClick={handleClick}>
              {messages.buyWithDiscount.defaultMessage}
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item container spacing={3} alignItems={'flex-start'}>
        <Grid item container sm={6} md={5} spacing={3}>
          <Grid item xs={12}>
            <ProductGroupDiscountCard discount={discountByGroup} />
          </Grid>
          {discountByGroup?.discountRule.discountType !== 'static' &&
          discountByGroup?.discountRule.discountType !== 'website' ? (
            <Grid item xs={12}>
              <ProductGroupDiscountProgressCard
                discount={discountByGroup?.discountRule}
                loading={discountByGroupLoadingState}
                progressValue={discountByGroup?.progressValue}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <ProductGroupOrdersHistoryCard
              loading={discountByGroupLoadingState}
              data={(discountByGroup && Object.values(discountByGroup.orders)) || []}
            />
          </Grid>
        </Grid>
        <Grid item container xs>
          <CategoriesListInGroupCard
            categories={discountByGroup?.discountRule.categories}
            group={discountByGroup?.discountRule.name}
          />
          <ProductGroupDiscountProductsHistoryCard
            group={discountByGroup?.discountRule.name}
            mobile={screenMobile}
            loading={discountByGroupLoadingState}
            data={(discountByGroup && Object.values(discountByGroup.transactions)) || []}
          />
        </Grid>
      </Grid>
      {discountByGroup?.discountRule?.discountType !== 'static' &&
      discountByGroup?.discountRule.discountType !== 'website' ? (
        <Grid item container sm className={classes.discountRules}>
          <Grid item xs={12}>
            <TitleH2>{messages.rules.defaultMessage}</TitleH2>
          </Grid>
          <Grid item container xs={12} spacing={4}>
            <Grid item container xs={12} sm={6} spacing={6} direction={'column'}>
              <DiscountRules type={PRODUCT_GROUP_DISCOUNT} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DiscountLevels conditions={discountByGroup?.discountRule?.conditions} />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  discountByGroupLoadingState: getDiscountByGroupLoadingState(state),
  discountByGroup: getDiscountByGroup(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      fetchDiscountByGroup: fetchDiscountByGroupAsync.request,
      setCatalogFilter: catalogSetFilter
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(ProductGroupDiscount as any));
