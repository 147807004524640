import { ITheme, defaultMaterialTheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  tableToolbarContainer: {
    [(theme as any).breakpoints.down('sm')]: {
      maxWidth: `calc(100vw - ${theme.spacing(4)})`
    },
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    display: 'flex',

    '& > button': {
      marginRight: theme.spacing(6)
    }
  },
  reviewContainer: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },

  reviewAlert: {
    width: 'calc(100% + 48px)',
    marginLeft: -24
  },
  reviewTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  ratingsContainer: {
    marginBottom: theme.spacing(3)
  },
  ratingsItem: {
    marginBottom: theme.spacing(1),
    height: theme.spacing(3)
  },
  ratingLabel: {
    color: theme.palette.text.secondary
  },
  reviewCommentField: {
    marginBottom: theme.spacing(2)
  },
  reviewSendButton: {
    marginBottom: theme.spacing(2)
  },
  reviewReclamationToggleButton: {
    marginBottom: theme.spacing(2)
  },
  reviewReclamationField: {
    marginBottom: theme.spacing(3)
  },
  reviewPhotoLabel: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary
  },
  reviewPhotoHelper: {
    marginBottom: theme.spacing(2)
  },
  reviewPhotoHelperError: {
    display: 'flex',
    marginBottom: theme.spacing(0.5)
  },
  reviewPhotoHelperFileName: {
    marginRight: theme.spacing(1),
    maxWidth: 100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  reviewPhotoDropZone: {
    marginLeft: theme.spacing(-1),
    marginBottom: theme.spacing(2)
  },
  previewContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: `calc(100% + ${theme.spacing(1)})`
  },

  previewAddPhotoActive: {
    border: `2px solid ${theme.palette.common.primaryHoverPress} !important`,
    color: `${theme.palette.common.primaryHoverPress}  !important`
  },
  previewAddPhoto: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 98,
    height: 98,
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: 8,
    color: theme.palette.primary.main,
    cursor: 'pointer',

    transition: defaultMaterialTheme.transitions.create(['color', 'background-color', 'border-color'], {
      easing: defaultMaterialTheme.transitions.easing.easeInOut,
      duration: defaultMaterialTheme.transitions.duration.shortest
    }),

    '&:focus': {
      outline: 'none',
      boxShadow: `0 0 0 1px ${theme.palette.secondary.main}`
    },
    '&:hover': {
      border: `2px dashed ${theme.palette.common.primaryHoverPress}`,
      color: theme.palette.common.primaryHoverPress
    },
    '&:active': {
      border: `2px dashed ${theme.palette.common.primaryHoverPress}`,
      color: theme.palette.common.primaryHoverPress,
      backgroundColor: theme.palette.common.primaryClickablePressBackground
    }
  },
  previewAddPhotoLabel: {
    marginTop: 5,
    color: 'inherit'
  },
  previewPhoto: {
    position: 'relative',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 98,
    height: 98,
    borderRadius: 8,

    '&:hover .MuiIconButton-root': {
      color: `${theme.palette.error.main} !important`,
      backgroundColor: `none !important`,

      '&::before': {
        backgroundColor: `${theme.palette.common.errorWarningBackground} !important`,
        opacity: 0.44
      }
    }
  },
  previewPhotoImage: {
    borderRadius: 8,
    width: 98,
    height: 98,
    objectFit: 'cover',
    backgroundColor: theme.palette.common.disabledBlackBackground
  },
  previewPhotoImageRemove: {
    position: 'absolute',
    zIndex: 1,
    top: 8,
    right: 8,
    borderRadius: '50%',
    overflow: 'hidden',
    color: `${theme.palette.common.white} !important`,
    transition: defaultMaterialTheme.transitions.create(['color'], {
      easing: defaultMaterialTheme.transitions.easing.easeIn,
      duration: defaultMaterialTheme.transitions.duration.short
    }),

    '& .MuiIconButton-label': {
      zIndex: 1
    },

    '&::before': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'block',
      backgroundColor: `${theme.palette.common.helperBlack} !important`,
      transition: defaultMaterialTheme.transitions.create(['background-color'], {
        easing: defaultMaterialTheme.transitions.easing.easeOut,
        duration: defaultMaterialTheme.transitions.duration.short
      }),
      opacity: 0.44
    }
  },
  reclamationTextLabel: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  reclamationText: {
    marginBottom: theme.spacing(2)
  },
  orderCard: {
    marginBottom: theme.spacing(10),
    paddingBottom: theme.spacing(3)
  },
  orderSubTitleGrid: { marginTop: 0, marginBottom: theme.spacing(2) },
  orderSubTitle: { marginTop: 0, marginBottom: 0 },
  orderLabelProductDivider: { marginBottom: theme.spacing(2) },
  orderLabelProduct: { marginTop: theme.spacing(4), marginBottom: theme.spacing(2) },
  orderItemsGrid: { marginBottom: theme.spacing(3) },
  expandedMobileContainer: {
    position: 'relative'
  },
  expandedMobileRow: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  commentTitle: {
    color: theme.palette.text.secondary
  },
  tableContainer: {
    position: 'relative'
  }
});

export default styles;
