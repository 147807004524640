import React from "react";
import TextareaAutosize, { TextareaAutosizeProps } from "@mui/material/TextareaAutosize";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { ITheme } from "utils/styled";

interface IProps extends TextareaAutosizeProps {}

const useStyles = makeStyles((theme: Theme & ITheme) => ({
  root: {
    minHeight: 80,
    borderWidth: 2,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: 8,
    padding: 8,

    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: 0,
    }
  }
}))

const FlrTextarea = (props: IProps) => {
  const classes = useStyles()

  return (
    <TextareaAutosize {...props} className={classes.root}/>
  );
};

export default FlrTextarea;