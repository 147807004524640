import React, { FC } from 'react';
import clsx from 'clsx';
import { upperFirst } from 'lodash';
import moment, { Moment } from 'moment';
import { Button } from '@mui/material';

import { Text } from 'components/layout/content/Text';
import { SplitedPrice } from 'components/prices';
import Icon from 'components/shared/Icon';
import { Offer } from 'models';
import { INFINITE } from 'shared/constants';
import messages from 'translations/catalog/mini-details';

import cartMessages from '../../../translations/cart/common';
import FlrQuantity from '../../shared/form-elements/quantity/FlrQuantity';
import SpecialOffer from './SpecialOffer';

import classes from './DeliveryCardInner.module.scss';

interface IProps {
  offer: Offer;
  qtyState: number;
  isMobile?: boolean;
  withDiscount?: boolean;
  activePrice: number;
  inPackageCount: number;
  finalPrice: number;
  deliveryOptions: Array<Moment | null>;
  stock: number;
  min: number;
  max?: number;
  setQtyState: React.Dispatch<React.SetStateAction<number>>;
  hasPreOrders?: boolean;
  handlePurchase: () => void;
  specialOfferTotal: () => JSX.Element;
  renderConditionalTotal: () => JSX.Element;
  isDisabled: () => boolean;
}

const DeliveryCardInner: FC<IProps> = ({
  offer,
  qtyState,
  isMobile,
  withDiscount,
  activePrice,
  finalPrice,
  inPackageCount,
  deliveryOptions,
  stock,
  max,
  min,
  setQtyState,
  hasPreOrders,
  handlePurchase,
  specialOfferTotal,
  renderConditionalTotal,
  isDisabled
}) => {
  const {
    preOrder: isPreOrder,
    special: specialOffer,
    transit: transitOffer,
    endDate: transitDeliveryDate,
    inStock,
    priceDate
  } = offer;

  return (
    <>
      <div className={classes.container}>
        <div className={classes.deliveryTimeContainer}>
          <div className={classes.deliveryTimeWrapper}>
            {transitOffer ? (
              <div className={classes.transitContainer}>
                <span className={classes.label}>{messages.transitLabel.defaultMessage}</span>
                <Text bold className={classes.label}>
                  {upperFirst(moment(transitDeliveryDate).format('dd DD.MM'))}
                </Text>
              </div>
            ) : null}
            <div className={clsx(classes.deliveryTimeInner, {[classes.deliveryTimePreOrder]: isPreOrder})}>
              {isMobile ? (
                <Icon type="delivery" size={18} opacity={1} />
              ) : (
                <span className={classes.label}>{messages.deliveryLabel.defaultMessage}</span>
              )}
              <div>
                <Text className={classes.deliveryDate}>
                  {upperFirst(deliveryOptions[0] ? deliveryOptions[0].format('dd DD.MM') : '')}
                </Text>
                <span className={classes.deliveryLabel}>
                  {' - '}
                  {isPreOrder ? INFINITE : upperFirst(deliveryOptions[1] ? deliveryOptions[1].format('dd DD.MM') : '')}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.priceWrapper}>
            {!offer.special ? (
              <>
                <span className={classes.label}>{messages.pricePerQuantity.defaultMessage}</span>
                <div className={classes.cardOffer}>
                  <div className={classes.priceContent}>
                    {isPreOrder ? (
                      <span className={classes.preOrderText}>
                        на {moment(priceDate).format('DD.MM.YY')}
                      </span>
                    ) : (
                      <SplitedPrice
                        value={withDiscount && isPreOrder ? finalPrice : activePrice}
                        fontSize={'h3'}
                        className={clsx({
                          [classes.splitedWithoutDiscount]: activePrice,
                          [classes.splitedPriceDiscount]: !activePrice && withDiscount,
                          [classes.splitedPreOrder]: isPreOrder
                        })}
                        fontColor={'inherit'}
                      />
                    )}
                    {isPreOrder ? (
                      <SplitedPrice
                        value={withDiscount && isPreOrder ? finalPrice : activePrice}
                        fontSize={'h3'}
                        className={clsx({
                          [classes.splitedWithoutDiscount]: activePrice,
                          [classes.splitedPriceDiscount]: !activePrice && withDiscount,
                          [classes.splitedPreOrder]: isPreOrder
                        })}
                        fontColor={'inherit'}
                        prefix={<span className={classes.boldText}>~</span>}
                      />
                    ) : null}
                    {withDiscount && !isPreOrder ? (
                      <SplitedPrice
                        value={finalPrice}
                        fontSize={'h3'}
                        className={classes.splitedPriceDiscount}
                        fontColor={'inherit'}
                      />
                    ) : null}
                  </div>
                </div>
              </>
            ) : (
              <SpecialOffer price={offer.price} />
            )}
          </div>
        </div>
        <div className={classes.priceContainer}>
          <div className={classes.availableCountContainer}>
            <span className={classes.label}>{messages.availableInStock.defaultMessage}</span>
            <span className={classes.value}>
              {isFinite(stock)
                ? `${stock} ${messages.quantityUnit.defaultMessage}`
                : `${INFINITE} ${messages.quantityUnit.defaultMessage}`}
            </span>
          </div>
          <div className={classes.sumContainer}>
            <span className={classes.sumLabel}>{messages.transitOrderColumn4.defaultMessage}</span>
            <span className={classes.sumPriceLabel}>
              {specialOffer ? specialOfferTotal() : renderConditionalTotal()}
            </span>
          </div>
        </div>
        <div className={classes.totalPriceContainer}>
          <div className={classes.quantityContainer}>
            <FlrQuantity
              value={qtyState}
              inPackageCount={inPackageCount}
              min={min}
              maxOptional={!isPreOrder ? inStock : undefined}
              onChange={setQtyState}
              disableMaxLimit={!hasPreOrders}
              classNameWrapper={classes.quantity}
              dense={isMobile}
              hideKeyboard={isMobile}
              isSelect
            />
          </div>
          <div className={classes.sumButton}>
            <Button
              color={'primary'}
              variant={isPreOrder ? 'outlined' : 'contained'}
              onClick={handlePurchase}
              disabled={isDisabled()}
              fullWidth
              className={classes.btn}
              classes={{ root: classes.btn }}
            >
              {isPreOrder ? cartMessages.preOrder.defaultMessage : cartMessages.buy.defaultMessage}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryCardInner;
