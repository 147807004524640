import { Component, FocusEvent, MouseEvent } from 'react';
import clsx from 'clsx';
import { iOS, isMobileDevice } from 'environment';
import { IconButton, Popper, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import { withStyles } from '@mui/styles';

import Icon from 'components/shared/Icon';
import { TextBody1 } from 'components/shared/text';
import { ITheme } from 'utils/styled';

import FlrSelect2 from '../select2/FlrSelect2';
import { FlrQuantityWrapper } from './styles';

interface IProps {
  dense?: boolean;
  value?: number;
  inPackageCount?: number;
  min?: number;
  max?: number;
  maxOptional?: number;
  onChange?: (value: number) => void;
  onIncrement?: (value: number) => void;
  onDecrement?: (value: number) => void;
  disabled?: boolean;
  classes: any;
  disableMaxLimit?: boolean;
  withoutActionButtons?: boolean;
  classNameWrapper?: string;
  label?: string;
  classNameButton?: string;
  classNameContainer?: string;
  hideKeyboard?: boolean;
  isSelect?: boolean;
  fullWidth?: boolean;
  isGap?: boolean;
  refreshQtyFromProps?: boolean;
}

const DEFAULT_MAX = 500;
const isIOS = iOS(window.navigator.userAgent);
const isMobile = Boolean(isMobileDevice(window.navigator));

const filterOptions = (options: number[], state: FilterOptionsState<number>) => {
  const { inputValue }: { inputValue: string } = state;

  const nearest =
    options && options.length
      ? options.reduce((prev, curr) =>
          Math.abs(curr - Number(inputValue)) < Math.abs(prev - Number(inputValue)) ? curr : prev
        )
      : -1;

  return options.filter((item) => String(item).indexOf(String(inputValue)) > -1 || item === nearest);
};

const hideKeyboardProps: any = {
  readOnly: true,
  style: { userSelect: 'none' },
  onFocus: (event: any) => event.target.blur(),
  onMouseDown: (event: any) => event.preventDefault()
};

class FlrQuantity extends Component<IProps> {
  public static defaultProps = {
    min: 1,
    inPackageCount: 1
  };
  public state = {
    value: this.props.value || this.props.min || this.props.inPackageCount || 1,
    open: false,
    ref: null
  };

  constructor(props: IProps) {
    super(props);
    this.handleInc = this.handleInc.bind(this);
    this.handleDec = this.handleDec.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnOpen = this.handleOnOpen.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.refreshQtyFromProps && prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value || this.props.min || this.props.inPackageCount || 1
      });
    }
  }

  public handleChange(value: number) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  }

  public handleOnOpen() {
    this.setState({ open: true });
  }

  public handleOnClose() {
    this.setState({ open: false });
  }

  public handleAutoCorrection = (ev: FocusEvent<HTMLInputElement>) => {
    const value = Number(ev.target.value);
    const { max } = this.props;
    const inPackageCount = this.props.inPackageCount ? this.props.inPackageCount : 1;

    if (!value) {
      this.setState({
        value: inPackageCount
      });

      this.handleChange(inPackageCount);
      return;
    }

    const remainder = value % inPackageCount;
    let newValue = !remainder ? value : value - remainder + inPackageCount;

    if (this.props.disableMaxLimit && max && newValue > max) {
      newValue = max;
    }

    if (this.props.min && newValue < this.props.min) {
      newValue = this.props.min;
    }

    this.setState({
      value: newValue
    });

    this.handleChange(newValue);
  };

  public handleAutoCompleteChange = (value: number) => {
    this.setState({
      value
    });

    this.handleChange(value);
  };

  public handleInc(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    const { max, onIncrement } = this.props;
    const inPackageCount = this.props.inPackageCount ? this.props.inPackageCount : 1;

    const { value } = this.state;
    const incValue = value + inPackageCount;
    const nextValue = max !== undefined && this.props.disableMaxLimit ? (max > value ? incValue : value) : incValue;

    this.setState({
      value: nextValue
    });

    if (onIncrement) {
      onIncrement(nextValue);
    } else {
      this.handleChange(nextValue);
    }
  }

  public handleDec(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    const { onDecrement } = this.props;
    const inPackageCount = this.props.inPackageCount ? this.props.inPackageCount : 1;

    const value = this.state.value - inPackageCount;
    const nextValue = this.props.min !== undefined ? (inPackageCount < value ? value : inPackageCount) : value;

    this.setState({
      value: nextValue
    });

    if (onDecrement) {
      onDecrement(nextValue);
    } else {
      this.handleChange(nextValue);
    }
  }

  public get max(): number {
    if (this.props.disableMaxLimit) {
      return this.props.maxOptional || this.props.max || 0;
    }
    return this.props.maxOptional || DEFAULT_MAX * (this.props.inPackageCount ? this.props.inPackageCount : 1);
  }

  public getSteps() {
    const inPackageCount = this.props.inPackageCount ? this.props.inPackageCount : 1;

    const items = [];
    const maxOption = this.max;
    const max = maxOption === inPackageCount ? inPackageCount : maxOption;

    for (let i = 1; i <= max / inPackageCount; i++) {
      if (inPackageCount * i >= (this.props.min || 0)) {
        items.push(inPackageCount * i);
      }
    }

    return items;
  }

  public options() {
    return this.getSteps().map((item) => ({
      value: String(item),
      label: String(item)
    }));
  }

  public render() {
    const {
      min,
      classes,
      disabled,
      dense,
      withoutActionButtons,
      classNameWrapper,
      hideKeyboard,
      isSelect,
      classNameButton
    } = this.props;
    const { value } = this.state;

    return (
      <div className={clsx({ [classes.dense]: dense })}>
        <FlrQuantityWrapper display="flex" alignItems="center" justifyContent="center">
          {!withoutActionButtons ? (
            <IconButton
              className={clsx("button-decrease", classNameButton)}
              onClick={this.handleDec}
              disabled={value === min || disabled}
              size="large"
            />
          ) : null}
          <div className={classNameWrapper}>
            {isSelect && isMobile ? (
              <FlrSelect2
                options={this.options()}
                value={String(value)}
                isDisabled={disabled}
                onChange={(event: any) => {
                  this.handleAutoCompleteChange(Number(event.target.value));
                }}
                inputClasses={classes.selectInput}
                qtySelect
              />
            ) : (
              <Autocomplete
                classes={{
                  listbox: classes.ListBox,
                  inputRoot: this.state.open ? classes.InputRootActive : classes.InputRoot,
                  input: classes.input,
                  paper: classes.paper
                }}
                fullWidth={this.props.fullWidth}
                size={dense ? 'small' : 'medium'}
                disabled={disabled}
                disableClearable
                // freeSolo
                onOpen={this.handleOnOpen}
                onClose={this.handleOnClose}
                multiple={false}
                onBlur={this.handleAutoCorrection}
                options={this.getSteps()}
                filterOptions={filterOptions}
                getOptionLabel={(option: string | number) => String(option)}
                value={value}
                onChange={(event: any, newValue: any) => {
                  this.handleAutoCompleteChange(Number(newValue));
                }}
                forcePopupIcon
                popupIcon={<Icon type={'chevronDown'} size={24} />}
                renderOption={(props: any, option, { selected }: any) => (
                  <li className={classes.ListItem}>
                    {!selected && <div className={classes.ItemDivider} />}
                    <div className={classes.Item} {...props}>
                      <TextBody1
                        className={clsx(classes.ItemText, {
                          [classes.ItemTextSelected]: !!selected
                        })}
                      >
                        {option}
                      </TextBody1>
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={this.props.label}
                    inputProps={{
                      ...params.inputProps,
                      ...(hideKeyboard ? hideKeyboardProps : {}),
                      type: 'number',
                      min,
                      className: clsx('quantity-input', {
                        active: this.state.open
                      })
                    }}
                  />
                )}
                {...(isIOS ? { PopperComponent: (props) => <Popper {...props} placement="top" /> } : {})}
              />
            )}
          </div>
          {!withoutActionButtons ? (
            <IconButton
              className={clsx("button-increase", classNameButton)}
              onClick={this.handleInc}
              disabled={value === this.max || disabled}
              size="large"
            />
          ) : null}
        </FlrQuantityWrapper>
      </div>
    );
  }
}

const MAX_HEIGHT_LIST = 170;

const styles = (theme: ITheme) => ({
  dense: {
    '& .MuiAutocomplete-inputRoot': {
      width: 90,
      paddingRight: '32px !important'
    },
    '& .button-increase': {
      marginLeft: theme.spacing(1)
    },
    '& .button-decrease': {
      marginRight: theme.spacing(1)
    },
    '& .MuiFormControl-root': {
      minWidth: 90
    }
  },
  paper: {
    borderRadius: 8,
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    borderRight: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 'none !important',
    maxHeight: MAX_HEIGHT_LIST,
    overflowY: 'auto',
    ...theme.scrollBar,

    "[data-popper-placement='top'] &": {
      marginTop: 0,
      marginBottom: -6,
      paddingBottom: 6,

      borderBottom: `none`,
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
      borderBottomRightRadius: '0 !important',
      borderBottomLeftRadius: '0 !important',

      borderTop: `2px solid ${theme.palette.primary.main}`
    },

    "[data-popper-placement='bottom'] &": {
      marginTop: -6,
      paddingTop: 6,
      borderTop: 'none',
      borderTopRightRadius: '0 !important',
      borderTopLeftRadius: '0 !important',
      borderBottom: `2px solid ${theme.palette.primary.main}`
    },

    '& > ul': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  Option: {
    width: '100%',
    position: 'relative',
    padding: 0,

    '&:focus': {
      backgroundColor: theme.palette.common.white
    },
    '&&:hover': {
      backgroundColor: theme.palette.common.white,
      color: `${theme.palette.common.black} !important`,

      '& $ItemText': {
        color: `${theme.palette.common.black} !important`
      }
    },
    '&&:active': {
      backgroundColor: theme.palette.common.primaryClickablePressBackground,
      color: `${theme.palette.common.black} !important`,

      '& $ItemText': {
        color: `${theme.palette.common.black} !important`
      }
    },
    "&&[aria-selected='true']": {
      backgroundColor: theme.palette.common.disabledBlackBackground
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.common.white
    }
  },
  ListItem: {
    width: '100%',
    padding: 0,
    position: 'relative',
    minHeight: 'auto',
    cursor: 'pointer',
    display: 'flex',
    outline: 0,
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '-webkit-tap-highlight-color': 'transparent'
  },
  ListBox: {
    maxHeight: MAX_HEIGHT_LIST - 8,
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: 0,
    ...theme.scrollBar
  },
  Item: {
    width: '100%',

    '&:hover': {
      color: theme.palette.common.black
    }
  },
  ItemDivider: {
    top: 0,
    marginLeft: 12,
    marginRight: 12,
    position: 'absolute',
    display: 'block',
    width: 'calc(100% - 24px)',
    height: 1,
    borderTop: `1px solid ${theme.palette.common.disabledBlackBackground}`
  },
  ItemText: {
    paddingLeft: 12,
    paddingRight: 16,
    paddingTop: 9,
    paddingBottom: 9,
    marginBottom: 0,
    color: theme.palette.text.secondary,
    textAlign: 'right'
  },
  ItemTextSelected: {
    color: theme.palette.common.black
  },
  InputRoot: {
    padding: '0 !important',
    paddingRight: '44px !important',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: 8,
    width: 112,
    '&.dense': {
      width: 90
    }
  },
  InputRootActive: {
    padding: '0 !important',
    paddingRight: '44px !important',
    borderRadius: 8,
    paddingBottom: 8,
    width: 112,
    '&.dense': {
      width: 90
    }
  },
  selectInput: {
    '&.MuiOutlinedInput-input': {
      padding: 8,

      '&>*': {
        justifyContent: 'center'
      }
    }
  }
});

export default withStyles<any>(styles)(FlrQuantity);
