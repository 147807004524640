import React from 'react';
import moment from 'moment';
import { Box, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
import env from 'environment';
import { FormattedMessage } from 'react-intl';
import FlrCard from 'components/shared/card/FlrCard';
import Tooltip from 'components/shared/tooltip';
import { TitleH1, TextSubTitle, TextBody2 } from 'components/shared/text';
import { Icon } from 'components/shared';
import messages from 'translations/account/dashboard';
import { IDiscountCondition, IClientDiscount } from 'models';

interface IPropsLoyaltyCard {
  discount: IDiscountCondition;
  classes: any;
  link: string;
  isLoading?: boolean;
  clientDiscount?: IClientDiscount;
}

const DashboardLoyaltyCard: React.FC<IPropsLoyaltyCard> = ({
  discount,
  classes,
  link,
  clientDiscount,
  isLoading,
  ...props
}) => {
  const { image, name, discountType } = discount;
  const imageUrl = image ? `${env.apiUrl}/static/product-discount-images/${image}` : '/images/discount-card-2.webp';

  const guaranteedDiscountPercent = discount?.discountData?.nextDiscountProgress?.percent || 0;

  const nextDiscountPercent: any = (() => {
    if (discount?.discountData) {
      return Object.keys(discount.conditions).find(
        k => Number(discount.conditions[k]) >= (discount?.discountData?.nextDiscountProgress?.progressValue || 0)
      );
    }
    return Object.keys(discount.conditions)[0];
  })();

  const getPercent = () => {
    if (discount.discountType === 'static' || discount.discountType === 'website') {
      return Object.keys(discount.conditions)[0];
    }
    if (clientDiscount?.discountData?.percent) {
      return clientDiscount.discountData.percent || 0;
    }
    return 0;
  };

  const nextMonth = moment()
    .startOf('month')
    .add(1, 'month')
    .format('D MMMM');
  const lastDayOfMonth = moment()
    .endOf('month')
    .format('D MMMM');

  const nextDiscountAmount = clientDiscount
    ? Number(discount.conditions[nextDiscountPercent]) -
      (discount?.discountData?.nextDiscountProgress?.progressValue || 0)
    : Number(discount.conditions[nextDiscountPercent]);

  const Conditions = () => {
    return (
      <>
        <TextSubTitle color={'inherit'} style={{ marginTop: 0 }}>
          <FormattedMessage {...messages.tooltipTitle} values={{ nextMonth, guaranteedDiscountPercent }} />
        </TextSubTitle>
        <br />
        <TextBody2 className={classes.tooltipText}>
          <FormattedMessage
            {...messages.tooltipText}
            values={{
              nextDiscountPercent,
              nextDiscountAmount,
              lastDayOfMonth
            }}
          />
        </TextBody2>
      </>
    );
  };

  const percentLevel = getPercent();

  return (
    <Grid item xs={12} sm={6} className={classes.cardPaper} {...props}>
      <NavLink className={classes.CardLink} to={link}>
        <FlrCard hoverShadow zeroPadding className={classes.cardLoyalty}>
          <img className={classes.cardLoyaltyImage} src={imageUrl} alt={''} />
          {discountType !== 'static' && discountType !== 'website' ? (
            <Box className={classes.cardLoyaltyTooltip}>
              <Tooltip
                placement="top"
                icon={<Icon type={'info'} size={24} offset={8} opacity={1} />}
                title={<Conditions />}
                classes={{ tooltip: classes.tooltipClass }}
              >
                <span>
                  <Icon type={'info'} size={32} opacity={1} className={classes.cardLoyaltyTooltipIcon} />
                </span>
              </Tooltip>
            </Box>
          ) : null}
          <Grid
            className={classes.cardLoyaltyContent}
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            wrap="nowrap"
          >
            <Grid>
              <TextSubTitle>{name}</TextSubTitle>
            </Grid>
            <Grid>
              {!isLoading && (
                <TitleH1 component="span" className={percentLevel === 0 ? classes.percentLevel : null}>
                  {percentLevel}%
                </TitleH1>
              )}
            </Grid>
          </Grid>
        </FlrCard>
      </NavLink>
    </Grid>
  );
};

export default DashboardLoyaltyCard;
