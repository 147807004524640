import React from "react";
import clsx from "clsx";
import { Link as ScrollLink } from "react-scroll";

import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";

import { Order, ORDER_STATUS_PENDING_APPROVAL } from "models";
import { TextBody2, TextFounds, TextSubTitle } from "components/shared/text";
import { SplitedPrice } from "components/prices";
import { defaultMaterialTheme } from "utils/styled";

import styles from "../styles";
import messages from "translations/checkout/common";
import { UAH } from "shared/constants";

interface IProps {
  allOrdersCount: number;
  order: Order;
  index: number;
  setActiveElement?: (index: number) => void;
  anchor: string;
  anchorOffset?: number;
  classes: any;
}

const CheckoutRightSideItem: React.FC<IProps> = ({
  allOrdersCount,
  order,
  index,
  anchor,
  anchorOffset = 0,
  setActiveElement,
  classes
}) => {
  const isLast = allOrdersCount === index;
  const isFirst = index === 1;
  const isFinalized = order.orderStatus === ORDER_STATUS_PENDING_APPROVAL;
  const onSetActive = () => setActiveElement && setActiveElement(index)
  return (
    <ScrollLink
      onSetActive={onSetActive}
      key={order.id}
      className={classes.rightSideItemAnchor}
      to={`${anchor}-${order.id}`}
      containerId={`${anchor}-container-inner`}
      activeClass="active"
      spy={true}
      smooth={true}
      isDynamic={true}
      duration={defaultMaterialTheme.transitions.duration.shorter}
      offset={anchorOffset}
    >
      <Grid
        container
        spacing={1}
        className={clsx(classes.rightSideItem, {
          last: isLast,
          first: isFirst
        })}
      >
        <Grid item container onClick={() => setActiveElement && setActiveElement(index)}>
          <Grid item container>
            <Grid item sm>
              <TextSubTitle style={{ margin: 0 }}>{order.outlet && order.outlet.name}</TextSubTitle>
            </Grid>
            <Grid item className={clsx({ [classes.fundsPositive]: isFinalized })}>
              <SplitedPrice value={order.totalQty} bold={false} fontSize={"body2"} hideDecimal={true} postfix={"шт"} />
            </Grid>
          </Grid>
          <Grid item container justifyContent={"flex-end"} alignItems={"flex-end"}>
            <Grid item sm>
              <TextBody2>
                {messages.delivery.defaultMessage} - {index}
              </TextBody2>
            </Grid>
            <Grid item className={classes.gray}>
              {!isFinalized ? (
                <SplitedPrice
                  value={order.totalSum}
                  fontColor={"textSecondary"}
                  fontSize={"h3"}
                  fontSizeDecimal={"h5"}
                  postfix={UAH}
                />
              ) : (
                <TextFounds className={classes.fundsPositive}>Оплачено</TextFounds>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ScrollLink>
  );
};

export default withStyles<any>(styles)(CheckoutRightSideItem);
