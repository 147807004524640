import { breadcrumbsHeight, devicePanelHeight, mobileHeaderHeight } from 'shared/constants';
import { scrollBarMaterial } from '../../styles/mixins';

const styles = (theme: any) => ({
  title: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(0.5)
    }
  },
  warehouse: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      fontSize: 14,
      lineHeight: 1
    }
  },
  delivery: {
    justifyContent: 'flex-end',

    '& .flr-icon': {
      color: theme.palette.common.helperBlack
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(2)
    }
  },

  mobileRoot: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }
  },
  checkoutHeader: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      top: 0,
      height: devicePanelHeight,
      '& > *': { height: devicePanelHeight }
    }
  },
  checkoutFooter: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: 0,
      height: devicePanelHeight,
      '& > *': { height: devicePanelHeight }
    }
  },
  checkoutBreadcrumbs: {
    margin: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      position: 'fixed',
      top: mobileHeaderHeight,
      background: theme.palette.common.cardBlackBackgroundHex,
      zIndex: 2
    }
  },

  mobileFooterContent: {
    display: 'flex',
    alignItems: 'center'
  },
  mobileTotalLabel: {
    marginRight: theme.spacing(1)
  },
  checkoutMain: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    ...scrollBarMaterial,
    overflowY: 'initial',
    [theme.breakpoints.down('md')]: {
      '&&': {
        background: theme.palette.common.cardBlackBackgroundHex,
        marginBottom: devicePanelHeight,
        // remove 1px because of 50.29px breadcrumbs
        marginTop: mobileHeaderHeight + 34,
        paddingTop: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    }
  },
  fundsPositive: {
    color: theme.palette.common.primaryAccentText
  },
  gray: {
    color: theme.palette.text.secondary,
    '&:hover': {
      cursor: 'default',
      color: theme.palette.text.secondary
    }
  },
  black: {
    color: theme.palette.common.black
  },
  checkoutRightPanel: {
    paddingTop: theme.spacing(3)
  },
  checkoutOrdersSummaryCard: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  },
  dividerFullWidth: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    width: `calc(100% + calc(${theme.spacing(2)} * 2))`,
    maxWidth: 'none'
  },
  rightSideItemAnchor: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'block',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer'
    },
    '&.active + a': {
      borderTopColor: 'transparent'
    },
    '&.active': {
      margin: `0 -${theme.spacing(3)}`,
      width: `calc(100% + calc(${theme.spacing(3)} * 2))`,
      background: theme.palette.common.white,
      boxShadow: theme.shadow.cardSelected.boxShadow,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      padding: `0 ${theme.spacing(2.25)} !important`,
      '&.last': {
        marginBottom: 0
      }
    }
  },
  rightSideItem: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75)
  },
  clickable: {
    cursor: 'pointer'
  }
});

export default styles;
