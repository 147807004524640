import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import { IDiscountCondition } from 'models';
import { baseUrl } from 'shared/constants';

import messages from 'translations/account/dashboard';
import { TitleH1, TextSubTitle } from 'components/shared/text';
import { getClientDiscounts } from 'store/discount-conditions/selectors';
import Icon from 'components/shared/Icon';
import DashboardLoyaltyCard from './DashboardLoyaltyCard';
import useStyles from './DashboardLoyaltyStyles';

interface IProps {
  discountConditions?: IDiscountCondition[] | undefined;
  hideTitle?: boolean;
  hideProfileDiscount?: boolean;
  smallCards?: boolean;
}

const DashboardLoyalty: React.FC<IProps> = ({ discountConditions, hideTitle, smallCards }) => {
  const classes = useStyles();
  const clientDiscounts = useSelector(getClientDiscounts);

  return (
    <Grid container item spacing={1} direction={'column'}>
      {!hideTitle && (
        <Grid item>
          <div className={classes.title}>
            <Icon className={classes.titleIcon} type={'loyalty'} opacity={1} />
            <TitleH1 className={classes.titleLoyalty}>{messages.titleLoyalty.defaultMessage}</TitleH1>
            <TextSubTitle className={classes.subTitleLoyalty}>{messages.subTitleLoyalty.defaultMessage}</TextSubTitle>
          </div>
        </Grid>
      )}
      <Grid item container spacing={2} direction={'row'}>
        {discountConditions && discountConditions.length
          ? discountConditions.map(item => (
              <DashboardLoyaltyCard
                key={item.name}
                classes={classes}
                link={`${baseUrl}/account/loyalty/${item.id}`}
                discount={item}
                clientDiscount={
                  clientDiscounts && clientDiscounts.length
                    ? clientDiscounts.find(({ discountRule }) => item.id === discountRule)
                    : undefined
                }
                {...(smallCards ? { sm: 3 } : {})}
              />
            ))
          : null}
      </Grid>
    </Grid>
  );
};

export default DashboardLoyalty;
