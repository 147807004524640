import { FC } from 'react';
import { withStyles } from '@mui/styles';

import DeliveryCard from 'components/cards/offer/DeliveryCard';
import { MarginedContainer } from 'components/layout/content/ContentWrapper';
import { IOfferDiscountDetails, Offer } from 'models';

import styles from './styles';

interface IProps {
  classes: any;
  discount: number;
  discountDetails: IOfferDiscountDetails[];
  offer: Offer;
  onPurchase: (offer: Offer, qty: number) => void;
}

const MiniDetailsOffer: FC<IProps> = ({ onPurchase, classes, offer, discount, discountDetails }) => {
  return (
    <MarginedContainer className={classes.offer} side={['x', 'y']} padding={'cardInner'}>
      <DeliveryCard offer={offer} discount={discount} discountDetails={discountDetails} onPurchase={onPurchase} />
    </MarginedContainer>
  );
};

export default withStyles<any>(styles)(MiniDetailsOffer);
