import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlrTextArea from 'components/shared/form-elements/textfield/FlrTextarea';
import { getCheckoutOrders } from 'store/checkout/selectors';
import { updateOrderItem } from 'store/checkout/actions';

const OrderComment: React.FC<{ index: number }> = ({ index }) => {
  const dispatch = useDispatch();
  const orders = useSelector(getCheckoutOrders);
  const order = orders[index];

  const onCommentChange = (e: any) => {
    order.notes = e.target.value;
    dispatch(updateOrderItem(order));
  };

  return (
    <FlrTextArea
      style={{ marginTop: 5, resize: 'none', width: '100%' }}
      value={order.notes}
      onChange={onCommentChange}
      placeholder={`Коментар до замовлення`}
    />
  );
};

export default OrderComment;
