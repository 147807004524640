import { ITheme, defaultMaterialTheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  paper: {
    height: '100%',
    padding: defaultMaterialTheme.spacing(2),
    textAlign: 'center',
    color: defaultMaterialTheme.palette.text.secondary
  },
  paperCardFullHeight: {
    height: '100%'
  },
  paperCard: {
    position: 'relative',
    minHeight: 160,
    padding: defaultMaterialTheme.spacing(3),
    paddingBottom: defaultMaterialTheme.spacing(4),
    textAlign: 'center',
    color: defaultMaterialTheme.palette.text.secondary
  },
  paperBottomButton: {
    position: 'absolute',
    bottom: defaultMaterialTheme.spacing(2),
    right: defaultMaterialTheme.spacing(2)
  },
  cardContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  cardIcon: {
    color: theme.palette.common.helperBlack
  },
  cardBalanceBody: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  dialogTitle: {
    marginTop: 0,
    marginBottom: defaultMaterialTheme.spacing(2),
    textAlign: 'center'
  },
  dialogText: {
    marginTop: 0,
    marginBottom: defaultMaterialTheme.spacing(6),
    textAlign: 'center'
  }
});

export default styles;
