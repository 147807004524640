import { ITheme, defaultMaterialTheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  root: {
    flex: 1,
    background: defaultMaterialTheme.palette.background.default
  },
  deliveryContainer: {
    marginBottom: theme.spacing(10),
    [(theme as any).breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4)
    }
  },
  financesSection: {
    [(theme as any).breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4)
    }
  },
  financesCard: {
    [(theme as any).breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3)
    }
  },
  titleDelivery: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  title: {
    width: '100%',
    position: 'relative',
    paddingLeft: 48
  },
  titleIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    color: theme.palette.common.black
  },
  titleCommon: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
    color: theme.palette.common.black
  },
  titleLoyalty: {
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.common.black
  },
  subTitleLoyalty: {
    marginTop: 0,
    marginBottom: theme.spacing(3)
  },
  cardPaper: {
    [(theme as any).breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2)
    }
  },
  CardLink: {
    textDecoration: 'none',
    '&:hover, &:active': {
      textDecoration: 'none'
    }
  },
  cardLoyalty: {
    position: 'relative',
    overflow: 'hidden'
  },
  // TODO comment not needed
  // cardLoyaltyTooltip: {
  //   position: "absolute",
  //   top: theme.spacing(1),
  //   right: theme.spacing(2),

  //   padding: theme.spacing(0.5),

  //   color: theme.palette.common.white,
  //   backgroundColor: theme.palette.common.helperBlack,
  //   borderRadius: "50%"
  // },
  cardLoyaltyContent: {
    padding: theme.spacing(2),
    paddingTop: 12
  },
  cardLoyaltyImage: {
    width: '100%',
    height: 112,
    objectFit: 'cover'
  },
  cardDeliveryIcon: {
    color: theme.palette.text.secondary
  },
  cardDeliveryLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
});

export default styles;
