import { ITheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  sectionSpacing: {
    [(theme as any).breakpoints.down('xl')]: {
      maxWidth: `calc(100% - 100px)`
    },
    [(theme as any).breakpoints.down('lg')]: {
      maxWidth: `100%`
    }
  },
  wrapper: {
    marginTop: 16,
    flex: 1,
    paddingRight: 4,
    maxWidth: 1432,
    paddingLeft: 4,
    [(theme as any).breakpoints.down('xl')]: {
      maxWidth: '100%'
    },
    [(theme as any).breakpoints.down('lg')]: {
      paddingLeft: 24
    }
  },
  cartMain: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  cartLeftSide: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    color: theme.palette.common.helperBlack
  },
  cartLeftSideHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '&:first-of-type': {
      marginTop: 0
    }
  },
  cartLeftSideSubheader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: 'inherit',
    '&:first-of-type': {
      marginTop: 0
    }
  },
  noVerticalMargin: {
    marginBottom: 0,
    marginTop: 0
  },
  timerBlock: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.info.main,
    '&.expired': {
      color: theme.palette.error.main
    }
  },
  leftSideItemAnchor: {
    paddingLeft: theme.spacing(2),
    display: 'block',
    position: 'relative',
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '&::before': {
      content: "''",
      position: 'absolute',
      display: 'block',
      borderRadius: 2,
      width: 4,
      left: 0,
      top: 0,
      bottom: 0,
      background: 'transparent'
    },
    '&:hover': {
      cursor: 'pointer',
      '&:not(.active)::before': {
        background: theme.palette.divider
      }
    },
    '&.active': {
      '& .warehouse-name': {
        color: theme.palette.common.black
      },
      '&::before': {
        background: theme.palette.primary.main
      },
      '&:active': {
        '&::before': {
          background: theme.palette.primary.light
        }
      }
    }
  },
  deviceTimer: {
    marginBottom: theme.spacing(1)
  },
  cartBlockHeader: {
    maxWidth: 1136,
    '@media (max-width: 1500px)': {
      maxWidth: '1096px'
    }
  },
  mobileWrapper: {
    padding: '80px 15px 54px',
    width: '100%'
  },
  favouriteWrapper: {
    top: 55
  }
});

export default styles;
