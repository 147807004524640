import { countriesMap } from 'shared/countriesMap';

export const miniCartProcductAttributes = ['height', 'weight'];
// “Висота” - “Дiаметр” - “Вага” - “Довжина” - “Виробник” - “Якiсть” - "Колир" - “Зрiлiсть”

export const searchProductAttributes = ['height', 'size', 'weight', 'manufacturer', 'quality', 'color', 'maturity'];

export type IProductAlphabet = IProductAlphaLetter[];

export interface IProductAlphaLetter extends ISortableItem {
  startsAt: number;
  endsAt: number;
  enabled: boolean;
  selected: boolean;
}

export const ProductAlphabetStringRU = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя';
export const ProductAlphabetStringUA = 'абвгґдеєжзиіїйклмнопрстуфхцчшщьюя';

export const ProductAlphabet = {
  ru: ProductAlphabetStringRU.split(''),
  ua: ProductAlphabetStringUA.split('')
};

function getTransit(product: any): boolean {
  if (product.hasTransit) {
    return product.hasTransit;
  }
  if (typeof product.hasTransitBySupplier === 'object') {
    return Object.values(product.hasTransitBySupplier).some((i) => i);
  }
  return product.hasTransitBySupplier;
}

export interface IProductStock {
  prices: number[];
  stock: number;
  warehouse: string;
  warehouseId: string;
}

export interface IAttribute {
  code: string;
  name: string;
  value: string;
  alias: string;
  format?: string | null;
  displayValue?: string | null;
  primary?: boolean;
}

export const ProductAttributesDictionary = {
  height: '000000001',
  quality: '000000017',
  maturity: '000000016',
  size: '000000003',
  manufacturer: '000000018',
  weight: '000000015',
  color: '000000021',
  country: '000000019',
  packing: '000000020',
  diameter: '000000003',
  shape: '000000039'
};

export const attributesToFilter = [
  ProductAttributesDictionary.country,
  ProductAttributesDictionary.color,
  ProductAttributesDictionary.manufacturer
];

export interface IProductAttributesDictionary {
  height: string;
  quality: string;
  maturity: string;
  size: string;
  manufacturer: string;
  weight: string;
  color: string;
  country: string;
  packing: string;
}

export type IProductAttributes = Record<keyof IProductAttributesDictionary, IAttribute>;

export interface ISortableItem {
  name: string;
  fullName?: string;
  price: number;
  height: number;
  preOrderPrice?: number;
}

export interface IDiscountDetails {
  description: string;
  image: string;
  name: string;
}

export interface IDaysData {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface IPreOrderShippingData {
  active: boolean;
  country: string;
  dayMargin: number;
  id: string;
  manufacturer: string;
  days: IDaysData;
}

export class Product implements ISortableItem {
  public id: string;
  public code: string;
  public displayCode: string;
  public hasPreOrder: boolean;
  public preOrderPrice: number;
  public preOrderPriceDate?: string;
  public preOrderShippingData: IPreOrderShippingData;
  public priceRange: number[];
  public priceRangeEquals: boolean;
  public discount: number;
  public discountDetails: IDiscountDetails;
  public fullName: string;
  public displayName: string;
  public name: string;
  public images: string[];
  public attributes: IProductAttributes;
  public attributesList: IAttribute[];
  public inStock: boolean;
  public archived: boolean;
  public offers: any[];
  public offersCount: number;
  public specialOffer?: any;
  public specialPrice?: number;
  public hasTransit?: boolean;

  public catalogCategory: string;
  public catalogCategoryCode: string;
  public productType: string;
  public productTypeCode: string;
  public productGroup: string;
  public productGroupCode: string;
  public unit: string;
  public unitCode: string;

  public alias: string;
  public seoKey: string;
  // favourite
  public watch: boolean = false;

  // used in product quantity select
  public inPackageCount: number = 1;

  // temp
  public supplier?: string;
  public popularityRate?: number = 0;

  constructor(product: any) {
    this.id = product.id || product.productId;
    this.code = product.code;
    this.displayCode = product.displayCode;
    this.hasPreOrder = product.hasPreOrder;
    this.specialPrice = product.specialPrice;
    this.hasTransit = getTransit(product);
    this.preOrderPrice = product.preOrderPrice;
    this.preOrderPriceDate = product.preOrderPriceDate;
    this.preOrderShippingData = product.preOrderShippingData;
    this.priceRange = product.priceRange;
    this.priceRangeEquals = this.priceRange && this.priceRange[0] === this.priceRange[1];
    this.discount = product.discount;
    this.discountDetails = product.discountDetails;
    this.name = product.name;
    this.displayName = product.displayName;
    this.fullName = product.fullName;
    this.images = product.images;
    this.attributes = typeof product.attributes === 'object' ? product.attributes : {};
    this.attributesList = Object.keys(this.attributes)
      .map((key) => this.attributes[key])
      .filter(({ code, primary }) => !attributesToFilter.includes(code) && Boolean(primary))
      .splice(0, 3);
    this.inStock = !!product.inStock;
    this.archived = !!product.archived;
    this.offers = product.offers || [];
    this.offersCount = (product.offers && product.offers.length) || null;
    this.catalogCategory = product.catalogCategory || 'Категорія';
    this.catalogCategoryCode = product.catalogCategoryCode;
    this.productGroup = product.productGroup;
    this.productGroupCode = product.productGroupCode;
    this.productType = product.productType;
    this.productTypeCode = product.productTypeCode;
    this.unit = product.unit;
    this.unitCode = product.unitCode;
    this.alias = product.alias;
    this.seoKey = product.seoKey;
    this.watch = product.watch;
    this.inPackageCount = product.inPackageCount;
    this.popularityRate = product.popularityRate;

    if (this.attributes.country) {
      const displayValue: IAttribute = countriesMap[this.attributes.country.value];
      this.attributes.country.displayValue = displayValue ? displayValue.name : null;
    }
  }

  public get minPrice(): number {
    if (this.specialPrice) {
      return this.specialPrice;
    }

    if (!this.priceRange) {
      return 0;
    }
    return Math.round(Math.min(...this.priceRange) * (1 - this.discount / 100) * 100) / 100;
  }

  public get maxPrice(): number {
    if (!this.priceRange) {
      return 0;
    }
    return Math.round(Math.max(...this.priceRange) * (1 - this.discount / 100) * 100) / 100;
  }

  public get preOrderPriceWithDiscount(): number {
    return Math.round(this.preOrderPrice * (1 - this.discount / 100) * 100) / 100;
  }

  public get price(): number {
    return this.minPrice;
  }

  public get height(): number {
    if (!this.attributes.height) {
      return 0;
    }
    return parseInt(this.attributes.height.value, 10);
  }

  // spike for now
  public get category(): IAttribute {
    return {
      name: this.catalogCategory,
      value: this.catalogCategoryCode,
      code: this.catalogCategoryCode,
      alias: this.alias
    };
  }

  public get group(): IAttribute {
    return {
      name: this.productGroup,
      value: this.productGroupCode,
      code: this.productGroupCode,
      alias: this.alias
    };
  }

  public getAttribute(key: string): IAttribute {
    if (this.attributes[key]) {
      return this.attributes[key];
    }
    return {} as IAttribute;
  }
}
