import React from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Element as ScrollBlock } from 'react-scroll';

import { Cart, CartItem, Order, ORDER_TYPE_PREORDER, OrderItem, Product } from 'models';
import { ORDER_STATUS_NEW } from 'models/order';

import { TitleH2, TextCaption } from 'components/shared/text';
import FlrCard from 'components/shared/card/FlrCard';
import Alert from 'components/shared/Alert/Alert';

import OrderCardDelivery from '../OrderCardDelivery';
import OrderCardSubtotal from '../OrderCardSubtotal';
import OrderCardItemDevice from './OrderCardItem';

import styles from '../styles';
import messages from 'translations/checkout/common';

interface IProps {
  checkoutGroup: string;
  cart: Cart;
  order: Order;
  orderIndex: number;
  orderIndexShift: number;
  anchor: string;
  selectedOrderItemId: string;
  onQtyUpdate: (item: OrderItem, newQty: number) => void;
  onDeliveryUpdate: (item: Order) => void;
  onRemoveItem: (item: OrderItem) => void;
  onChangeItemAmount: (item: OrderItem) => void;
  onCancel: () => void;
  onMoreInfo: (product: Product) => void;
  onSelectOrderItem: (itemId: string) => void;
  onUnselectOrderItem: () => void;
  onSubmit: (item: Order) => void;
  classes: any;
  submitted: boolean;
}

const OrderCardDevice: React.FC<IProps> = ({
  checkoutGroup,
  cart,
  order,
  orderIndex,
  orderIndexShift,
  anchor,
  selectedOrderItemId,
  classes,
  onRemoveItem,
  onChangeItemAmount,
  onSubmit,
  onCancel,
  onMoreInfo,
  onSelectOrderItem,
  onUnselectOrderItem,
  onQtyUpdate,
  onDeliveryUpdate,
  submitted
}) => {
  const isPreOrder = order.orderType === ORDER_TYPE_PREORDER;
  const isNewOrder = order.orderStatus === ORDER_STATUS_NEW;
  const disableRemove = order.items.length === 1; // order.totalQty > 1 && order.items.length > 1);
  const getGroupItemQty = (offerId: string) => {
    const group = cart.groups[checkoutGroup];
    if (group) {
      const item = group.items.find((cartItem: CartItem) => cartItem.offer.id === offerId);
      if (item) {
        return item.qty;
      }
    }
    return 0;
  };

  return (
    <ScrollBlock
      name={`${anchor}-${order.id}`}
      className={clsx(`${anchor}-item`, classes.orderCard, classes.orderCardActual)}
    >
      <FlrCard id={order.id}>
        <Grid item>
          {orderIndex && orderIndex >= 0 && (
            <TextCaption className={classes.gray}>
              {messages.delivery.defaultMessage} &ndash; {orderIndex + orderIndexShift}
            </TextCaption>
          )}
          <TitleH2 className={classes.noMarginTop}>{order.outlet && order.outlet.name}&nbsp;</TitleH2>
        </Grid>
        <OrderCardDelivery order={order} onDeliveryChange={onDeliveryUpdate} submitted={submitted} />
        <Alert closable type={'info'} title={messages.alertSplitOrder.defaultMessage} className={classes.outlineMargin}>
          {messages.alertSplitOrderText.defaultMessage}
        </Alert>
        <Grid container item direction={'column'} spacing={2}>
          {order.items && order.items.length
            ? order.items.map((item: OrderItem, index: number) => (
                <OrderCardItemDevice
                  isPreOrder={isPreOrder}
                  isFirst={index === 0}
                  max={getGroupItemQty(item.offerId)}
                  key={item.id}
                  item={item}
                  removeDisabled={disableRemove}
                  withItemRemove={isNewOrder}
                  selected={item.offerId === selectedOrderItemId}
                  onChangeAmount={(qty: number) => onQtyUpdate(item, qty)}
                  onSelect={() => onSelectOrderItem(item.offerId)}
                  onUnselect={() => onUnselectOrderItem()}
                  onMoreInfo={onMoreInfo}
                  onRemove={() => onRemoveItem(item)}
                  isNotEditable={item.special}
                  withDiscount
                />
              ))
            : null}
          <OrderCardSubtotal
            order={order}
            withItemRemove={isNewOrder}
            index={orderIndex}
            withCommentEdit={true}
            withDiscount
          />
        </Grid>
      </FlrCard>
    </ScrollBlock>
  );
};

export default withStyles<any>(styles)(OrderCardDevice);
