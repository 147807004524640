import React from 'react';
import { Grid } from '@mui/material';
import { PERSONAL_DISCOUNT } from 'models';
import RuleText from './RuleText';

interface IProps {
  type: any;
}

const DiscountRules: React.FC<IProps> = ({ type }) => {
  const keys: string[] =
    type === PERSONAL_DISCOUNT ? ['gettingDiscount', 'losingDiscount'] : ['increasingDiscount', 'decreasingDiscount'];
  return (
    <>
      {keys.map((key, index) => (
        <Grid item sm key={`discount-rule-${index}`}>
          <RuleText messageKey={key} />
        </Grid>
      ))}
    </>
  );
};

export default DiscountRules;
