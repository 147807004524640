import { defineMessages } from "react-intl";

export default defineMessages({
  titleDelivery: {
    id: "account.dashboard.titleDelivery",
    description: "",
    defaultMessage: "Замовлення"
  },
  titleFinance: {
    id: "account.dashboard.titleFinance",
    description: "",
    defaultMessage: "Фінанси"
  },
  titleLoyalty: {
    id: "account.dashboard.titleLoyalty",
    description: "",
    defaultMessage: "Знижки за програмою лояльності"
  },
  subTitleLoyalty: {
    id: "account.dashboard.subTitleLoyalty",
    description: "",
    defaultMessage: "Знижки діють на товари від постачальника Флоротека. Знижки на один товар сумуються"
  },
  personalDiscount: {
    id: "account.dashboard.title",
    description: "",
    defaultMessage: "Всі товари Флоротека"
  },
  tooltipTitle: {
    id: "account.dashboard.tooltipTitle",
    description: "",
    defaultMessage: "Гарантована знижка з {nextMonth} - {guaranteedDiscountPercent}%."
  },
  tooltipText: {
    id: "account.dashboard.tooltipText",
    description: "",
    defaultMessage: "Можливо збільшити знижку до {nextDiscountPercent}%, за умови купівлі ще {nextDiscountAmount} шт товарів з групи до {lastDayOfMonth}"
  }
});
