import React from 'react';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { GridDivider } from 'components/shared/table/dividers';
import { Link3, TextBody2 } from 'components/shared/text';
import messages from 'translations/account/finance';

import styles from './styles';

export const DetailsPanel = ({ classes, data }: any) => {
  const orderId = data.operation && data.operation.on ? data.operation.on.orderId : '';
  const paymentId: string = data.operation && data.operation.on ? data.operation.on.paymentId : '';

  return (
    <Grid item container direction="column" className={classes.expandedMobileContainer}>
      <Grid item container alignItems="flex-start" classes={{ root: classes.expandedMobileRow }}>
        <Grid item xs>
          <TextBody2 color="textSecondary">{messages.transactionNumber.defaultMessage}</TextBody2>
        </Grid>
        <Grid item>
          <TextBody2 align="right">
            {data.operationType === 'debit' ? (
              `№ ${paymentId}`
            ) : (
              <Link3 to={`orders/deliveries/${orderId}`}>№ {orderId}</Link3>
            )}
          </TextBody2>
        </Grid>
      </Grid>
      <Grid item xs>
        <GridDivider />
      </Grid>
      <Grid item container alignItems="flex-start" classes={{ root: classes.expandedMobileRow }}>
        <Grid item xs>
          <TextBody2 color="textSecondary">{messages.date.defaultMessage}</TextBody2>
        </Grid>
        <Grid item xs>
          <TextBody2 color="textSecondary" align="right">
            {data.dateString}
          </TextBody2>
        </Grid>
      </Grid>
      <GridDivider />
    </Grid>
  );
};

export const DetailsExpandPanel = withStyles<any>(styles)(DetailsPanel);
