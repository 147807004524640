import React, { useState } from 'react';
import clsx from 'clsx';
import { connect, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Box, Paper, Button, Hidden } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import { useConfirm } from 'material-ui-confirm';

import { User, Outlet } from 'models';
import { logout } from 'store/auth/actions';
import { removeOutletAsync } from 'store/outlets/actions';
import { IApplicationState } from 'store/reducers';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { updateAccountAsync } from 'store/account/actions';
import { useTrackInitialPageLoad } from 'controllers';

import { TitleH1, TitleH2, Link2, TextBody2, TextSubTitle } from 'components/shared/text';
import FlrDialogTitle from 'components/shared/modals/components/DialogTitle';
import FlrCard from 'components/shared/card/FlrCard';
import OutletsTable from './Table';
import AddForm from 'components/shared/modals/outlet/OutletModal';
import PersonalData from './PersonalData';
import PersonalDataForm from './PersonalDataForm';
import BusinessData from './BusinessData';
import BusinessDataForm from './BusinessDataForm';
import DataCardSkeleton from './DataSkeleton';
import ChangePasswordModal from './ChangePasswordModal';

// import messagesMenu from "translations/account/menu";
import { clearPopovers } from 'utils/helpers';

import messages from 'translations/account/settings';
import styles from './styles';

interface IProps {
  // component own props
  classes: any;
  account: User;
  accountLoadingState: boolean;
}

interface IStateProps {
  // Props passed to the component by `connect`
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
  updateAccount: typeof updateAccountAsync.request;
  removeOutlet: typeof removeOutletAsync.request;
  logout: typeof logout;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const AccountSettings: React.FC<IComponentProps> = ({
  account,
  accountLoadingState,
  updateAccount: updateUser,
  removeOutlet,
  logout: logoutUser,
  classes
}) => {
  const [changePasswordModal, setChangePasswordModal] = React.useState(false);
  const [addOutletModal, setAddOutletModal] = React.useState(false);
  const [modifiedAccount, setAccount] = React.useState(account);
  const [outletToEdit, setOutletToEdit] = React.useState('');
  const [closePopovers, setClosePopovers] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [personalInfoEdit, setPersonalInfoEdit] = useState(false);
  const [businessInfoEdit, setBusinessInfoEdit] = useState(false);

  useTrackInitialPageLoad();

  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const toggleChangePasswordModal = () => {
    setChangePasswordModal(state => !state);
  };
  const toggleAddOutletModal = () => {
    const outletShown = addOutletModal;
    if (outletShown) {
      setOutletToEdit('');
      if (closePopovers) {
        clearPopovers(true);
        setClosePopovers(false);
      }
    }
    setAddOutletModal(!outletShown);
  };

  const toggleEditOutletModal = (id: string, clearAfter?: boolean) => {
    setOutletToEdit(id);
    toggleAddOutletModal();
    setClosePopovers(!!clearAfter);
  };

  const confirmOutletDelete = (outlet: Outlet, clearAfter?: boolean) => {
    confirm({
      confirmationText: formatMessage({ ...messages.deleteBtnLabel }),
      cancellationText: formatMessage({ ...messages.cancelBtnLabel }),
      title: <FlrDialogTitle text={messages.deleteOutletModalTitle} />,
      description: formatMessage({ ...messages.deleteOutletModalText }, { name: outlet.name })
    })
      .then(() => {
        removeOutlet(outlet.id);
        clearPopovers(clearAfter);
      })
      .catch(() => {
        clearPopovers(clearAfter);
      });
  };

  const editPersonalInfo = () => {
    if (businessInfoEdit) {
      setModalOpen(true);
    } else {
      setPersonalInfoEdit(true);
    }
  };
  const closeEditPersonalInfo = () => {
    setPersonalInfoEdit(false);
  };

  const editBusinessInfo = () => {
    if (personalInfoEdit) {
      setModalOpen(true);
    } else {
      setBusinessInfoEdit(true);
    }
  };
  const closeEditBusinessInfo = () => {
    setBusinessInfoEdit(false);
  };

  const handleModalConfirm = () => {
    handleAccountUpdate(modifiedAccount);
    if (personalInfoEdit) {
      closeEditPersonalInfo();
      setBusinessInfoEdit(true);
    } else {
      closeEditBusinessInfo();
      setPersonalInfoEdit(true);
    }
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleAccountUpdate = (updatedAccount: User) => {
    updateUser(updatedAccount);
  };

  return (
    <Box className={clsx(classes.root, 'actual-content')}>
      {changePasswordModal && (
        <ChangePasswordModal
          logoutUser={logoutUser}
          account={account}
          modalOpen={changePasswordModal}
          handleCancel={toggleChangePasswordModal}
        />
      )}
      <Box className={classes.topBar}>
        <TitleH1 className={classes.title}>{messages.title.defaultMessage}</TitleH1>
        <Hidden smDown>
          <Button onClick={toggleChangePasswordModal} variant={'outlined'} color={'primary'}>
            {messages.changePassword.defaultMessage}
          </Button>
        </Hidden>
      </Box>

      <Grid container spacing={3} className={classes.settingsContainer}>
        <Grid item xs={12} md={6} style={{ height: '100%' }}>
          <FlrCard className={classes.paper}>
            <Grid container spacing={2}>
              <Grid xs={12} item className={classes.titleGroupContainer}>
                <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item>
                    <TitleH2 align={'left'} className={classes.titleGroup}>
                      {messages.personalData.defaultMessage}
                    </TitleH2>
                  </Grid>
                  <Grid item xs={'auto'}>
                    <Hidden smDown>
                      <Button
                        color={'primary'}
                        onClick={editPersonalInfo}
                        className={clsx({
                          [classes.hiddenButton]: personalInfoEdit
                        })}
                      >
                        {messages.editButton.defaultMessage}
                      </Button>
                    </Hidden>
                    <Hidden smUp>
                      <Link2
                        onClick={editPersonalInfo}
                        className={clsx({
                          [classes.hiddenButton]: personalInfoEdit
                        })}
                      >
                        {messages.editButton.defaultMessage}
                      </Link2>
                    </Hidden>
                  </Grid>
                </Grid>
                <TextSubTitle align={'left'} className={classes.subTitleGroup}>
                  {messages.personalDataSubtitle.defaultMessage}
                </TextSubTitle>
                <TextBody2 align={'left'} className={classes.subTitleGroupCaption}>
                  {messages.personalDataSubtitleCaption.defaultMessage}
                </TextBody2>
              </Grid>

              {!accountLoadingState && account ? (
                personalInfoEdit ? (
                  <PersonalDataForm
                    onCancel={closeEditPersonalInfo}
                    onSave={handleAccountUpdate}
                    onChange={setAccount}
                    account={account}
                  />
                ) : (
                  <PersonalData
                    toggleChangePasswordModal={toggleChangePasswordModal}
                    account={account}
                    onEditClick={editPersonalInfo}
                  />
                )
              ) : (
                <DataCardSkeleton columns={2} />
              )}
            </Grid>
          </FlrCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                  <TitleH2 align={'left'} className={classes.titleGroup}>
                    {messages.businessData.defaultMessage}
                  </TitleH2>
                </Grid>
                <Grid item xs={'auto'}>
                  <Hidden smDown>
                    <Button
                      color={'primary'}
                      onClick={editBusinessInfo}
                      className={clsx({
                        [classes.hiddenButton]: businessInfoEdit
                      })}
                    >
                      {messages.editButton.defaultMessage}
                    </Button>
                  </Hidden>
                  <Hidden smUp>
                    <Link2
                      onClick={editBusinessInfo}
                      className={clsx({
                        [classes.hiddenButton]: businessInfoEdit
                      })}
                    >
                      {messages.editButton.defaultMessage}
                    </Link2>
                  </Hidden>
                </Grid>
              </Grid>
              {!accountLoadingState && account ? (
                businessInfoEdit ? (
                  <BusinessDataForm
                    onCancel={closeEditBusinessInfo}
                    onSave={handleAccountUpdate}
                    onChange={setAccount}
                    account={account}
                  />
                ) : (
                  <BusinessData account={account} />
                )
              ) : (
                <DataCardSkeleton />
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FlrCard>
          <OutletsTable
            toggleAddOutletModal={toggleAddOutletModal}
            toggleEditOutletModal={toggleEditOutletModal}
            confirmDeleteOutlet={confirmOutletDelete}
          />
          <AddForm
            closePopOvers={closePopovers}
            toggle={toggleAddOutletModal}
            id={outletToEdit}
            open={addOutletModal}
          />
        </FlrCard>
      </Grid>
      <Dialog
        open={modalOpen}
        onClose={handleCancel}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{messages.saveChangesConfirmTitle.defaultMessage}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <FormattedMessage
              {...messages.saveChangesConfirmText}
              values={{
                dataType: personalInfoEdit ? messages.personalData.defaultMessage : messages.businessData.defaultMessage
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={handleCancel} color="primary">
            {messages.saveChangesConfirmContinue.defaultMessage}
          </Button>
          <Button onClick={handleModalConfirm} color="primary">
            {messages.saveBtnLabel.defaultMessage}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps: MapStateToProps<{}, {}, IApplicationState> = (state: IApplicationState) => ({
  account: getUserAccount(state),
  accountLoadingState: getAccountLoadingState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      updateAccount: updateAccountAsync.request,
      removeOutlet: removeOutletAsync.request,
      logout
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(AccountSettings as any));
