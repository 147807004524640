import React from 'react';
import { Grid } from '@mui/material';

import { OrderItem, Product } from 'models';
import { UAH } from 'shared/constants';
import { GTM } from 'controllers';

import Icon from 'components/shared/Icon';
import { SplitedPrice } from 'components/prices';
import ProductDetails from 'components/cards/shared/ProductDetails';
import FlrQuantity from 'components/shared/form-elements/quantity/FlrQuantity';
import ProductDetailsInCart from 'components/shared/product/product-details/ProductDetailsInCart';
import { Link2, Link3, TextBody2 } from 'components/shared/text';
import { GridDivider, GridDividerBold } from 'components/shared/table/dividers';

import withStyles from './styles';
import messages from 'translations/cart/common';
import clsx from 'clsx';

interface IProps {
  removeDisabled?: boolean;
  max?: number;
  item: OrderItem;
  onChangeAmount?: (qty: number) => void;
  onClose: () => void;
  onMoreInfo: (product: Product) => void;
  onRemove?: () => void;
  withDiscount?: boolean;
  withItemRemove?: boolean;
  isNotEditable?: boolean;
  isPreOrder?: boolean;
  classes: any;
}

const OrderItemDataDevice: React.FC<IProps> = ({
  max,
  item,
  onChangeAmount,
  onRemove,
  onClose,
  onMoreInfo,
  withDiscount,
  isNotEditable,
  isPreOrder,
  removeDisabled,
  withItemRemove,
  classes
}) => {
  const removeHandle = () => {
    if (!removeDisabled && onRemove) {
      GTM.trackNullifyItemFromOrderList(item);
      onRemove();
    }
  };

  const isSpecialOrder = item.special;

  return (
    <Grid item container sm direction={'column'} spacing={2}>
      <Grid item xs>
        <Link2 onClick={() => onMoreInfo(item.product)}>Більше про товар</Link2>
      </Grid>
      {!isNotEditable && (
        <Grid item xs>
          <ProductDetailsInCart qty={item.qty} unit={item.product.unit} />
        </Grid>
      )}
      <Grid item container xs className={classes.params}>
        <Grid item xs className={classes.paramLabel}>
          Вид
        </Grid>
        <Grid item xs={'auto'} className={classes.paramValue}>
          {item.product.catalogCategory}
        </Grid>
      </Grid>
      <GridDivider />
      <Grid item container xs className={classes.params} flexWrap="nowrap" justifyContent="space-between">
        <Grid item xs className={classes.paramLabel}>
          Хар.
        </Grid>
        <Grid item xs={'auto'} className={classes.paramValue} style={{ width: '80%', marginRight: -10 }}>
          <ProductDetails attrsOnly product={item.product} attrsListStyle={{ justifyContent: 'end' }} />
        </Grid>
      </Grid>
      <GridDivider />
      <Grid item container xs className={classes.params}>
        <Grid item xs className={classes.paramLabel}>
          Ціна за шт.
        </Grid>
        <Grid item xs={'auto'} className={classes.paramValue} container style={{ width: 'auto' }}>
          {isSpecialOrder ? <Icon opacity={1} type="fire" size={20} style={{ marginRight: 6 }} /> : null}
          <SplitedPrice
            value={item.price}
            fontColor={'textSecondary'}
            fontSize={'h3'}
            fontSizeDecimal={'h5'}
            postfix={UAH}
            prefix={isPreOrder ? '~' : ''}
          />
        </Grid>
      </Grid>
      <GridDivider />
      <Grid item xs container className={classes.params} alignItems={'center'}>
        <Grid item xs>
          {isNotEditable ? (
            <FlrQuantity value={item.qty} disableMaxLimit={isNotEditable} disabled />
          ) : (
            <FlrQuantity
              value={item.qty}
              inPackageCount={item.product.inPackageCount}
              min={item.qty === item.product.inPackageCount ? 0 : item.product.inPackageCount}
              onDecrement={item.qty === item.product.inPackageCount ? onRemove : undefined}
              max={max || item.qty}
              onChange={onChangeAmount}
              disableMaxLimit={!isPreOrder}
            />
          )}
        </Grid>
        {!!withItemRemove && (
          <Grid item xs={'auto'} onClick={removeHandle}>
            <Link3 className={clsx(classes.orderItemRemove, removeDisabled ? classes.disabled : null)}>Обнулити</Link3>
          </Grid>
        )}
      </Grid>
      {withDiscount && (
        <React.Fragment>
          <GridDivider />
          <Grid item container xs className={classes.params}>
            <Grid item xs className={classes.paramLabel}>
              {messages.totalDiscount.defaultMessage}
            </Grid>
            <Grid item xs={'auto'} className={classes.paramValue}>
              {item.discount + item.discountAuto}&nbsp;%
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <GridDividerBold />
      <Grid item container xs className={classes.params}>
        <Grid item xs className={classes.paramLabel}>
          {messages.total.defaultMessage}
        </Grid>
        <Grid item xs={'auto'} className={classes.paramValue}>
          <SplitedPrice
            value={item.total}
            fontColor={isNotEditable ? 'helperBlack' : 'textSecondary'}
            fontSize={'h3'}
            fontSizeDecimal={'h5'}
            postfix={UAH}
            prefix={isPreOrder ? '~' : ''}
          />
        </Grid>
      </Grid>
      <GridDividerBold />
      <Grid item className={classes.params}>
        <TextBody2 align={'center'}>
          <Link2 onClick={() => onClose()}>Згорнути</Link2>
        </TextBody2>
      </Grid>
    </Grid>
  );
};

export default withStyles(OrderItemDataDevice);
