import React from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, MapStateToProps } from 'react-redux';
import { useSnackbar } from 'notistack';
// import Chart from 'react-apexcharts';
// import { useIntl } from 'react-intl';
import moment from 'moment';

import { Grid, Button, CircularProgress, useMediaQuery, Theme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withStyles, useTheme } from '@mui/styles';

import { User } from 'models';
import messages from 'translations/account/finance';
import { SplitedPrice } from 'components/prices';
import { AuthService } from 'store/auth/service';
import { fetchAccountAsync } from 'store/account/actions';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { IApplicationState } from 'store/reducers';

import { FlrButtonKhaki } from 'components/shared/buttons';
import { FlrInput } from 'components/shared/form-elements/textfield';
import FlrCheckboxWithLabel from 'components/shared/form-elements/FlrCheckbox';
import { TitleH1, Link2, TextBody2, TextSubTitle } from 'components/shared/text';
import SnackbarContent from 'components/layout/notistack-provider/SnackbarWithTitle';
import Icon from 'components/shared/Icon';
import FlrCard from 'components/shared/card/FlrCard';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import DataCardSkeleton from 'pages/account/finance/CardSkeleton';
// import { GridDivider } from 'components/shared/table/dividers';
// import { getDonutChartOptions } from 'pages/account/loyalty/cards/chart-configs';
// import { formattedStringPrice } from 'utils/helpers';
// import { defaultMaterialTheme } from 'utils/styled';
// import { Circle } from 'styles/components/circle';

import styles from 'pages/account/finance/styles';
import env from 'environment';
import { UAH } from 'shared/constants';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  classes: any;
  fullHeight?: boolean;
}

interface IStateProps {
  account: User | null;
  accountLoadingState: boolean;
}

interface IDispatchProps {
  loadUser: typeof fetchAccountAsync.request;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const CardCreditLine: React.FC<IComponentProps> = ({
  classes,
  account,
  fullHeight = true,
  accountLoadingState,
  loadUser,
  ...rest
}) => {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleCancel = () => {
    setModalOpen(false);
  };
  const handleModalConfirm = () => {
    setModalOpen(false);
    setOnceRequest(false);
    setCreditRequest(0);

    enqueueSnackbar(<SnackbarContent title={messages.creditLineRequestSent.defaultMessage} />, {
      variant: 'info'
    });
  };

  const [onceRequest, setOnceRequest] = React.useState(false);
  const [creditRequest, setCreditRequest] = React.useState(0);
  const handleChangeCreditRequest = (ev: any) => {
    setCreditRequest(Number(ev.currentTarget.value));
  };

  const handleRequestCredit = () => {
    const urlMock = `${env.apiUrl}/account/credit/increase-request`;
    const service = new AuthService();
    setLoading(true);

    axios
      .post(
        `${urlMock}`,
        {
          amount: String(creditRequest),
          once: String(onceRequest)
        },
        {
          headers: {
            Authorization: service.authToken
          }
        }
      )
      .then(() => {
        loadUser();
        handleModalConfirm();
      })
      .catch(err => {
        let data;
        if (err.response && err.response.data) {
          data = err.response.data;
        }

        if (data === 'The client already has a credit') {
          return enqueueSnackbar(<SnackbarContent title={messages.creditLineRequestErrorAlready.defaultMessage} />, {
            variant: 'error'
          });
        }
        enqueueSnackbar(<SnackbarContent title={messages.creditLineRequestError.defaultMessage} />, {
          variant: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const orderCredit = (account && account.profile.orderCredit) || 0;
  const availableFunds = (account && account.profile.availableFunds) || 0;
  const { credit = 0, tmpCredit = 0, tmpCreditExpireOn = null } = (account && account.profile) || {};
  const creditCurrent = credit + tmpCredit;
  const creditUsed = credit + tmpCredit + orderCredit - availableFunds;

  // const chartOptions = {
  //   ...getDonutChartOptions('', formattedStringPrice(creditCurrent, UAH), classes),
  //   labels: [
  //     `${formatMessage(messages.creditLineUsed)}: ${formattedStringPrice(creditUsed, UAH)}`,
  //     `${formatMessage(messages.creditLineAvailable)}: ${formattedStringPrice(availableFunds, UAH)}`
  //   ]
  // };
  // chartOptions.colors[0] = defaultMaterialTheme.palette.error.main;

  return (
    <React.Fragment>
      <FlrCard
        className={clsx(classes.paperCard, { [classes.paperCardFullHeight]: fullHeight }, rest.className)}
        hoverShadow
      >
        <FlrCardTitle isUnderline>
          <TextSubTitle align={'left'} className={classes.title}>
            {messages.creditLine.defaultMessage} <Icon type={'history'} size={24} leftOffset={8} />
          </TextSubTitle>
          <Button color={'primary'} onClick={() => setModalOpen(true)}>
            {messages.creditLineIncrease.defaultMessage}
          </Button>
        </FlrCardTitle>
        {!accountLoadingState && account ? (
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={'auto'} className={classes.cardIcon}>
              <Icon type={'creditCard'} size={32} />
            </Grid>
            <Grid item xs container direction={'column'} spacing={2}>
              <Grid item container direction={'column'} spacing={1}>
                <Grid item>
                  <TextBody2 align={'left'}>
                    <SplitedPrice
                      fontSize={isMobile ? 'h2' : 'h1'}
                      fontSizeDecimal={isMobile ? 'h4' : 'h3'}
                      fontColor="black"
                      value={credit}
                      postfix={UAH}
                      priceDisplayStyle={{ fontWeight: 700, letterSpacing: '1px' }}
                    />
                  </TextBody2>
                </Grid>
                {!!tmpCredit && (
                  <Grid item container spacing={1} justifyContent={'flex-start'}>
                    <Grid item xs={'auto'}>
                      <SplitedPrice
                        value={tmpCredit + orderCredit}
                        prefix="+"
                        postfix={UAH}
                        negative
                        fontSize={'subtitle1'}
                        fontSizeDecimal={'caption'}
                      />
                    </Grid>
                    {!!tmpCreditExpireOn && (
                      <Grid item xs className={classes.creditContent}>
                        <Link2>Погасити до {moment(tmpCreditExpireOn).calendar()}</Link2>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid item container alignItems={'center'} spacing={1}>
                {/* <Grid item container xs spacing={1}> */}
                <Grid item container alignItems={'flex-start'} justifyContent={'flex-start'} spacing={1} xs={6}>
                  {/* <Grid item xs={'auto'}>
                      <Circle size={12} color={defaultMaterialTheme.palette.common.white} />
                    </Grid> */}
                  {/* <Grid item container xs direction={'column'} alignItems={'flex-start'} spacing={1}> */}
                  <Grid item>{messages.creditLineAvailable.defaultMessage}</Grid>
                  <Grid item>
                    <SplitedPrice
                      fontSize={'subtitle1'}
                      fontSizeDecimal={'caption'}
                      value={availableFunds}
                      postfix={UAH}
                    />
                  </Grid>
                </Grid>
                {/* </Grid> */}
                {/* <GridDivider /> */}
                <Grid
                  item
                  container
                  alignItems={'flex-start'}
                  justifyContent={'flex-start'}
                  spacing={1}
                  xs={6}
                  className={classes.verticalDivider}
                >
                  {/* <Grid item> */}
                  {/* <div style={{ height: '100%', width: 1, backgroundColor: 'grey' }} /> */}
                  {/* <Circle size={12} color={defaultMaterialTheme.palette.error.main} /> */}
                  {/* </Grid> */}
                  {/* <Grid item container xs direction={'column'} alignItems={'flex-start'} spacing={1}> */}
                  <Grid item>{messages.creditLineUsed.defaultMessage}</Grid>
                  <Grid item>
                    <SplitedPrice fontSize={'subtitle1'} fontSizeDecimal={'caption'} value={creditUsed} postfix={UAH} />
                  </Grid>
                  {/* </Grid> */}
                </Grid>
                {/* </Grid> */}
                {/* <Grid item xs={'auto'} className={'creditLine'}>
                  <Chart
                    width={120}
                    height={140}
                    options={chartOptions}
                    series={[creditUsed, availableFunds]}
                    type={'donut'}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <DataCardSkeleton />
        )}
      </FlrCard>

      <Dialog
        open={modalOpen}
        onClose={handleCancel}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll={'body'}
        classes={{
          paperScrollBody: classes.dialogContainer
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <TitleH1 className={classes.dialogTitle}>{messages.creditLineModalTitle.defaultMessage}</TitleH1>
        </DialogTitle>
        <DialogContent>
          <TextBody2 className={classes.dialogText}>{messages.creditLineModalText.defaultMessage}</TextBody2>

          <ValidatorForm noValidate instantValidate={true} autoComplete="off" onSubmit={handleModalConfirm}>
            <FlrInput
              className={classes.creditInput}
              name="name"
              variant="outlined"
              required
              placeholder={messages.creditLineInputLabel.defaultMessage}
              value={String(creditRequest || '')}
              onChange={handleChangeCreditRequest}
              helperText={
                <span>
                  {messages.creditLineInputHelpText.defaultMessage}{' '}
                  <SplitedPrice value={creditCurrent} fontSize={'tiny'} postfix={`₴`} />
                </span>
              }
            />
          </ValidatorForm>

          <FlrCheckboxWithLabel
            name={'onceRequest'}
            checked={onceRequest}
            onChange={(event: any, checked: boolean) => setOnceRequest(checked)}
            label={messages.creditLineOnceCheckbox.defaultMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={'text'} onClick={handleCancel} color="primary" disabled={loading}>
            {messages.cancelBtnLabel.defaultMessage}
          </Button>
          <FlrButtonKhaki onClick={handleRequestCredit} color="primary" disabled={loading || creditRequest <= 0}>
            {messages.creditLineIncrease.defaultMessage}
            {loading && <CircularProgress size={14} />}
          </FlrButtonKhaki>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  account: getUserAccount(state),
  accountLoadingState: getAccountLoadingState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      loadUser: fetchAccountAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(CardCreditLine as any));
