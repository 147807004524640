import styled from '@emotion/styled/macro';

import { leftDrawerWidth, rightDrawerWidth } from 'shared/constants';
import { defaultMaterialTheme, ITheme, IThemed } from 'utils/styled';

export const TopBarFiltersWrapper = styled<any>('div')(({ theme }: IThemed) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: 4,
  width: '100%',
  boxSizing: 'border-box',
  '@media(max-width: 720px)': {
    paddingTop: 0
  },

  '& > *': {
    display: 'flex',
    '&:first-child': {
      marginLeft: 16,
      flexGrow: 1
    },
    '&:last-child': {
      marginRight: 24
    }
  },
  '&.mobile': {
    justifyContent: 'flex-end'
  }
}));

const filtersDrawerWidth = leftDrawerWidth;
const cardDrawerWidth = rightDrawerWidth;

const styles = (theme: ITheme) => ({
  catalogCartLayout: {
    flexGrow: 1,
    transition: defaultMaterialTheme.transitions.create(['margin', 'width'], {
      easing: defaultMaterialTheme.transitions.easing.sharp,
      duration: defaultMaterialTheme.transitions.duration.leavingScreen
    }),
    marginRight: 0
  },
  catalogCartLayoutShift: {
    width: `calc(100% - ${cardDrawerWidth}px)`,
    transition: defaultMaterialTheme.transitions.create(['margin', 'width'], {
      easing: defaultMaterialTheme.transitions.easing.easeOut,
      duration: defaultMaterialTheme.transitions.duration.enteringScreen
    }),
    marginRight: cardDrawerWidth
  },
  cartDrawer: {
    width: 0
  },
  cartDrawerPaper: {
    top: 120,
    width: cardDrawerWidth
  },

  filtersDrawer: {
    width: 0
  },
  filtersDrawerPaper: {
    top: 120,
    width: filtersDrawerWidth,
    bottom: 0,
    height: 'auto'
  },

  catalogFiltersLayout: {
    flex: 1,
    transition: defaultMaterialTheme.transitions.create(['margin', 'width'], {
      easing: defaultMaterialTheme.transitions.easing.sharp,
      duration: defaultMaterialTheme.transitions.duration.leavingScreen
    }),
    display: 'flex',
    height: '100%'
  },
  catalogFiltersLayoutShift: {
    width: `calc(100% - ${filtersDrawerWidth}px)`,
    marginLeft: filtersDrawerWidth,
    transition: defaultMaterialTheme.transitions.create(['margin', 'width'], {
      easing: defaultMaterialTheme.transitions.easing.easeOut,
      duration: defaultMaterialTheme.transitions.duration.enteringScreen
    })
  },

  catalog: {
    flex: 1,
    display: 'flex'
  },

  catalogHeader: {
    background: theme.colors.brandPrimaryLightedOutMax
  },
  alignedRight: {
    textAlign: 'right'
  },
  cardTimeLeft: {
    color: theme.colors.red,
    textAlign: 'right'
  },
  cardListItem: {
    borderBottom: `1px solid ${theme.colors.borderGray}`
  },
  cardFooter: {},
  statList: {
    '& > div': {
      borderRight: `1px solid ${theme.colors.gray}`,
      '&:first-child': {
        paddingRight: theme.padding.container
      },
      '&:last-child': {
        borderRight: 'none'
      }
    }
  },
  logoutLeftBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
    background: 'none',
    border: 'none',
    borderRadius: 0,
    borderTop: `1px solid ${theme.palette.common.disabledBlack}`,
    borderBottom: `1px solid ${theme.palette.common.disabledBlack}`,
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    textTransform: 'none',

    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      color: theme.palette.primary.main
    },
    '&:active': {
      color: theme.palette.primary.main
    },

    '&.MuiButton-textPrimary': {
      padding: `${theme.spacing(2)} ${theme.spacing(1)}`
    }
  },
  logoutLeftBarWrapper: {
    width: '100%',
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit'
  },
  removeUserButton: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  totalShown: {
    display: 'flex',
    alignItems: 'center'
  },
  rightPanel: {
    paddingBottom: 24,
    width: '100%'
  }
});

export default styles;
